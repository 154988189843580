import React from "react";
import classes from "./StatsCard.module.css";
const StatsCard = ({ item }) => {
  return (
    <>
      <div className={classes.reportCard_main}>
        <div className={classes.reportCard_img}>
          <img src={item?.image} alt="balance" />
        </div>
        <div className={classes.reportCard_balance}>
          <p className="extraSmall" style={{ color: "#2D2D2D" }}>
            {item?.title}
          </p>
          <h4>{item?.price}</h4>
        </div>
      </div>
    </>
  );
};

export default StatsCard;
