import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  loginVector,
  coverPhoto,
  pdf,
  statsImg,
} from "../../constant/imagepath";
import { useDispatch, useSelector } from "react-redux";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { apiUrl, URL, imageUrl } from "../../Config/apiUrl";
import StarRatings from "react-star-ratings";
import { SpinnerRoundOutlined } from "spinners-react";
import { toast } from "react-toastify";
import PDFView from "../../components/PDFView";
import classes from "./ApproveMechanicDetail.module.css";
import ProfileWithEditButton from "../../components/ProfileWithEditButton";
import { FaSnapchat } from "react-icons/fa";
import { BsFacebook, BsLinkedin, BsTelephoneFill } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { ImGooglePlus3 } from "react-icons/im";
import { MdDescription, MdEmail, MdLocationPin } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";

function ApprovedMechanicDetails() {
  const history = useHistory();
  const id = useParams()?.id;
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [userData, setUserData] = useState();
  const [bookingStats, setBookingStats] = useState();
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [activateLoading, setActivateLoading] = useState(false);
  const [MMHLoading, setMMHLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);

  const fetchData = async () => {
    const url = URL(`mechanic/mechanic-detail/${id}`);
    setIsFetchingData(true);
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      setUserData(response?.data?.data?.user);
      setBookingStats(response?.data?.data?.bookingStats);
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const Header = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const approve = async (id) => {
    const approvedUrl = URL(`admin/mechanic/verify`);
    const params = {
      mechanicId: id,
    };
    setApproveLoading(true);
    const responseData = await Patch(approvedUrl, params, Header, accessToken);
    setApproveLoading(false);
    if (responseData !== undefined) {
      console.log("responseData", responseData?.data?.data);
      // setUserData(responseData?.data?.data);
    }
  };

  const submitMMHVerified = async (status) => {
    const mmhVerifiedUrl = URL(`mmh/verify-unverify`);
    const params = {
      _id: userData?._id,
      isMMHVerified: status,
    };
    setMMHLoading(true);
    const responseData = await Patch(mmhVerifiedUrl, params, Header);
    setMMHLoading(false);
    if (responseData !== undefined) {
      setUserData(responseData?.data?.data);
      toast.success(`Successfully MMH ${status ? "Verified" : "Unverified"}`);
    }
  };

  // === Activate ===

  const ActivateOrDeactivate = async () => {
    const ActivateUrl = URL(`activate-or-deactivate-user`);
    const params = {
      _id: userData?._id,
      role: userData?.UserId?.role,
      activate: !userData?.accountStatus,
    };
    setActivateLoading(true);
    const responseData = await Patch(ActivateUrl, params, Header);
    setActivateLoading(false);
    if (responseData !== undefined) {
      responseData?.data?.data?.accountStatus == true
        ? toast.success("Account has been Activated.")
        : toast.success("Account has been Deactivated.");
      setUserData(responseData?.data?.data);
    } else {
      console.log("error");
    }
  };

  return (
    <>
      <div className="container-fluid newMentorRequestDetails">
        <h1 className="h3 mb-4 text-gray-800">Approved Mechanic Details</h1>
        <div>
          <div>
            <div className="row gx-0">
              {isFetchingData ? (
                <div className="col-md-12 darken-newMechanic on-loading">
                  <SpinnerRoundOutlined
                    enabled={isFetchingData}
                    thickness={200}
                    color="#AB2330"
                  />{" "}
                </div>
              ) : (
                <>
                  {/*
                  <div
                    class="btn-group"
                    style={{
                      marginLeft: "auto",
                      justifyContent: "end",
                    }}
                  >
                    {userData?.isAdminVerified ? (
                      <h5
                        style={{
                          color: "green",
                          alignSelf: "center",
                          marginRight: "30px",
                        }}
                      >
                        Approved
                      </h5>
                    ) : (
                      <div>
                        <Button
                          class="btn btn-secondary"
                          onClick={() => {
                            approve(userData?._id);
                          }}
                          disabled={loading}
                        >
                          {loading ? "loading" : "Approve"}
                        </Button>
                      </div>
                    )}

                    <Button
                      class="btn btn-secondary"
                      onClick={() => {
                        ActivateOrDeactivate();
                      }}
                      style={{ borderRadius: "5px" }}
                      disabled={activateLoading}
                    >
                      {activateLoading
                        ? "loading"
                        : userData?.accountStatus == false
                        ? "Activate"
                        : "Deactivate"}
                    </Button>
                    {userData?.isMMHVerified ? (
                      <h5
                        style={{
                          color: "red",
                          alignSelf: "center",
                        }}
                      >
                        MMH Verified
                      </h5>
                    ) : (
                      <div>
                        <Button
                          class="btn btn-secondary"
                          onClick={() => {
                            submitMMHVerified();
                          }}
                          disabled={MMHLoading}
                        >
                          {MMHLoading ? "loading" : "MMH Verified"}
                        </Button>
                      </div>
                    )}
                  </div> */}

                  <div className={classes.bodyContainer}>
                    <div
                      className={`${classes.userImagesContainer}`}
                      style={{
                        height: 400,
                      }}
                    >
                      <ProfileWithEditButton
                        updateImage={userData?.cover_image}
                        className={classes.coverImage}
                        onErrorImage={true}
                        classImg={classes.coverImageTagClass}
                        edit={false}
                      />

                      <div className={`${classes.profileImageContainer}`}>
                        <ProfileWithEditButton
                          updateImage={userData?.photo}
                          edit={false}
                        />
                      </div>
                    </div>
                    <div
                      className={`${classes.userDetailContainer}`}
                      style={{
                        margin: "50px 0",
                      }}
                    >
                      <div className={classes.centerItems}>
                        <StarRatings
                          rating={userData?.ratingsAverage}
                          starRatedColor="var(--main-color-red)"
                          numberOfStars={5}
                          name="rating"
                          starDimension="16px"
                          starSpacing="1px"
                        />
                        <p className={[classes.reviewText]}>{`${
                          userData?.review?.length
                        } ${
                          userData?.review?.length === 1 ? "review" : "reviews"
                        }`}</p>
                        <p className={[classes.reviewText]}>
                          {userData?.noOfcalls} Users Contacted
                        </p>
                        <p className={`${classes.userNameText}`}>
                          {userData?.userName}
                        </p>

                        <>
                          <Row className={`g-0`}>
                            <Col
                              md={12}
                              className={classes.SocialLinkContainer}
                            >
                              {userData?.snapchatLink && (
                                <FaSnapchat
                                  size={24}
                                  color={"var(--main-color-red)"}
                                  onClick={() => {
                                    window.open(
                                      `https://${userData?.snapchatLink}`,
                                      "_blank"
                                    );
                                  }}
                                  className={classes.socialIcon}
                                />
                              )}
                              {userData?.facebookLink && (
                                <BsFacebook
                                  size={24}
                                  color={"var(--main-color-red)"}
                                  onClick={() => {
                                    window.open(
                                      `https://${userData?.facebookLink}`,
                                      "_blank"
                                    );
                                  }}
                                  className={classes.socialIcon}
                                />
                              )}
                              {userData?.instagramLink && (
                                <GrInstagram
                                  size={24}
                                  color={"var(--main-color-red)"}
                                  onClick={() => {
                                    window.open(
                                      `https://${userData?.instagramLink}`,
                                      "_blank"
                                    );
                                  }}
                                  className={classes.socialIcon}
                                />
                              )}
                              {userData?.googleLink && (
                                <ImGooglePlus3
                                  size={24}
                                  color={"var(--main-color-red)"}
                                  onClick={() => {
                                    window.open(
                                      `https://${userData?.googleLink}`,
                                      "_blank"
                                    );
                                  }}
                                  className={classes.socialIcon}
                                />
                              )}
                              {userData?.linkedinLink && (
                                <BsLinkedin
                                  size={24}
                                  color={"var(--main-color-red)"}
                                  onClick={() => {
                                    window.open(
                                      `https://${userData?.linkedinLink}`,
                                      "_blank"
                                    );
                                  }}
                                  className={classes.socialIcon}
                                />
                              )}
                            </Col>
                          </Row>
                        </>
                      </div>
                      {bookingStats?.length > 0 && (
                        <div className="d-flex flex-wrap my-4">
                          {bookingStats?.map((item, i) => {
                            return (
                              <div className={[classes.bookingStatsDiv]}>
                                <div className={[classes.statsImgDiv]}>
                                  <img src={statsImg} alt="..." />
                                </div>
                                <div>
                                  <h5 className={[classes.bookingStatsCount]}>
                                    {item?.count}
                                  </h5>
                                  <h5 className={[classes.bookingStats]}>
                                    Booking {item?.status}
                                  </h5>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <Row className={`g-0 ${classes.personalDetailRow}`}>
                        <Col md={4} sm={12}>
                          <MdEmail size={24} color={"#E60000"} />
                          <span>{userData?.UserId?.email}</span>
                        </Col>
                        <Col md={3}>
                          <BsTelephoneFill size={20} color={"#E60000"} />
                          <span>
                            {userData?.contactNo == null ||
                            userData?.contactNo == "" ||
                            userData?.contactNo == undefined
                              ? "not added yet"
                              : userData?.contactNo}
                          </span>
                        </Col>
                        <Col md={5} sm={12}>
                          <MdLocationPin size={24} color={"#E60000"} />
                          <span>
                            {userData?.address
                              ? userData?.address
                              : "This information is not edit yet..."}
                          </span>
                        </Col>

                        <Col md={12} sm={12}>
                          <MdDescription size={24} color={"#E60000"} />
                          <span>
                            {userData?.description
                              ? userData?.description
                              : "This information is not edit yet..."}
                          </span>
                        </Col>
                      </Row>

                      <Row
                        className={`g-0 ${classes.certificateAndServicesRow}`}
                      >
                        <Col md={12} sm={12}>
                          <h4>CERTIFICATIONS</h4>
                        </Col>
                        {userData?.certificates?.length > 0 ? (
                          userData?.certificates?.map((items, index) => {
                            return (
                              <Col md={6} sm={12}>
                                <h5>{items?.certificateType}</h5>
                                <ul>
                                  {items?.certificateItem?.map(
                                    (list, index) => {
                                      return (
                                        <li className={`${classes.greyText}`}>
                                          {list?.CertificateName}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </Col>
                            );
                          })
                        ) : (
                          <h6>No Certificates</h6>
                        )}
                      </Row>

                      <Row
                        className={`g-0 ${classes.certificateAndServicesRow}`}
                      >
                        <Col md={12} sm={12}>
                          <h4>SERVICES</h4>
                        </Col>
                        {userData?.services?.length > 0 ? (
                          userData?.services?.map((items, index) => {
                            return (
                              <Col md={6} sm={12}>
                                <h5>{items?.serviceType}</h5>
                                <ul>
                                  {items?.serviceItems?.map((list, index) => {
                                    return (
                                      <li className={`${classes.greyText}`}>
                                        {list?.serviceName}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Col>
                            );
                          })
                        ) : (
                          <h6>No Services</h6>
                        )}
                      </Row>

                      <Row
                        className={`g-0 ${classes.certificateAndServicesRow}`}
                      >
                        <Col md={12} sm={12}>
                          <h4>Public Certificates PDF</h4>
                        </Col>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {userData?.documents?.length > 0 ? (
                            userData?.documents?.map((el, index) => {
                              return (
                                // <PDFImageView
                                //   item={el}
                                //   index={index}
                                //   local={false}
                                // />
                                <PDFView item={el} />
                              );
                            })
                          ) : (
                            <h6>No Public Certificates</h6>
                          )}
                        </div>
                      </Row>
                      <Row
                        className={`g-0 ${classes.certificateAndServicesRow}`}
                      >
                        <Col md={12} sm={12}>
                          <h4>Private Certificates PDF</h4>
                        </Col>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {userData?.privateDocuments?.length > 0 ? (
                            userData?.privateDocuments?.map((el, index) => {
                              return <PDFView item={el} />;
                            })
                          ) : (
                            <h6>No Private Certificates</h6>
                          )}
                        </div>
                      </Row>

                      {/* status */}

                      <Row
                        className={`g-0 ${classes.certificateAndServicesRow}`}
                      >
                        <Col md={12} sm={12}>
                          <h4 className="mb-3">Status</h4>
                        </Col>

                        <Col md={6} sm={12} className="mb-2">
                          <div
                            className="d-flex align-items-center"
                            style={{ gap: "6px" }}
                          >
                            <h6 className="mb-0">Account Status:</h6>
                            <span
                              className={`${
                                userData?.accountStatus
                                  ? classes.approveColor
                                  : classes.rejectColor
                              }`}
                            >
                              {userData?.accountStatus
                                ? "Activate"
                                : "Deactivate"}
                            </span>
                          </div>
                        </Col>
                        <Col md={6} sm={12} className="mb-2">
                          <div
                            className="d-flex align-items-center"
                            style={{ gap: "6px" }}
                          >
                            <h6 className="mb-0">MMH Verified Status:</h6>
                            <span
                              className={`${
                                userData?.isMMHVerified
                                  ? classes.approveColor
                                  : classes.rejectColor
                              }`}
                            >
                              {userData?.isMMHVerified
                                ? "MMH Verified"
                                : "MMH UnVerified"}
                            </span>
                          </div>
                        </Col>
                        <Col md={6} sm={12} className="mb-2">
                          <div
                            className="d-flex align-items-center"
                            style={{ gap: "6px" }}
                          >
                            <h6 className="mb-0">Admin Verified Status:</h6>
                            <span
                              className={`${
                                userData?.isAdminVerified
                                  ? classes.approveColor
                                  : classes.rejectColor
                              }`}
                            >
                              {userData?.isAdminVerified
                                ? "Approved"
                                : "Rejected"}
                            </span>
                          </div>
                        </Col>
                      </Row>

                      {/* status */}
                      <h4 className="my-4">Actions</h4>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        {userData?.isAdminVerified ? (
                          <h5
                            style={{
                              color: "green",
                              alignSelf: "center",
                              marginRight: "30px",
                            }}
                          >
                            Approved
                          </h5>
                        ) : (
                          <div className="me-3">
                            <Button
                              className={[classes.customBtn, "btn"].join(" ")}
                              onClick={() => {
                                approve(userData?._id);
                              }}
                              disabled={approveLoading}
                            >
                              {approveLoading ? "loading" : "Approve"}
                            </Button>
                          </div>
                        )}

                        <Button
                          className={[classes.customBtn, "btn"].join(" ")}
                          onClick={() => {
                            ActivateOrDeactivate();
                          }}
                          style={{ borderRadius: "5px", marginRight: "16px" }}
                          disabled={activateLoading}
                        >
                          {activateLoading
                            ? "loading"
                            : userData?.accountStatus == false
                            ? "Activate"
                            : "Deactivate"}
                        </Button>
                        {userData?.isMMHVerified ? (
                          // <h5
                          //   style={{
                          //     color: "red",
                          //     alignSelf: "center",
                          //   }}
                          // >
                          //   MMH Verified
                          // </h5>
                          <div>
                            <Button
                              className={[classes.customBtn, "btn"].join(" ")}
                              onClick={() => {
                                submitMMHVerified(false);
                              }}
                              disabled={MMHLoading}
                            >
                              {MMHLoading ? "loading" : "MMH UnVerified"}
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <Button
                              className={[classes.customBtn, "btn"].join(" ")}
                              onClick={() => {
                                submitMMHVerified(true);
                              }}
                              disabled={MMHLoading}
                            >
                              {MMHLoading ? "loading" : "MMH Verified"}
                            </Button>
                          </div>
                        )}
                        <Button
                          className={[classes.YeelowBtn, "btn"].join(" ")}
                          onClick={() => {
                            history.push(`/EditMechanic/${id}`);
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}
    </>
  );
}

export default ApprovedMechanicDetails;
