import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Pagination from "../components/Pagination";
import AddServiceTypesModal from "../components/AddServiceTypesModal";
import EditAddServiceTypesModal from "../components/EditAddServiceTypesModal";
import { URL } from "../Config/apiUrl";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { Post, Patch, Get, Delete } from "../Axios/AxiosFunctions";
import { SpinnerRoundOutlined } from "spinners-react";
import { toast } from "react-toastify";
import ConfirmModal from "../components/ConfirmModal";

function AddServicesTypes(props) {
  const { setChoice } = props;
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [addServicesTypes, setAddServicesTypes] = useState([]);
  const [serviceType, setServiceType] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [addServiceTypesModal, setAddServiceTypesModal] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);

  // Edit service
  const [editAddServiceTypesModal, setEditAddServiceTypesModal] =
    useState(false);
  const [editServiceType, setEditServiceType] = useState("");
  const [item, setItem] = useState({});
  const [index, setIndex] = useState("");

  const getAllApprovedMentee = async () => {
    const allApprovedMenteeApiURL = URL(
      `services?limit=${limit}&page=${pageNumber}`
    );
    setIsFetchingData(true);
    const responseData = await Get(allApprovedMenteeApiURL, accessToken);
    let fetchedData = [];
    if (responseData !== undefined) {
      console.log("qasim api", responseData);
      console.log(responseData?.data?.data);
      let maxLastLength =
        pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
      setMaxLength(maxLastLength);
      fetchedData = [...responseData?.data?.data];
      setAddServicesTypes(fetchedData);
    } else {
      console.log("error");
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    console.log({ pageNumber });
    getAllApprovedMentee();
  }, [pageNumber]);

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const addServiceTypeHandler = async () => {
    setLoading(true);
    console.log("add functio run");
    // setAddServiceTypesModal(false);
    const addServiceType = URL(`service-type`);
    let obj = { serviceType: serviceType };
    const responseData = await Post(addServiceType, obj, authHeader);
    if (responseData !== undefined) {
      console.log({ addServicesTypes });

      let oldData = addServicesTypes.slice();
      oldData.push(responseData?.data?.data);
      console.log({ oldData });
      setAddServicesTypes(oldData);
      setAddServiceTypesModal(false);
      console.log("qasim api add service", responseData?.data);
    }
    setLoading(false);
  };

  const resetStates = () => {
    setShowDeleteModal(false);
  };

  const deleteHandler = async () => {
    const deleteServiceType = URL(`service-type/${selectedItem?._id}`);
    let index = addServicesTypes?.findIndex((ele) => ele?._id === selectedItem?._id);

    setLoading(true);
    const responseData = await Delete(deleteServiceType, authHeader);
    if (responseData !== undefined) {
      console.log({ responseData });
      let arr = addServicesTypes.slice();
      arr.splice(index, 1);
      setAddServicesTypes(arr);
      setShowDeleteModal(false);
      setSelectedItem(null);
      toast.success("Item Deleted Successfully.");
    }
    setLoading(false);
  };

  const EditHandler = async (item, index) => {
    setLoading(true);

    setEditServiceType(item.serviceType);
    // setTitleText(item.title)

    const editServiceItemsUrl = URL(`service-type/${item._id}`);
    // setLoading(item._id);
    let obj = { serviceType: editServiceType };
    // let obj = { serviceTypeId: editServiceType.id ,serviceItem:editServiceItems,}
    console.log(obj);
    const responseData = await Patch(editServiceItemsUrl, obj, authHeader);
    if (responseData !== undefined) {
      let oldData = addServicesTypes.slice();
      oldData.push(responseData?.data?.data);
      console.log({ oldData });

      setEditAddServiceTypesModal(false);
    }
    setLoading(false);
  };

  console.log(addServicesTypes);

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequests">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Add Services Types</h1>
        <p className="mb-4">All Service list</p>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex">
            <h6 className="m-0 font-weight-bold text-primary">
              Add Services Types
            </h6>
            <div className="addBtn">
              <i
                className="fas fa-plus-circle"
                onClick={() => setAddServiceTypesModal(true)}
              ></i>
            </div>
          </div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#AB2330"
                />{" "}
              </div>
            ) : (
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      {/* <th>Service Id</th> */}
                      <th>Service Types</th>
                      <th>Delete</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {addServicesTypes?.map((item, index) => {
                      return (
                        <>
                          <tr
                            key={item?._id}
                            // onClick={() => setChoice("MenteeDetails")}
                          >
                            <td>{index + 1}</td>
                            <td>{item?.serviceType}</td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() => {
                                  setEditAddServiceTypesModal(true);
                                  setItem(item);
                                  setIndex(index);
                                  setEditServiceType(item.serviceType);
                                }}
                                disabled={loading}
                              >
                                Edit
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() => {
                                  setSelectedItem(item);
                                  setShowDeleteModal(true);
                                }}
                                disabled={loading}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <Pagination
                    maxLastLength={maxLength}
                    array={addServicesTypes}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}

      <AddServiceTypesModal
        setAddServiceTypesModal={setAddServiceTypesModal}
        addServiceTypesModal={addServiceTypesModal}
        value={serviceType}
        setValue={setServiceType}
        onPress={() => {
          addServiceTypeHandler();
        }}
        loading={loading}
      />

      <ConfirmModal
        isModalVisible={showDeleteModal}
        setIsModalVisible={setShowDeleteModal}
        onClickNo={resetStates}
        onClickYes={deleteHandler}
        confirmTextLine={"Are you sure you want to delete this item?"}
        isApiCall={loading}
      />

      <EditAddServiceTypesModal
        setEditAddServiceTypesModal={setEditAddServiceTypesModal}
        editAddServiceTypesModal={editAddServiceTypesModal}
        value={editServiceType}
        setValue={setEditServiceType}
        // onPress={()=>{
        //   addServiceTypeHandler()
        // }}
        EditHandler={EditHandler}
        item={item}
        setItem={setItem}
        index={index}
        loading={loading}
        setIndex={setIndex}
      />
    </>
  );
}

export default AddServicesTypes;
