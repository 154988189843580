import React, { useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { URL } from "../Config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Get, Patch, Delete } from "../Axios/AxiosFunctions";
import {
  getNewMentors,
  getNewPageDetails,
} from "../store/actions/mentorActions";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";
import EditVoucherModal from "../components/EditVoucherModal";
import { toast } from "react-toastify";
import ConfirmModal from "../components/ConfirmModal";
import moment from "moment";

function AddVoucher(props) {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  // const [paymentRequests, setPaymentRequests] = useState([]);
  const [addVoucher, setAddVoucher] = useState([]);
  const [limit, setLimit] = useState(10);
  // const [addVoucher, setAddVoucher] = useState("Voucher 1");
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [isFetchingData, setIsFetchingData] = useState(true);

  const [loading, setLoading] = useState("");

  const [editVoucherModal, setEditVoucherModal] = useState(false);
  const [item, setItem] = useState({});
  const [index, setIndex] = useState("");

  const [editImage, setEditImage] = useState("");
  const [editImageBase64, setEditImageBase64] = useState("");
  const [editVoucherTitle, setEditVoucherTitle] = useState("");
  const [editVoucherCode, setEditVoucherCode] = useState("");
  const [editVoucherDiscount, setEditVoucherDiscount] = useState("");
  const [editWebsiteLink, setEditWebsiteLink] = useState("");
  const [editVoucherDescription, setEditVoucherDescription] = useState("");
  const [editStartDate, setEditStartDate] = useState("");
  const [editEndDate, setEditEndDate] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dispatch = useDispatch();
  let history = useHistory();

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const getVoucherList = async () => {
    const certificatesApiURL = URL(
      `voucher/all?page=${pageNumber}&limit=${limit}`
    );
    setIsFetchingData(true);
    // setLoading(true);
    const responseData = await Get(certificatesApiURL, accessToken);
    let maxLastLength =
      pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
    setMaxLength(maxLastLength);
    if (responseData !== undefined) {
      console.log("voucher data", responseData);
      setAddVoucher(responseData.data.data);
      // console.log(responseData?.data?.data)
      // setAddVoucher(responseData?.data?.data);
    } else {
      setAddVoucher([]);
      console.log("error");
    }
    // setLoading(false);
    setIsFetchingData(false);
  };

  useEffect(() => {
    getVoucherList();
  }, [pageNumber]);

  // const Header = {
  //   headers: {
  //   Authorization: `Bearer ${accessToken}`,
  // },}

  // useEffect(() => {
  //   var config = {
  //     method: "get",
  //     url: NODE_URL(
  //       `transaction/pending-withdraws?limit=${limit}&page=${pageNumber}&addVoucher=${addVoucher}`
  //     ),
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   };
  //   axios(config)
  //     .then(function (response) {
  //       let maxLastLength =
  //         pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
  //       setMaxLength(maxLastLength);
  //       setPaymentRequests(response.data.data);
  //       console.log(response.data.data, "=================================");
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, [pageNumber, addVoucher]);

  // const approve = (id) => {
  //   console.log(id);
  //   var config = {
  //     method: "get",
  //     url: NODE_URL(
  //       `transaction/clear-pending-list?addVoucher=${AddVoucher}`
  //     ),
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   };
  //   axios(config)
  //     .then(function (response) {
  //       console.log(response);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const decline = (id) => {
  //   console.log(id);
  //   var config = {
  //     method: "get",
  //     url: NODE_URL(`mentor/decline-mentor/${id}`),
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   };
  //   axios(config)
  //     .then(function (response) {
  //       console.log(response);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const deleteHandler = async () => {
    const deleteVoucher = URL(`voucher/${selectedItem?.id}`);
    let index = addVoucher?.findIndex((ele) => ele?.id === selectedItem?.id);
    
    setLoading(true);
    const responseData = await Delete(deleteVoucher, null, authHeader);
    if (responseData !== undefined) {
      // console.log({ responseData });
      let arr = addVoucher.slice();
      arr.splice(index, 1);
      setAddVoucher(arr);
      setShowDeleteModal(false);
      setSelectedItem(null);
      toast.success("Deleted Successfully!");
    }
    setLoading(false);
  };
  
  const resetStates = () => {
    setShowDeleteModal(false);
  };
  

  const imgAuthHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  };

  // upload Image
  const uploadImages = async (event) => {
    setEditImage(event.target.files[0]);
    let reader = new FileReader();
    reader.onload = (Myevent) => {
      setEditImageBase64(Myevent.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const createFormdata = async (data) => {
    //  console.log({data})
    var formData = new FormData();
    console.log({ formData });
    formData.append("image", editImage);
    formData.append("title", editVoucherTitle);
    formData.append("code", editVoucherCode);
    formData.append("discount", editVoucherDiscount);
    formData.append("description", editVoucherDescription);
    formData.append("web_link", editWebsiteLink);
    formData.append("voucher_start", editStartDate);
    formData.append("voucher_end", editEndDate);
    formData.append("test", "test");
    return formData;
  };

  // Edit voucher
  const EditVoucherHandler = async (item) => {
    console.log(item);

    setEditImage(item.image);
    setEditVoucherTitle(item.title);
    setEditVoucherCode(item.code);
    setEditVoucherDiscount(item.discount);
    setEditWebsiteLink(item.web_link);
    setEditVoucherDescription(item.description);
    setEditStartDate(item.voucher_start);
    setEditEndDate(item.voucher_end);

    const editVoucher = URL(`voucher/${item.id}`);
    setLoading(true);

    let obj = {
      image: editImage,
      title: editVoucherTitle,
      code: editVoucherCode,
      discount: editVoucherDiscount,
      description: editVoucherDescription,
      web_link: editWebsiteLink,
      voucher_start: editStartDate,
      voucher_end: editEndDate,
    };

    let returnFormData = await createFormdata(obj);
    console.log({ returnFormData });
    const responseData = await Patch(
      editVoucher,
      returnFormData,
      imgAuthHeader
    );

    if (responseData !== undefined) {
      console.log({ index });
      let oldData = addVoucher.slice();
      oldData.splice(index, 1, responseData?.data?.data);
      setAddVoucher(oldData);
      setEditVoucherModal(false);
      toast.success("Changes Done Successfully!");
    }
    setLoading(false);
  };

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequests">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Add Voucher</h1>
        <p className="mb-4">Add Voucher List</p>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex">
            <h6 className="m-0 font-weight-bold text-primary">Add Voucher</h6>
            <div className="addBtn">
              <Link to="/AddNewVoucher">
                <i
                  className="fas fa-plus-circle"
                  // onClick={() => setChoice("AddNewVoucher")}
                  // onClick={() => setAddCertificateItemsModal(true)}
                ></i>
              </Link>
            </div>
          </div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#AB2330"
                />{" "}
              </div>
            ) : (
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      {/* <th>Name</th> */}
                      <th>Code</th>
                      <th>Title</th>
                      <th>Web_link</th>
                      <th>Discount</th>
                      <th>Voucher_start</th>
                      <th>Voucher_end</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {addVoucher.map((item, index) => {
                      return (
                        <>
                          <tr
                            key={item._id}
                            // onClick={() => setChoice("AddVoucherDetails")}
                          >
                            <td>{item.code}</td>
                            <td>{item.title}</td>
                            <td>{item.web_link}</td>
                            <td>{item.discount}</td>
                            <td>
                              {moment(item.voucher_start).format(
                                "DD/MM/yyy hh:mm A"
                              )}
                            </td>
                            <td>
                              {moment(item.voucher_end).format(
                                "DD/MM/yyy hh:mm A"
                              )}
                            </td>
                            <td>
                              <Button
                                className={`btn btn-user btn-block ${"btn-pending"}`}
                                onClick={() => {
                                  history.push("/AddVoucherDetails");
                                  dispatch(getNewPageDetails(item));
                                }}
                              >
                                View Detail
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                               onClick={() => {
                                  setSelectedItem(item);
                                  setShowDeleteModal(true);
                                }}
                                disabled={loading}
                              >
                                Delete
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() => {
                                  setEditVoucherModal(true);
                                  setItem(item);
                                  setIndex(index);
                                  setEditImage(item.image);
                                  setEditVoucherTitle(item.title);
                                  setEditVoucherCode(item.code);
                                  setEditVoucherDiscount(item.discount);
                                  setEditWebsiteLink(item.web_link);
                                  setEditVoucherDescription(item.description);
                                  setEditStartDate(item.voucher_start);
                                  setEditEndDate(item.voucher_end);
                                }}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>

                {/* <div style={{ width: "100px" }}>
                <Button
                  className={`btn btn-user btn-block btn-approved`}
                  onClick={() => approve()}
                >
                  Approve
                </Button>
              </div> */}

                <div className="pagination">
                  <Pagination
                    maxLastLength={maxLength}
                    array={addVoucher}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}

      <EditVoucherModal
        editVoucherModal={editVoucherModal}
        setEditVoucherModal={setEditVoucherModal}
        EditVoucherHandler={EditVoucherHandler}
        item={item}
        index={index}
        loading={loading}
        editImage={editImage}
        setEditImage={setEditImage}
        setEditImageBase64={setEditImageBase64}
        editImageBase64={editImageBase64}
        editVoucherTitle={editVoucherTitle}
        setEditVoucherTitle={setEditVoucherTitle}
        editVoucherCode={editVoucherCode}
        setEditVoucherCode={setEditVoucherCode}
        editVoucherDiscount={editVoucherDiscount}
        setEditVoucherDiscount={setEditVoucherDiscount}
        editWebsiteLink={editWebsiteLink}
        setEditWebsiteLink={setEditWebsiteLink}
        editVoucherDescription={editVoucherDescription}
        setEditVoucherDescription={setEditVoucherDescription}
        editStartDate={editStartDate}
        setEditStartDate={setEditStartDate}
        editEndDate={editEndDate}
        setEditEndDate={setEditEndDate}
        uploadImages={uploadImages}
      />
      
      <ConfirmModal
        isModalVisible={showDeleteModal}
        setIsModalVisible={setShowDeleteModal}
        onClickNo={resetStates}
        onClickYes={deleteHandler}
        confirmTextLine={"Are you sure you want to delete this item?"}
        isApiCall={loading}
      />
    </>
  );
}

export default AddVoucher;
