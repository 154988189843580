import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Get, Post, Delete, Patch } from "../Axios/AxiosFunctions";
import AddServiceItemsModal from "../components/AddServiceItemsModal";
import { URL } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import { SpinnerRoundOutlined } from "spinners-react";
import AddAndEditItemModal from "../components/AddAndEditItemModal";
import { toast } from "react-toastify";
import ConfirmModal from "../components/ConfirmModal";

function AddServicesItems(props) {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [ServicesResponseData, setServicesResponseData] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState({});
  const [inputServiceItem, setInputServiceItem] = useState("");
  const [addItemLoading, setAddItemLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [addServiceItemsModal, setAddServiceItemsModal] = useState(false);
  const [editAddServiceItemsModal, setEditAddServiceItemsModal] =
    useState(false);
  const addAndGetServiceItemApiURL = URL(`service-item`);

  const editServiceItemsUrl = URL(`service-item/${editItem?.id}`);
  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const resetStates = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    getServiceItem();
  }, []);

  const getServiceItem = async () => {
    setIsFetchingData(true);
    const responseData = await Get(addAndGetServiceItemApiURL, accessToken);
    if (responseData !== undefined) {
      setServicesResponseData(responseData.data?.data);
    }
    setIsFetchingData(false);
  };

  const addServiceItemHandler = async () => {
    setLoading(true);
    let obj = {
      serviceTypeId: selectedServiceType.id,
      serviceName: inputServiceItem,
    };
    const responseData = await Post(
      addAndGetServiceItemApiURL,
      obj,
      authHeader
    );
    if (responseData !== undefined) {
      const index = ServicesResponseData.findIndex(
        (x) => x._id == selectedServiceType.id
      );
      let oldArray = ServicesResponseData.slice();
      oldArray.splice(index, 1, responseData.data?.data);
      setServicesResponseData(oldArray);
      setAddServiceItemsModal(false);
      setSelectedServiceType({});
      setInputServiceItem("");
      toast.success("Item Successfully Added.");

    }
    setLoading(false);
  };

  const deleteHandler = async () => {
    const deleteService = URL(`service-item/${selection?.id}`);

    setIsDeleting(true);
    const responseData = await Delete(deleteService, null, authHeader);

    if (responseData !== undefined) {
      let oldArray = ServicesResponseData.slice();
      oldArray[selection?.parentIndex].serviceItem.splice(selection?.index, 1);
      setServicesResponseData(oldArray);
      setShowDeleteModal(false);
      setSelection(null);
      toast.success("Item Deleted Successfully.");
    }
    setIsDeleting(false);
  };

  const EditHandler = async () => {
    setLoading(true);
    let obj = {
      serviceTypeId: selectedServiceType.id,
      serviceName: inputServiceItem,
    };
    const responseData = await Patch(editServiceItemsUrl, obj, authHeader);
    if (responseData !== undefined) {
      let oldArray = ServicesResponseData.slice();
      oldArray[editItem?.parentIndex].serviceItem.splice(
        editItem?.index,
        1,
        responseData.data?.data
      );
      setServicesResponseData(oldArray);
      setEditItem({});
      setEditAddServiceItemsModal(false);
      setSelectedServiceType({});
      setInputServiceItem("");
    }
    setLoading(false);
  };

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequests">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Service Items</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex">
            <h6 className="m-0 font-weight-bold text-primary">
              Add Service Items
            </h6>
            <div className="addBtn">
              <i
                className="fas fa-plus-circle"
                onClick={() => setAddServiceItemsModal(true)}
              ></i>
            </div>
          </div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#AB2330"
                />{" "}
              </div>
            ) : (
              <div className="table-responsive" style={{ overflow: "hidden" }}>
                {ServicesResponseData?.map((item, index) => {
                  return (
                    <div
                      key={`div ${index}`}
                      className="servicesItemAndTypeContainer"
                    >
                      <h3
                        key={`h3 ${index}`}
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        {item?.serviceType}
                      </h3>
                      {item.serviceItem.map((innerItem, innerIndex) => (
                        <div
                          key={`div ${innerIndex}`}
                          className="serviceItemParent"
                        >
                          <p>
                            {innerIndex + 1}) {innerItem?.serviceName}
                          </p>
                          <Button
                            className="btn btn-user btn-block btn-save"
                            onClick={() => {
                              setEditAddServiceItemsModal(true);
                              setEditItem({
                                id: innerItem?._id,
                                index: innerIndex,
                                parentIndex: index,
                              });
                              setInputServiceItem(innerItem.serviceName);
                              setSelectedServiceType({
                                id: item?._id,
                                itemType: item?.serviceType,
                              });
                            }}
                            disabled={loading || isDeleting}
                          >
                            Edit
                          </Button>
                          <Button
                            className="btn btn-user btn-block btn-save"
                            onClick={() => {
                              setSelection({
                                id: innerItem?._id,
                                index: innerIndex,
                                parentIndex: index,
                              });
                              setShowDeleteModal(true);
                            }}
                            disabled={loading}
                          >
                            {isDeleting ? "Deleting..." : " Delete"}
                          </Button>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}

      <AddAndEditItemModal
        title={"Add Service Items"}
        type={"service"}
        visibleModal={addServiceItemsModal}
        typesArray={ServicesResponseData}
        selectedType={selectedServiceType}
        setSelectedType={setSelectedServiceType}
        inputItem={inputServiceItem}
        setInputItem={setInputServiceItem}
        onPress={() => {
          addServiceItemHandler();
        }}
        onPressCancel={() => {
          setSelectedServiceType({});
          setInputServiceItem("");
          setAddServiceItemsModal(!addServiceItemsModal);
        }}
        loading={loading}
      />

      <AddAndEditItemModal
        title={"Edit Service Items"}
        type={"service"}
        visibleModal={editAddServiceItemsModal}
        typesArray={ServicesResponseData}
        selectedType={selectedServiceType}
        setSelectedType={setSelectedServiceType}
        inputItem={inputServiceItem}
        setInputItem={setInputServiceItem}
        onPress={() => {
          EditHandler();
        }}
        onPressCancel={() => {
          setEditItem({});
          setSelectedServiceType({});
          setInputServiceItem("");
          setEditAddServiceItemsModal(false);
        }}
        loading={loading}
      />

      <ConfirmModal
        isModalVisible={showDeleteModal}
        setIsModalVisible={setShowDeleteModal}
        onClickNo={resetStates}
        onClickYes={deleteHandler}
        confirmTextLine={"Are you sure you want to delete this item?"}
        isApiCall={loading}
      />
    </>
  );
}

export default AddServicesItems;
