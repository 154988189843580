import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { loginVector, coverPhoto } from "../constant/imagepath";
import { useDispatch, useSelector } from "react-redux";
import { imageUrl, voucherImageUrl } from "../Config/apiUrl";
import axios from "axios";
import { getNewPageDetails } from "../store/actions/mentorActions";
// import { SpinnerRoundOutlined } from "spinners-react";

function AddVoucherDetails() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const voucherDetails = useSelector(
    (state) => state.mentorReducer.pageDetails
  );
  const [voucherData, setVoucherData] = useState(voucherDetails);

  useEffect(() => {
    const responseData = voucherDetails;
    if (responseData !== undefined && responseData !== null) {
      setVoucherData(voucherDetails);
    }
    return () => {
      dispatch(getNewPageDetails(null));
    };
  }, [voucherDetails]);

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequestDetails">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Voucher Details</h1>
        {/* <p className="mb-4">New Mechanic Requests list</p> */}

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            {/* <h6 className="m-0 font-weight-bold text-primary">DataTables Example</h6> */}

            {/* profile */}
            <div className="row">
              {/* <div className="col-md-12">
                <div className="cover-photo">
                  <img
                    src={`${nodeImageUrl}${newMentors.coverPhoto}`}
                    alt="cover"
                    className="image-fluid"
                  />
                </div>
              </div> */}

              <div className="col-md-3">
                <div className="profile">
                  <img
                    src={`${imageUrl}/${voucherData?.image}`}
                    alt=""
                    className="image-fluid"
                  />
                </div>
              </div>
              <div className="col-md-9">
                <div>
                  <h2>{voucherData.title}</h2>
                  <p className="location-lang">Code {voucherData.code}</p>
                  <p className="location-lang">
                    Discount {voucherData.discount}
                  </p>
                </div>
                <div>
                  <p className="bio-text"> Web link : {voucherData.web_link}</p>
                  <p className="bio-text">
                    Voucher Start: {voucherData.voucher_start}{" "}
                  </p>
                  <p className="bio-text">
                    Voucher End: {voucherData.voucher_end}
                  </p>
                  <p className="aboutMe-text">About Voucher</p>
                  <p className="aboutMe-para">{voucherData.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}
    </>
  );
}

export default AddVoucherDetails;
