import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { URL, imageUrl } from "../Config/apiUrl";
import Pagination from "../components/Pagination";
import { getNewMentors } from "../store/actions/mentorActions";
import { useDispatch, useSelector } from "react-redux";
import { Get, Patch } from "../Axios/AxiosFunctions";
import { useHistory } from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";

function NewMechanicRequests(props) {
  // const { setChoice } = props;
  // const newMentors = useSelector((state) => state.mentorReducer.unApprovedMentors);
  const [unApprovedMechanic, setUnApprovedMechanic] = useState([]);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const dispatch = useDispatch();
  let history = useHistory();
  const getMechanicList = async () => {
    const certificatesApiURL = URL(
      `admin/mechanics/unverified?page=${pageNumber}&limit=${limit}`
    );
    setIsFetchingData(true);
    // setLoading(true);
    const responseData = await Get(certificatesApiURL, accessToken);
    let maxLastLength =
      pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
    setMaxLength(maxLastLength);
    if (responseData !== undefined) {
      console.log({ responseData });
      setUnApprovedMechanic(responseData.data.data);
      // console.log(responseData?.data?.data)
      // setUnApprovedMechanic(responseData?.data?.data);
    } else {
      setUnApprovedMechanic([]);
      console.log("error");
    }
    // setLoading(false);
    setIsFetchingData(false);
  };

  useEffect(() => {
    GetSubscriptionPlans();
  }, []);

  useEffect(() => {
    getMechanicList();
    // var config = {
    //   method: "get",
    //   url: NODE_URL(`mentor/pending?limit=${limit}&page=${pageNumber}`),
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    // };
    // axios(config)
    //   .then(function (response) {
    //     let maxLastLength =
    //       pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
    //     setMaxLength(maxLastLength);
    //     setUnApprovedMechanic(response.data.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, [pageNumber]);

  const GetSubscriptionPlans = async () => {
    const subscriptionPlansApiURL = URL(`get-plans`);
    setIsLoading(true);
    const responseData = await Get(subscriptionPlansApiURL, accessToken, false);
    if (responseData !== undefined) {
      setSubscriptionPlans(responseData.data.data);
    }
    setIsLoading(false);
  };

  const Header = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return (
    <>
      {/* <!-- DataTales Example --> */}
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            New Mechanic Requests
          </h6>
        </div>
        <div className="card-body">
          {isFetchingData === true || isLoading == true ? (
            <div className="col-md-12 darken-newMechanic on-loading">
              <SpinnerRoundOutlined
                enabled={isFetchingData}
                thickness={200}
                color="#AB2330"
              />{" "}
            </div>
          ) : (
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <td>Photo</td>
                    <th>Business Name</th>
                    {/* <th>Id</th> */}
                    {/* <th>Country / City</th> */}
                    {/* <th>Country</th> */}
                    <th>Email</th>
                    <th>Package</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading == false &&
                    unApprovedMechanic.map((item, index) => {
                      const filterIndex = subscriptionPlans.findIndex(
                        (x) => (x) => x?.id == item?.plan
                      );
                      return (
                        <>
                          <tr key={item._id}>
                            <td className="table-image">
                              <img
                                src={`${imageUrl}/${item?.photo}`}
                                alt=""
                                className="image-fluid"
                              />
                            </td>
                            <td>{item?.userName}</td>
                            {/* <td>{item._id}</td> */}
                            <td>{item?.UserId?.email}</td>
                            <td>
                              {filterIndex !== -1
                                ? subscriptionPlans[filterIndex]?.product?.name
                                : "Null"}
                            </td>
                            {/* <td>{item.country}, {" "}{item.city}</td>
                            <td>{item.preferredLanguage}</td> */}
                            {/* <td>View Detail</td> */}
                            <td>
                              <Button
                                className={`btn btn-user btn-block ${
                                  item.active ? "btn-approved" : "btn-pending"
                                }`}
                                onClick={() => {
                                  history.push(
                                    `/NewMechanicRequestsDetails/${item?._id}`
                                  );
                                  dispatch(getNewMentors(item));
                                  console.log(item);
                                }}
                              >
                                View Detail
                              </Button>
                            </td>

                            {/* <td>
                            <Button
                        className="btn btn-user btn-block btn-approved"
                        onClick={() => {
                
                          approve(item?._id, index);
                        }}
                        disabled={loading}
                      >
                        {loading?'loading' : 'Approve'}
                        
                      </Button>
                            </td> */}

                            {/* <td>
                              <Button
                                className={`btn btn-user btn-block decline-color ${
                                  item.active ? "btn-approved" : "btn-pending"
                                }`}
                                onClick={() => decline(item.UserId._id)}
                              >
                                {item.active ? "Pending" : "Decline"}
                              </Button>
                            </td> */}
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              <div className="pagination">
                <Pagination
                  maxLastLength={maxLength}
                  array={unApprovedMechanic}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default NewMechanicRequests;

// return (
//   <>
//     {/* <!-- DataTales Example --> */}
//     <div className="card shadow mb-4">
//       <div className="card-header py-3">
//         <h6 className="m-0 font-weight-bold text-primary">
//           DataTables Example
//         </h6>
//       </div>
//       <div className="card-body">
//         <div className="table-responsive">
//           <table
//             className="table table-bordered"
//             id="dataTable"
//             width="100%"
//             cellspacing="0"
//           >
//             <thead>
//               <tr>
//                 <th>Name</th>
//                 <th>Id</th>
//                 <th>data</th>
//                 <th>data</th>
//                 <th>Total Balance</th>
//                 <th>Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {NewMentorRequest.map((item) => {
//                 return (
//                   <>
//                     <tr key={item.id} onClick={() => viewHandler()}>
//                       <td>{item.name}</td>
//                       <td>{item.m_id}</td>
//                       <td>{item.data}</td>
//                       <td>{item.country}</td>
//                       <td>{item.balance}</td>
//                       <td>
//                         <Button
//                           className={`btn btn-user btn-block ${
//                             item.status ? "btn-approved" : "btn-pending"
//                           }`}
//                           onClick={console.log("approved")}
//                         >
//                           {item.status ? "Approved" : "Pending"}
//                         </Button>
//                       </td>
//                     </tr>
//                   </>
//                 );
//               })}
//             </tbody>
//           </table>
//           <div className="pagination">
//             <Pagination />
//           </div>
//         </div>
//       </div>
//     </div>

//     {/* <!-- /.container-fluid --> */}
