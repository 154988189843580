import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Get, Post } from "../Axios/AxiosFunctions";
import { apiUrl, URL } from "../Config/apiUrl";
import { SpinnerRoundOutlined } from "spinners-react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { getNewPageDetails } from "../store/actions/mentorActions";

function ContentEditor() {
  const dispatch = useDispatch();
  const history = useHistory();

  //redux Value
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const newpageDetails = useSelector(
    (state) => state.mentorReducer?.pageDetails
  );
  //api Header And Url

  const submitApiURL = URL(
    `${newpageDetails?.linkPath}/${newpageDetails?._id}`
  );

  const Header = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const quillRef = useRef();

  // Page Edit State's
  const [title, setTitle] = useState(newpageDetails?.title);
  const [content, setContent] = useState(newpageDetails?.content);
  const [submitLoading, setSubmitLoading] = useState(false);

  const CheckRequiredField = (obj) => {
    let requiredFields = false;
    Object.keys(obj).forEach(function (key) {
      if (obj[key] == "") {
        requiredFields = true;
      }
    });
    return requiredFields;
  };

  const submit = async () => {
    let data = {
      title: title,
      content: `${content}`,
    };
    let requiredNull = CheckRequiredField(data);
    if (requiredNull == true) {
      toast.error("Enter Required Fields.");
      return null;
    }
    setSubmitLoading(true);
    let responseData = await Post(submitApiURL, data, Header);

    if (responseData !== undefined) {
      toast.success(`${title} Content has been updated SuccessFully`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setSubmitLoading(false);
  };

  const handleChange = (html) => {
    // var limit = 7499;
    // var quill = quillRef.current.getEditor();
    // quill.on('text-change', function (delta, old, source) {
    //   if (quill.getLength() > limit) {
    //    quill.deleteText(limit, quill.getLength());
    //   }
    // });
    setContent(html);
  };

  useEffect(() => {
    return () => {
      dispatch(getNewPageDetails(null));
    };
  }, []);
  return (
    <div className="container-fluid newMentorRequestDetails">
      <div className="d-flex align-items-center">
        <div
          className="save-container"
          style={{
            // width: "7.5%",
            marginRight: "1%",
            // marginLeft: "2%",
          }}
        >
          <Button
            className="btn btn-user btn-block btn-save"
            onClick={() => {
              dispatch(getNewPageDetails(null));
              history.goBack();
            }}
            disabled={submitLoading}
          >
            Back
          </Button>
        </div>
        <h1
          className="h3 mb-2 text-gray-800"
          style={{ textTransform: "uppercase" }}
        >
          {title}
        </h1>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <div className="col-md-12">
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={content}
              onChange={handleChange}
            />
          </div>

          <div
            className="row"
            style={{
              paddingRight: "inherit",
            }}
          >
            {/* <div className="col-md-12 d-flex justify-content-end" > */}
            {/* <p>{content !== undefined ? content.length : 0}/10000</p> */}
            {/* <p>{quillRef.current !== undefined ? quillRef.current.getEditor().getLength() : 0}/7500</p> */}
            {/* <p>{quillRef.current !== undefined ? quillRef.current.getEditor().getLength() : content !== undefined ? content.length : 0}/7500</p> */}
            {/* </div> */}

            <div className="col-md-12">
              <div className="save-container">
                <Button
                  className="btn btn-user btn-block btn-save"
                  onClick={() => submitLoading == false && submit()}
                  disabled={submitLoading}
                >
                  {submitLoading == true ? "loading" : "Save"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentEditor;
