import React, { useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import { Button } from "react-bootstrap";
import { URL, apiUrl, imageUrl } from "../Config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { Get, Patch, Delete, Post } from "../Axios/AxiosFunctions";
import { SpinnerRoundOutlined } from "spinners-react";
import { toast } from "react-toastify";
import AddAndEditCmsWebItemModal from "../components/AddAndEditCmsWebItemModal";
import ConfirmModal from "../components/ConfirmModal";

function Services(props) {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [isFetchingData, setIsFetchingData] = useState(true);

  const [loading, setLoading] = useState(false);

  const [itemArray, setItemArray] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState("");
  const [iconDisplay, setIconDisplay] = useState(null);
  const [itemDetail, setItemDetail] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const addUrl = URL(`web/services`);

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const resetStates = () => {
    setShowDeleteModal(false);
  };

  const getServiceItems = async () => {
    const getApiURL = URL(
      `web/all-services?type=Service&page=${pageNumber}&limit=${limit}`
    );
    setIsFetchingData(true);
    const responseData = await Get(getApiURL, accessToken);
    let maxLastLength =
      pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
    setMaxLength(maxLastLength);
    if (responseData !== undefined) {
      setItemArray(responseData.data.data);
    } else {
      setItemArray([]);
      toast.error("error");
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    getServiceItems();
  }, [pageNumber]);

  const deleteHandler = async () => {
    const deleteApiUrl = URL(`web/services/${selectedItem?._id}`);
    let index = itemArray?.findIndex((ele) => ele?._id === selectedItem?._id);

    setLoading(true);
    const responseData = await Delete(deleteApiUrl, null, authHeader);
    if (responseData !== undefined) {
      let arr = itemArray.slice();
      arr.splice(index, 1);
      setItemArray(arr);
      setShowDeleteModal(false);
      setSelectedItem(null);
      toast.success("Item Deleted Successfully!");
    }
    setLoading(false);
  };

  const imgAuthHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const uploadImages = async (event) => {
    if (event.target.files.length > 0) {
      setIcon(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (event) => {
        setIconDisplay(event.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const createFormdata = async () => {
    var formData = new FormData();
    formData.append("icon", icon);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("type", "Service");
    return formData;
  };

  const addServiceHandler = async () => {
    setSaveLoading(true);
    let returnFormData = await createFormdata();
    const responseData = await Post(addUrl, returnFormData, imgAuthHeader);
    if (responseData !== undefined) {
      let oldData = itemArray.slice();
      oldData.push(responseData?.data?.data);
      setItemArray(oldData);
      toast.success("Item Successfully Added.");
      cancelHandler(setShowAddModal);
    }
    setSaveLoading(false);
  };

  const editItemHandler = async () => {
    const editUrl = URL(`web/services/${itemDetail.id}`);
    setSaveLoading(true);
    let returnFormData = await createFormdata();
    const responseData = await Post(editUrl, returnFormData, imgAuthHeader);
    if (responseData !== undefined) {
      let oldData = itemArray.slice();
      oldData.splice(itemDetail.index, 1, responseData?.data?.data);
      setItemArray(oldData);
      toast.success("Item Edit Successfully.");
      cancelHandler(setShowEditModal);
    }
    setSaveLoading(false);
  };

  const cancelHandler = async (setter) => {
    setter(false);
    setItemDetail({});
    setTitle("");
    setDescription("");
    setIcon("");
    setIconDisplay(null);
  };

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequests">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Service Items</h1>
        <p className="mb-4">Add and Edit Services</p>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex">
            <h6 className="m-0 font-weight-bold text-primary">
              Add Service Items
            </h6>
            <div className="addBtn">
              <i
                className="fas fa-plus-circle"
                onClick={() => setShowAddModal(true)}
              ></i>
            </div>
          </div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#AB2330"
                />{" "}
              </div>
            ) : (
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Icon</th>
                      <th>Title</th>
                      <th>Type</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemArray.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {item?.icon ? (
                                <img
                                  style={{ width: "50px", height: "50px" }}
                                  src={`${imageUrl}/${item?.icon}`}
                                />
                              ) : (
                                <img
                                  style={{ width: "50px", height: "50px" }}
                                  src={`${item?.icon}`}
                                />
                              )}
                            </td>
                            <td>{item.title}</td>
                            <td>{item.type}</td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() => {
                                  setSelectedItem(item);
                                  setShowDeleteModal(true);
                                }}
                                disabled={loading}
                              >
                                Delete
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() => {
                                  setShowEditModal(true);
                                  setItemDetail({
                                    index: index,
                                    id: item?._id,
                                  });
                                  setTitle(item.title);
                                  setDescription(item.description);
                                  setIcon(item.icon);
                                }}
                                disabled={loading}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>

                <div className="pagination">
                  <Pagination
                    maxLastLength={maxLength}
                    array={setItemArray}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}

      <AddAndEditCmsWebItemModal
        title={"Add Service"}
        type={"Service"}
        visibleModal={showAddModal}
        itemTitle={title}
        setItemTitle={setTitle}
        itemDescription={description}
        setItemDescription={setDescription}
        icon={icon}
        iconDisplay={iconDisplay}
        onPress={() => {
          addServiceHandler();
        }}
        onPressCancel={() => {
          cancelHandler(setShowAddModal);
        }}
        itemLoading={saveLoading}
        uploadImages={uploadImages}
      />

      <AddAndEditCmsWebItemModal
        title={"Edit Service"}
        type={"Service"}
        visibleModal={showEditModal}
        itemTitle={title}
        setItemTitle={setTitle}
        itemDescription={description}
        setItemDescription={setDescription}
        icon={icon}
        iconDisplay={iconDisplay}
        onPress={() => {
          editItemHandler();
        }}
        onPressCancel={() => {
          cancelHandler(setShowEditModal);
        }}
        itemLoading={saveLoading}
        uploadImages={uploadImages}
      />
      <ConfirmModal
        isModalVisible={showDeleteModal}
        setIsModalVisible={setShowDeleteModal}
        onClickNo={resetStates}
        onClickYes={deleteHandler}
        confirmTextLine={"Are you sure you want to delete this item?"}
        isApiCall={loading}
      />
    </>
  );
}

export default Services;
