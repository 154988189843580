import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";

const AddAndEditItemModal = ({
  title,
  type,
  visibleModal,
  typesArray,
  selectedType,
  setSelectedType,
  inputItem,
  setInputItem,
  onPress,
  onPressCancel,
  loading,
}) => {
  return (
    <>
      <Modal
        className="modal-dialog-centered custom-addLang-modal"
        isOpen={visibleModal}
        toggle={loading == false ? onPressCancel : () => {}}
      >
        {/* <ModalBody> */}
        <div className="modal-Header">
          <h2 className="text-center">{title}</h2>
        </div>
        <div className="addlang-modalBody">
          <h2 className="text-center">{title}</h2>
          <div className="innerContainer">
            <div className="form-group">
              <DropdownButton
                id="dropdown-basic-button"
                title={`${
                  selectedType?.itemType == undefined
                    ? type == "service"
                      ? "Select Service Item"
                      : "Select Certificate Item"
                    : selectedType?.itemType
                }`}
                onSelect={(e) => {
                  let item = typesArray.filter((x) => {
                    return x._id == e;
                  });
                  if (type == "service") {
                    setSelectedType({
                      id: e,
                      itemType: item[0]?.serviceType,
                    });
                  } else {
                    setSelectedType({
                      id: e,
                      itemType: item[0]?.CertificateType,
                    });
                  }
                }}
              >
                {typesArray.map((item, index) => (
                  <Dropdown.Item
                    className="addCertificateItems"
                    key={index}
                    eventKey={item._id}
                  >
                    {type == "service"
                      ? item.serviceType
                      : item.CertificateType}
                  </Dropdown.Item>
                ))}
              </DropdownButton>

              <input
                type="text"
                placeholder={
                  type == "service"
                    ? "Add Service Items"
                    : "Add Certificate Items"
                }
                value={inputItem}
                onChange={(e) => setInputItem(e.target.value)}
              />
            </div>
          </div>

          <div className="modal-footer footer-div">
            <div>
              <Button
                className="btn btn-user btn-block btn-cancel"
                disabled={loading}
                onClick={loading == false ? onPressCancel : () => {}}
              >
                Cancel
              </Button>
            </div>

            <div>
              <Button
                className="btn btn-user btn-block btn-save"
                disabled={loading}
                onClick={loading == false ? onPress : () => {}}
              >
                {loading == false ? "Save" : "Saving..."}
              </Button>
            </div>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </>
  );
};

export default AddAndEditItemModal;
