import React, { useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import { Button } from "react-bootstrap";
import { URL, imageUrl, apiUrl } from "../Config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { Get, Patch, Delete, Post } from "../Axios/AxiosFunctions";
import { getNewPageDetails } from "../store/actions/mentorActions";
import { useHistory } from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";
import AddCmsGoalsModal from "../components/AddCmsGoalsModal";
import EditCmsGoalsModal from "../components/EditCmsGoalsModal";
import { toast } from "react-toastify";

function Pages(props) {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);

  const [isFetchingData, setIsFetchingData] = useState(true);

  const ApiURL = URL(`pages?all=${true}`);

  const [pagesList, setPagesList] = useState([]);

  const dispatch = useDispatch();
  let history = useHistory();

  const getPagesListData = async () => {
    setIsFetchingData(true);
    const responseData = await Get(ApiURL, accessToken);
    if (responseData !== undefined) {
      setPagesList(responseData.data.pages);
    } else {
      setPagesList([]);
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    getPagesListData();
  }, []);

  return (
    <div className="container-fluid newMentorRequests">
      {/* <!-- Page Heading --> */}
      <h1 className="h3 mb-2 text-gray-800">Pages</h1>
      {/* <p className="mb-4">
        Lorem Ipsum is simply dummy text of the printing and typesetting
      </p> */}

      {/* <!-- DataTales Example --> */}
      <div className="card shadow mb-4">
        <div className="card-body">
          {isFetchingData ? (
            <div className="col-md-12 darken-newMechanic on-loading">
              <SpinnerRoundOutlined
                enabled={isFetchingData}
                thickness={200}
                color="#AB2330"
              />{" "}
            </div>
          ) : (
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Page Name</th>
                    {/* <th>Short Description</th> */}
                  </tr>
                </thead>
                <tbody>
                  {pagesList.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.pageName.replaceAll("_", " ")}</td>
                          {/* <td>{item.Short_description}</td> */}
                          <td>
                            <Button
                              className={`btn btn-user btn-block ${"btn-pending"}`}
                              onClick={() => {
                                history.push("/PageDetails");
                                dispatch(getNewPageDetails(item));
                                console.log(item);
                              }}
                            >
                              View Detail
                            </Button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Pages;
