import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { Post, Patch } from "../../Axios/AxiosFunctions";
import { toast } from "react-toastify";
import Input from "../Input";
import classes from './AddOrUpdateEmailTemplateModal.module.css'

const AddOrUpdateEmailTemplateModal = ({
    show,
    setShow,
    loading,
    data,
    onSave,
    type = 'add'
}) => {

    const [content, setContent] = useState('')
    const [key, setKey] = useState('')

    useEffect(() => {
        if (data !== undefined) {
            setContent(data?.message)
            setKey(data?.key)
        }
    }, [])

    return (
        <>
            <Modal
                className="modal-dialog-centered custom-addLang-modal"
                isOpen={show}
                toggle={() => setShow(false)}
            >
                {/* <ModalBody> */}
                <div className="modal-Header">
                    <h2 className="text-center">{`${type == 'add' ? 'Add' : "Update"}`} Email Template</h2>
                </div>
                <div className="addlang-modalBody">
                    <div className="innerContainer">
                        <div className="form-group">
                            {type == 'add' && <div className={classes.inputBox}>
                                <Input setter={setKey} value={key} placeholder={'Name'} />
                            </div>}
                            <div className="Plans-textArea">
                                <textarea
                                    className="w-100"
                                    type="text"
                                    rows={10}
                                    placeholder="Content"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer footer-div">
                        <div>
                            <Button
                                className="btn btn-user btn-block btn-cancel"
                                onClick={() => setShow(false)}
                                disabled={loading}
                            >
                                Cancel
                            </Button>
                        </div>

                        <div>
                            <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() => {
                                    let params = { key, message: content }
                                    if (key == '') {
                                        return toast.error(`Name can not be empty`)
                                    }
                                    if (content == '') {
                                        return toast.error(`Content can not be empty`)
                                    }
                                    onSave(params)
                                }}
                                disabled={loading}
                            >
                                {loading ? `${type == 'add' ? 'Creating...' : 'Updating...'}` : `${type == 'add' ? 'Create' : 'Update'}`}
                            </Button>
                        </div>
                    </div>
                </div>
                {/* </ModalBody> */}
            </Modal>
        </>
    );
};

export default AddOrUpdateEmailTemplateModal;
