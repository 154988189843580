import React from "react";
import { Modal } from "reactstrap";
import { Button } from "react-bootstrap";

const AddCertificateTypesModal = ({
  addCertificateTypesModal,
  setAddCertificateTypesModal,
  onPress,
  value,
  setValue,
  loading,
  title,
}) => {
  return (
    <>
      <Modal
        className="modal-dialog-centered custom-addLang-modal"
        isOpen={addCertificateTypesModal}
      >
        {/* <ModalBody> */}
        <div className="modal-Header">
          <h2 className="text-center">{title}</h2>
        </div>
        <div className="addlang-modalBody">
          <div className="innerContainer">
            <div className="form-group">
              <input
                type="text"
                placeholder={title}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
          </div>

          <div className="modal-footer footer-div">
            <div>
              <Button
                className="btn btn-user btn-block btn-cancel"
                onClick={() => setAddCertificateTypesModal(false)}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>

            <div>
              <Button
                className="btn btn-user btn-block btn-save"
                onClick={onPress}
                disabled={loading}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </>
  );
};

export default AddCertificateTypesModal;
