import {
  SIGN_OUT,
  LOGIN_SUCCESS,
  SET_CERTIFICATES_SERVICES_ARRAY,
} from "../actions/actionTypes";

const initState = {
  userType: "",
  isLogin: false,
  accessToken: null,
  user: null,
  certificatesArray: [],
  servicesArray: [],
};
const globalReducer = (state = initState, action) => {
  switch (action.type) {
    case SIGN_OUT:
      return {
        ...state,
        userType: "",
        isLogin: false,
        accessToken: null,
        user: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        userType: action?.data?.data?.user?.role,
        isLogin: true,
        accessToken: action?.data?.token,
        user: action?.data?.data?.user,
      };
    case SET_CERTIFICATES_SERVICES_ARRAY:
      return {
        ...state,
        certificatesArray: action.payload?.certificate,
        servicesArray: action.payload?.service,
      };
    default:
      return state;
  }
};

export default globalReducer;
