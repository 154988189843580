import React, { useState, useEffect, useRef } from "react";
import classes from "./profilewitheditbutton.module.css";
import {
  defaultProfilePhoto,
  defaultCoverPhoto,
} from "../../constant/imagepath";
import { imageUrl, URL as baseUrl } from "../../Config/apiUrl";
import { toast } from "react-toastify";
import { HiPencil } from "react-icons/hi";

const ProfileWithEditButton = ({
  updateImage,
  setUpdateImage,
  className,
  onErrorImage,
  classBtn,
  classImg,
  edit = true,
}) => {
  const inputRef = useRef(null);

  return (
    <>
      <div
        className={`${classes.profileEditContainer} ${className}`}
        style={{
          position: edit ? "relative" : "unset",
        }}
      >
        {typeof updateImage === "object" ? (
          // <div className={`${classes.profileEditImage_box}`}>
          <img
            className={`${classes.profileEditImage} ${classImg}`}
            src={URL.createObjectURL(updateImage)}
          />
        ) : // </div>
        updateImage !== "" ? (
          // <div className={`${classes.profileEditImage_box}`}>
          <img
            className={`${classes.profileEditImage} ${classImg}`}
            src={`${imageUrl}/${updateImage}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = onErrorImage
                ? defaultCoverPhoto
                : defaultProfilePhoto;
            }}
          />
        ) : (
          //  </div>
          // <div className={`${classes.profileEditImage_box}`}>
          <img
            className={`${classes.profileEditImage} ${classImg}`}
            src={updateImage}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = onErrorImage
                ? defaultCoverPhoto
                : defaultProfilePhoto;
            }}
            alt="profileEditImage"
          />
          // </div>
        )}
        {edit && (
          <div className={`${classes.profileEditPen_box} ${classBtn}`}>
            <HiPencil
              color="white"
              className={`${classes.profileEditPen}`}
              onClick={() => {
                inputRef.current.click();
              }}
            />
            <input
              ref={inputRef}
              type="file"
              size="2000000"
              className={`${classes.file_upload_form3rd}`}
              onChange={(e) => {
                if (e.target.files?.length > 0) {
                  if (
                    !["image/jpeg", "image/png", "image/jpg"].includes(
                      e.target.files[0].type
                    )
                  ) {
                    return toast.error(
                      "Please upload a valid image. [jpg and png formats only]"
                    );
                  }
                  // max size 2MB
                  if (e.target.files[0]?.size / 1024 / 1024 > 2)
                    return toast.error(
                      "Please upload a valid image. [Max size: 2MB]"
                    );

                  setUpdateImage(e.target.files[0]);
                }
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileWithEditButton;
