import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { Button, Col, Row } from "react-bootstrap";
import { Checkbox } from "../Checkbox";
import { useSelector } from "react-redux";
import classes from "./MechanicFilterModal.module.css";
import DropDown from "../DropDown";
import StateCitySelect from "../StateCitySelect";

const sortArray = [
  {
    label: "A-Z By Name",
    value: "A-Z",
  },
  {
    label: "Z-A By Name",
    value: "Z-A",
  },
  {
    label: "Highest Rating",
    value: "highestRating",
  },
  {
    label: "Lowest Rating",
    value: "lowestRating",
  },
  {
    label: "Most Booking",
    value: "mostBooking",
  },
  {
    label: "Most Contacted",
    value: "mostContacted",
  },
];
const statusArray = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Activated",
    value: "activated",
  },
  {
    label: "Deactivated",
    value: "deactivated",
  },
];

const MechanicFilterModal = ({
  showModal,
  setShowModal,
  filterData,
  setFilterData,
}) => {
  console.log(filterData);
  const { certificatesArray, servicesArray } = useSelector(
    (state) => state.globalReducer
  );
  const [selectedState, setSelectedState] = useState(filterData?.state || "");

  const [selectedCertificates, setSelectedCertificates] = useState(
    filterData?.certificates || []
  );
  const [selectedServices, setSelectedServices] = useState(
    filterData?.services || []
  );
  const [selectedStatus, setSelectedStatus] = useState(
    filterData?.status || []
  );
  const [selectedMMHVerified, setSelectedMMHVerified] = useState(
    filterData?.mmhVerified ? "MMH Verified" : ""
  );
  const [selectedMemberShiped, setSelectedMemberShiped] = useState(
    filterData?.membershipedUser ? "MemberShip User" : ""
  );
  const [selectedSort, setSelectedSort] = useState(filterData?.sort || []);
  const onClear = () => {
    setShowModal(false);
    setSelectedCertificates([]);
    setSelectedServices([]);
    setSelectedStatus([]);
    setSelectedSort([]);
    setSelectedMMHVerified("");
    setSelectedMemberShiped("");
    setSelectedState("");
    // setFilterData({});
    setFilterData({
      certificates: [],
      services: [],
      status: "all",
      sort: "A-Z",
      mmhVerified: "",
      membershipedUser: "",
      state: "",
    });
  };
  const onApply = () => {
    setFilterData({
      certificates: selectedCertificates,
      services: selectedServices,
      status: selectedStatus?.value,
      sort: selectedSort?.value,
      mmhVerified: selectedMMHVerified !== "" ? true : false,
      membershipedUser: selectedMemberShiped !== "" ? true : false,
      state: selectedState?.name,
    });
    setShowModal(false);
  };

  useEffect(() => {
    if (filterData?.status?.length !== 0) {
      const filter = statusArray.find(
        (item, index) => item?.value == filterData?.status
      );
      setSelectedStatus(filter);
    }
    if (filterData?.sort?.length !== 0) {
      const sortData = sortArray.find(
        (item, index) => item?.value == filterData?.sort
      );
      setSelectedSort(sortData);
    }
  }, [filterData]);

  return (
    <>
      <style>{`
    .modal-dialog {
      max-width:900px !important;
    }
    .modal-Header {
      border-radius:unset;
          border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    }
    
    `}</style>
      <Modal
        className="modal-dialog-centered custom-addLang-modal"
        isOpen={showModal}
        toggle={() => {
          setShowModal(false);
        }}
      >
        <div className="modal-Header">
          <h2 className="text-center">Filter</h2>
        </div>
        <div className="addlang-modalBody">
          <Row className={[classes.modalRow, `g-0`].join(" ")}>
            <Col md={12}>
              <h4>Certificates</h4>
            </Col>

            {certificatesArray?.map((item, i) => {
              return (
                <Col md={6} className={[classes.certificateCol]} key={i}>
                  <Checkbox
                    value={selectedCertificates}
                    label={item?.CertificateType}
                    disabled={false}
                    setValue={(e) => {
                      setSelectedCertificates(e);
                    }}
                  />
                </Col>
              );
            })}
          </Row>
          <Row className={[classes.modalRow, `g-0`].join(" ")}>
            <Col md={12}>
              <h4>Services</h4>
            </Col>

            {servicesArray?.map((item, i) => {
              return (
                <Col md={6} className={[classes.certificateCol]} key={i}>
                  <Checkbox
                    value={selectedServices}
                    label={item?.serviceType}
                    disabled={false}
                    setValue={(e) => {
                      setSelectedServices(e);
                    }}
                  />
                </Col>
              );
            })}
          </Row>
          {/*  */}

          <Row className={[classes.modalRow, `g-0`].join(" ")}>
            <Col md={12}>
              <h4>Others</h4>
            </Col>
            <Col md={6}>
              <div className={[classes.certificateCol]}>
                <Checkbox
                  value={selectedMemberShiped}
                  label="MemberShip User"
                  disabled={false}
                  isMulti={false}
                  setValue={setSelectedMemberShiped}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className={[classes.certificateCol]}>
                <Checkbox
                  value={selectedMMHVerified}
                  label="MMH Verified"
                  disabled={false}
                  isMulti={false}
                  setValue={setSelectedMMHVerified}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <StateCitySelect
                selectedState={selectedState}
                setSelectedState={setSelectedState}
              />
            </Col>
          </Row>

          {/*  */}
          <Row className={[classes.modalRow, `g-0`].join(" ")}>
            <Col md={12}>
              <h4>Status</h4>
            </Col>
            <Col md={12}>
              <DropDown
                data={statusArray}
                value={selectedStatus}
                setValue={setSelectedStatus}
              />
            </Col>
          </Row>
          <Row className={[classes.modalRow, `g-0`].join(" ")}>
            <Col md={12}>
              <h4>Sort by</h4>
            </Col>
            <Col md={12}>
              <DropDown
                data={sortArray}
                value={selectedSort}
                setValue={setSelectedSort}
              />
            </Col>
          </Row>

          <div className="modal-footer footer-div">
            <div>
              <Button
                className="btn btn-user btn-block btn-cancel"
                onClick={onClear}
              >
                Clear
              </Button>
            </div>

            <div>
              <Button
                className="btn btn-user btn-block btn-save"
                onClick={onApply}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MechanicFilterModal;
