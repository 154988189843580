import { LOGIN_SUCCESS, SET_CERTIFICATES_SERVICES_ARRAY } from "./actionTypes";
export const islogin = (data) => {
  console.log("...", data);
  return (dispatch, getState) => {
    dispatch({ type: LOGIN_SUCCESS, data });
  };
};
export const signUpAction = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: "SIGN_UP_ACTION", data });
  };
};
export const signOutAction = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SIGN_OUT" });
  };
};

export function setCertificatesAndServices(data) {
  return {
    type: SET_CERTIFICATES_SERVICES_ARRAY,
    payload: data,
  };
}
