import React from "react";
import classes from "./noData.module.css";
import Lottie from "lottie-react";
import animationData from "./noData.json";

function NoData({
  className,
  text = "No Data Found",
  animationPath = animationData,
}) {
  return (
    <div className={`${classes.noDataContainer} ${className && className}`}>
      <Lottie
        style={{
          width: "30%",
        }}
        className={classes.lottieContainer}
        rendererSettings={{
          preserveAspectRatio: "xMidYMid slice",
        }}
        loop={true}
        autoplay={true}
        animationData={animationPath}
      />
      <h3>{text}</h3>
    </div>
  );
}

export default NoData;
