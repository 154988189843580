import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Pagination from "../components/Pagination";
import AddCertificateTypesModal from "../components/AddCertificateTypesModal";
import EditAddCertificateTypesModal from "../components/EditAddCertificateTypesModal";
import { URL } from "../Config/apiUrl";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { SpinnerRoundOutlined } from "spinners-react";
import { Post, Patch, Get, Delete } from "../Axios/AxiosFunctions";
import ConfirmModal from "../components/ConfirmModal";
import { toast } from "react-toastify";

function AddCertificateTypes(props) {
  // const { setChoice } = props;
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [addCertificateTypes, setAddCertificateTypes] = useState([]);

  const [addCertificateTypesModal, setAddCertificateTypesModal] =
    useState(false);

  const [certificateTypes, setCertificateTypes] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(true);

  const [certificateType, setCertificateType] = useState("");

  // edit modal
  const [editAddCertificateTypesModal, setEditAddCertificateTypesModal] =
    useState(false);
  const [item, setItem] = useState({});
  const [index, setIndex] = useState("");
  const [editCertificateType, setEditCertificateType] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const resetStates = () => {
    setShowDeleteModal(false);
  };

  const getAllCertificateTypes = async () => {
    const allCertificateTypesApiURL = URL(
      // `admin/get-all-certificate-type?limit=${limit}&page=${pageNumber}`
      `certificates?limit=${limit}&page=${pageNumber}`
    );
    setIsFetchingData(true);
    const responseData = await Get(allCertificateTypesApiURL, accessToken);

    let fetchedData = [];
    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      let maxLastLength =
        pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
      setMaxLength(maxLastLength);
      fetchedData = [...responseData?.data?.data];
      setAddCertificateTypes(fetchedData);
    } else {
      console.log("error");
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    getAllCertificateTypes();
  }, [pageNumber]);

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const AddCertificateTypeHandler = async () => {
    setLoading(true);
    console.log("add function run");
    // setAddCertificateTypesModal(false);
    const addServiceType = URL(`certificate-type`);
    let obj = { CertificateType: certificateType };
    const responseData = await Post(addServiceType, obj, authHeader);
    if (responseData !== undefined) {
      let oldData = addCertificateTypes.slice();
      oldData.push(responseData?.data?.data);
      setAddCertificateTypes(oldData);
      setAddCertificateTypesModal(false);
      setCertificateType("");
      toast.success("Item Successfully Added.");
    }
    setLoading(false);
  };

  const deleteHandler = async () => {
    const deleteCertificateType = URL(`certificate-type/${selectedItem?._id}`);
    let index = addCertificateTypes?.findIndex(
      (ele) => ele?._id === selectedItem?._id
    );

    setLoading(true);
    const responseData = await Delete(deleteCertificateType, authHeader);
    if (responseData !== undefined) {
      let arr = addCertificateTypes.slice();
      arr.splice(index, 1);
      setAddCertificateTypes(arr);
      setShowDeleteModal(false);
      setSelectedItem(null);
      toast.success("Item Deleted Successfully.");
    }
    setLoading(false);
  };

  const EditHandler = async (item, index) => {
    setLoading(true);
    setEditCertificateType(item.CertificateType);

    const editItemsUrl = URL(`certificate-type/${item._id}`);

    let obj = { CertificateType: editCertificateType };

    const responseData = await Patch(editItemsUrl, obj, authHeader);
    if (responseData !== undefined) {
      let oldData = addCertificateTypes.slice();
      oldData.push(responseData?.data?.data);
      // setAddCertificateTypes(oldData)
      setEditAddCertificateTypesModal(false);
      // setAddCertificateTypes(oldData)
    }
    setLoading(false);
  };

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequests">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Add Certificate Type</h1>
        {/* <p className="mb-4">All Certificate list</p> */}

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex">
            <h6 className="m-0 font-weight-bold text-primary">
              Add Certificate Type
            </h6>
            <div className="addBtn">
              <i
                className="fas fa-plus-circle"
                onClick={() => setAddCertificateTypesModal(true)}
              ></i>
            </div>
          </div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#AB2330"
                />{" "}
              </div>
            ) : (
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      {/* <th>Certificate Id</th> */}
                      <th>Certificate Type</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {addCertificateTypes?.map((item, index) => {
                      console.log("check", addCertificateTypes);
                      return (
                        <>
                          <tr
                            key={item?._id}
                            // onClick={() => setChoice("MenteeDetails")}
                          >
                            <td>{index + 1}</td>
                            <td>{item?.CertificateType}</td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() => {
                                  setEditAddCertificateTypesModal(true);
                                  setItem(item);
                                  setIndex(index);
                                  setEditCertificateType(item.CertificateType);
                                }}
                                disabled={loading}
                              >
                                Edit
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() => {
                                  setSelectedItem(item);
                                  setShowDeleteModal(true);
                                }}
                                disabled={loading}
                              >
                                {loading === item._id ? "loading" : " Delete"}
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <Pagination
                    maxLastLength={maxLength}
                    array={addCertificateTypes}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}

      <AddCertificateTypesModal
        setAddCertificateTypesModal={setAddCertificateTypesModal}
        addCertificateTypesModal={addCertificateTypesModal}
        value={certificateType}
        setValue={setCertificateType}
        onPress={() => {
          AddCertificateTypeHandler();
        }}
        loading={loading}
        title={"Add Certificate Type"}
      />

      <EditAddCertificateTypesModal
        setEditAddCertificateTypesModal={setEditAddCertificateTypesModal}
        editAddCertificateTypesModal={editAddCertificateTypesModal}
        value={editCertificateType}
        setValue={setEditCertificateType}
        EditHandler={EditHandler}
        item={item}
        index={index}
        title={"Edit Certificate Type"}
        loading={loading}
      />

      <ConfirmModal
        isModalVisible={showDeleteModal}
        setIsModalVisible={setShowDeleteModal}
        onClickNo={resetStates}
        onClickYes={deleteHandler}
        confirmTextLine={"Are you sure you want to delete this item?"}
        isApiCall={loading}
      />
    </>
  );
}

export default AddCertificateTypes;
