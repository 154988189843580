import { NEW_PAGE_DETAILS, NEW_MENTOR_REQUEST, MENTOR_CHANGES,APPROVED_MECHANIC } from "../actions/actionTypes";

const initState = {
  pageDetails: [],
  unApprovedMentors: [],
  mentorChanges: {},
  approvedMechanic: {},
};
const mentorReducer = (state = initState, action) => {
  switch (action.type) {
    case NEW_PAGE_DETAILS:
      return {
        pageDetails: action?.data,
      };
    case NEW_MENTOR_REQUEST:
      return {
        unApprovedMentors: action?.data,
      };
    case MENTOR_CHANGES:
      console.log(action)
      return {
        mentorChanges: action?.data
      }
      case APPROVED_MECHANIC:
        console.log(action)
        return {
          approvedMechanic: action?.data
        }
    default:
      return state;
  }
};

export default mentorReducer;
