import React from "react";
import classes from "./TextArea.module.css";

export default function TextArea({
  value,
  setter,
  label,
  placeholder,
  customStyle,
  labelStyle,
  rows,
  leftIcon,
}) {
  return (
    <div className={classes.textAreaBox}>
      {label && <label style={{ ...labelStyle }}>{label}</label>}

      <div className={`${[classes.inputPassContainer].join(" ")}`}>
        {leftIcon && <div className={classes.leftIconBox}>{leftIcon}</div>}
        <textarea
          rows={rows}
          placeholder={placeholder}
          value={value}
          style={{ ...customStyle }}
          onChange={(e) => {
            setter(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
