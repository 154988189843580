import React, { useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { URL } from "../Config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Get, Patch, Delete, Post } from "../Axios/AxiosFunctions";
import { getNewMentors } from "../store/actions/mentorActions";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";
import EditVoucherModal from "../components/EditVoucherModal";
import AddFaqModal from "../components/AddFaqModal";
import EditFaqModal from "../components/EditFaqModal";
import { ToastContainer, toast } from "react-toastify";
import ConfirmModal from "../components/ConfirmModal";
import { successIcon, warningIcon } from "../constant/imagepath";
import AlertModal from "../components/AlertModal";

function Faq(props) {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);

  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [isFetchingData, setIsFetchingData] = useState(true);

  const [loading, setLoading] = useState(false);

  // ===== FQA's ======
  const [fAQ, setFAQ] = useState([]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [type, setType] = useState("");

  const [addFaqModal, setAddFaqModal] = useState(false);

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [editFaqModal, setEditFaqModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const dispatch = useDispatch();
  let history = useHistory();

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const getFaqList = async () => {
    const FaqApiURL = URL(`web/all-faq?page=${pageNumber}&limit=${limit}`);
    setIsFetchingData(true);
    // setLoading(true);
    const responseData = await Get(FaqApiURL, accessToken);
    let maxLastLength =
      pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
    setMaxLength(maxLastLength);
    if (responseData !== undefined) {
      console.log("FAQ data", responseData);
      setFAQ(responseData.data.data);
      // console.log(responseData?.data?.data)
      // setAddVoucher(responseData?.data?.data);
    } else {
      setFAQ([]);
      console.log("error");
    }
    // setLoading(false);
    setIsFetchingData(false);
  };

  useEffect(() => {
    getFaqList();
  }, [pageNumber]);

  // ==== FAQ Delete ====
  const deleteHandler = async () => {
    const deleteApiUrl = URL(`web/faq/${selectedItem?.item?._id}`);
    setLoading(true);
    const responseData = await Delete(deleteApiUrl, null, authHeader);
    if (responseData !== undefined) {
      let arr = fAQ.slice();
      arr.splice(selectedItem?.index, 1);
      setFAQ(arr);
      setShowAlertModal(false);
      setSelectedItem(null);
      toast.success("Item Deleted Successfully!");
    }
    setLoading(false);
  };

  //   === FAQ add =====
  const addFaqHandler = async () => {
    setLoading(true);
    const addFaqUrl = URL(`web/faq`);
    let obj = { question: question, answer: answer, type: type?.value };
    const responseData = await Post(addFaqUrl, obj, authHeader);
    if (responseData !== undefined) {
      let oldData = fAQ.slice();
      oldData.push(responseData?.data?.data);
      console.log({ oldData });
      setFAQ(oldData);
      toast.success("Successfully Added New FAQ");
      cancelHandler(setAddFaqModal);
    }
    setLoading(false);
  };

  //  ===== Edit FAQ ====
  const EditHandler = async (item, index) => {
    const editUrl = URL(`web/faq/${selectedItem?.item?._id}`);
    setLoading(true);
    let obj = { question: question, answer: answer, type: type?.value };
    const responseData = await Patch(editUrl, obj, authHeader);
    if (responseData !== undefined) {
      let oldData = fAQ.slice();
      oldData.splice(selectedItem?.index, 1, responseData?.data?.data);
      setFAQ(oldData);
      toast.success("Successfully Edit");
      cancelHandler(setEditFaqModal);
    }
    setLoading(false);
  };

  const cancelHandler = async (setter) => {
    setter(false);
    setSelectedItem(null);
    setAnswer("");
    setQuestion("");
    setType("");
  };

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequests">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">FAQ</h1>
        <p className="mb-4">
          Lorem Ipsum is simply dummy text of the printing and typesetting
        </p>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex">
            <h6
              className="m-0 font-weight-bold text-primary"
              onClick={() => setAddFaqModal(true)}
              style={{ cursor: "pointer" }}
            >
              Add FAQ
            </h6>
            <div
              className="addBtn"
              onClick={() => setAddFaqModal(true)}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-plus-circle"></i>
            </div>
          </div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#AB2330"
                />{" "}
              </div>
            ) : (
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      {/* <th>Name</th> */}
                      <th>S.NO</th>
                      <th>Questions</th>
                      <th>Answers</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {fAQ.map((item, index) => {
                      return (
                        <>
                          <tr key={item._id}>
                            <td>{index + 1}</td>
                            <td>{item.question}</td>
                            <td>{item.answer}</td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() => {
                                  setSelectedItem({ item, index });
                                  setShowAlertModal(true);
                                }}
                                disabled={loading}
                              >
                                {loading === item._id ? "loading" : " Delete"}
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() => {
                                  setEditFaqModal(true);
                                  setSelectedItem({ item, index });
                                  setQuestion(item.question);
                                  setAnswer(item.answer);
                                  setType({
                                    label: item?.type,
                                    value: item?.type,
                                  });
                                }}
                                disabled={loading}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>

                <div className="pagination">
                  <Pagination
                    maxLastLength={maxLength}
                    array={fAQ}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}

      <AddFaqModal
        addFaqModal={addFaqModal}
        setAddFaqModal={setAddFaqModal}
        question={question}
        setQuestion={setQuestion}
        answer={answer}
        setAnswer={setAnswer}
        type={type}
        setType={setType}
        onPress={() => {
          addFaqHandler();
        }}
        loading={loading}
      />
      <AddFaqModal
        addFaqModal={editFaqModal}
        setAddFaqModal={setEditFaqModal}
        question={question}
        setQuestion={setQuestion}
        answer={answer}
        setAnswer={setAnswer}
        type={type}
        setType={setType}
        onPress={() => {
          EditHandler();
        }}
        loading={loading}
      />
      {/* <EditFaqModal
        editFaqModal={editFaqModal}
        setEditFaqModal={setEditFaqModal}
        editQuestion={editQuestion}
        setEditQuestion={setEditQuestion}
        editAnswer={editAnswer}
        setEditAnswer={setEditAnswer}
        EditHandler={EditHandler}
        item={item}
        setItem={setItem}
        index={index}
        setIndex={setIndex}
        loading={loading}
      /> */}

      {/* <ConfirmModal
        isModalVisible={showAlertModal}
        setIsModalVisible={setShowAlertModal}
        onClickNo={resetStates}
        onClickYes={deleteHandler}
        confirmTextLine={"Are you sure you want to delete this item?"}
        isApiCall={loading}
      /> */}
      <AlertModal
        title={"Alert!"}
        message={"Are you sure you want to delete this item?"}
        image={warningIcon}
        visibleModal={showAlertModal}
        onPress={() => {
          deleteHandler();
        }}
        onPressCancel={() => {
          cancelHandler(setShowAlertModal);
        }}
        itemLoading={loading}
      />
    </>
  );
}

export default Faq;
