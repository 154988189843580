import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Post } from "../Axios/AxiosFunctions";
import { URL } from "../Config/apiUrl";

const ContactRepliedModal = ({
  handleClose,
  show,
  sendData,
  setRenderScreen,
}) => {
  console.log(sendData?.firstName);

  var [name, setName] = useState(sendData.firstName);
  var [id, setId] = useState(sendData._id);
  var [email, setEmail] = useState(sendData.email);
  var [subject, setSubject] = useState("");
  var [message, setMessage] = useState("");
  const accessToken = useSelector((state) => state.globalReducer.accessToken);

  console.log(name);
  const editServiceItemsUrl = URL(`admin/contact-us-reply`);
  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(
      sendData.firstName,
      sendData._id,
      sendData.email,
      subject,
      message
    );
    const data = {
      name: sendData.firstName,
      id: sendData._id,
      email: sendData.email,
      subject: subject,
      message: message,
    };
    console.log(data, accessToken);
    const responseData = await Post(editServiceItemsUrl, data, authHeader);
    console.log(responseData);
    if (responseData !== undefined) {
      handleClose();
      setRenderScreen(Math.random());
    }
  };

  return (
    <>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <label>Subject</label>
            <input
              type="text"
              className="form-control"
              id="section1InputHeading"
              placeholder="Enter Subject"
              onChange={(e) => setSubject(e.target.value)}
            />
            <label>Message</label>
            <textarea
              type="text"
              className="form-control"
              id="section1InputHeading"
              placeholder="Enter Message"
              onChange={(e) => setMessage(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ContactRepliedModal;
