import React, { useState, useEffect, useRef } from "react";
import classes from "./editMechanicProfile.module.css";
import Input from "../../components/Input";
import { FiMail } from "react-icons/fi";
import {
  FaUserAlt,
  FaSnapchat,
  FaFacebook,
  FaInstagram,
  FaGooglePlus,
  FaLinkedinIn,
  FaCar,
  FaRegFilePdf,
} from "react-icons/fa";
import { MdOutlineDescription } from "react-icons/md";
import ProfileWithEditButton from "./../../components/ProfileWithEditButton";
import { UploadPdfIcon } from "../../constant/imagepath";
import { useDispatch, useSelector } from "react-redux";
import { apiHeader, createFormData, URL as BaseURL } from "../../Config/apiUrl";
import { toast } from "react-toastify";
import { Get, Patch, Post } from "../../Axios/AxiosFunctions";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Checkbox } from "../../components/Checkbox";
import { SpinnerRoundOutlined } from "spinners-react";
import { BsTelephoneFill } from "react-icons/bs";

function EditMechanicProfile() {
  const pageId = useParams()?.id;
  const [userData, setUserData] = useState(null);

  const { accessToken, certificatesArray, servicesArray } = useSelector(
    (state) => state.globalReducer
  );

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setcontactNo] = useState("");
  const [description, setDescription] = useState("");
  const [snapchat, setSnapchat] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [google, setGoogle] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [isGetting, setIsGetting] = useState(false);
  const [loading, setLoading] = useState(false);

  const [profileImage, setProfileImage] = useState("");
  const [coverImage, setCoverImage] = useState("");

  // For Certificates
  //   const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [selectedCertificatesObjects, setSelectedCertificatesObjects] =
    useState([]);
  //   const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServicesObjects, setSelectedServicesObjects] = useState([]);

  const handleSelectedCertificates = (type, item, itemType) => {
    let index = -1;
    let newItem = {};
    let data =
      itemType == "certificate"
        ? selectedCertificatesObjects.slice()
        : selectedServicesObjects.slice();
    if (itemType == "certificate") {
      index = selectedCertificatesObjects.findIndex(
        (x) => x.certificateItemId == item?._id
      );
      if (index == -1) {
        newItem = {
          mechanicUserId: userData?.UserId?._id,
          mechanicDetailId: userData?._id,
          certificateTypeId: type?._id,
          certificateItemId: item?._id,
        };
      }
    } else {
      index = selectedCertificatesObjects.findIndex(
        (x) => x.certificateItemId == item?._id
      );
      if (index == -1) {
        newItem = {
          mechanicUserId: userData?.UserId?._id,
          mechanicDetailId: userData?._id,
          serviceTypeId: type?._id,
          serviceItemId: item?._id,
        };
      }
    }
    if (index == -1) {
      data.push(newItem);
    } else {
      data?.splice(index, 1);
    }
    if (itemType == "certificate") {
      setSelectedCertificatesObjects(data);
    } else {
      setSelectedServicesObjects(data);
    }
  };

  async function getMechanicData() {
    const getUrl = BaseURL(`mechanic/mechanic-detail/${pageId}`);
    setIsGetting(true);
    const responseData = await Get(getUrl, accessToken);

    if (responseData !== undefined) {
      let user = responseData.data?.data?.user;
      console.log("response qasim", responseData);
      setUserName(user?.userName);
      setEmail(user?.UserId?.email);
      setDescription(user?.description);
      setcontactNo(user?.contactNo);
      setSnapchat(user?.snapchatLink);
      setFacebook(user?.facebookLink);
      setInstagram(user?.instagramLink);
      setGoogle(user?.googleLink);
      setLinkedIn(user?.linkedinLink);
      setProfileImage(user?.photo);
      setCoverImage(user?.cover_image);
      //   setSelectedCertificates(user?.certificates);
      //   setSelectedServices(user?.services);
      setUserData(user);

      setIsGetting(false);
    } else {
      setIsGetting(false);
    }
  }

  useEffect(() => {
    getMechanicData();
  }, []);

  const createCeritifateAndServiceDataStructure = (
    mainArray,
    mainKey,
    innerTypeKey,
    setObject
    // setString
  ) => {
    let selectedItemObjectArray = [];
    mainArray.map((item, index) => {
      item[mainKey].map((innerItem, innerIndex) => {
        selectedItemObjectArray.push({
          mechanicUserId: userData?.UserId?._id,
          mechanicDetailId: userData?._id,
          ...(innerTypeKey == "Certificate" && {
            certificateTypeId: innerItem[`${innerTypeKey}TypeId`],
            certificateItemId: innerItem?._id,
          }),
          ...(innerTypeKey == "service" && {
            serviceTypeId: innerItem[`${innerTypeKey}TypeId`],
            serviceItemId: innerItem?._id,
          }),
        });
      });
    });
    setObject(selectedItemObjectArray);
  };

  useEffect(() => {
    if (userData !== null) {
      createCeritifateAndServiceDataStructure(
        userData?.certificates,
        "certificateItem",
        "Certificate",
        setSelectedCertificatesObjects
        // setSelectedCertificates
      );
      createCeritifateAndServiceDataStructure(
        userData?.services,
        "serviceItems",
        "service",
        setSelectedServicesObjects
        // setSelectedServices
      );
    }
  }, [userData]);

  // Api

  const submit = async () => {
    let updateProfileURL = BaseURL(`admin/mechanic/update`);

    let params = {
      userId: userData?.UserId?._id,
      email,
      contactNo,
      description,
      userName,
      photo: profileImage,
      //   UserId: userData?.UserId?._id,
      cover_image: coverImage,
      selectedCertificates: JSON.stringify(selectedCertificatesObjects),
      selectedServices: JSON.stringify(selectedServicesObjects),
      //   facebookLink: facebook,
      //   instagramLink: instagram,
      //   snapchatLink: snapchat,
      //   googleLink: google,
      //   linkedinLink: linkedIn,
    };

    for (let key in params) {
      if (
        params[key] === "" ||
        params[key] === false ||
        params[key]?.length === 0 ||
        params[key] == null
      ) {
        return toast.info(`Please Enter Required Details.`);
      }
    }
    params = {
      ...params,
      facebookLink: facebook,
      instagramLink: instagram,
      snapchatLink: snapchat,
      googleLink: google,
      linkedinLink: linkedIn,
      //   selectedCertificates: JSON.stringify(selectedCertificatesObjects),
      //   selectedServices: JSON.stringify(selectedServicesObjects),
    };
    let editProfileData = await createFormData(params);

    setLoading(true);
    const responseData = await Patch(
      updateProfileURL,
      editProfileData,
      apiHeader(accessToken)
    );
    if (responseData !== undefined) {
      console.log("Response UpdateMe: ", responseData?.data?.data);
      toast.success(`Mechanic updated successfully.`);
      setLoading(false);
    } else {
      toast.error(`${responseData?.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      {isGetting ? (
        <div className="col-md-12 darken-newMechanic on-loading">
          <SpinnerRoundOutlined
            enabled={isGetting}
            thickness={200}
            color="#AB2330"
          />
        </div>
      ) : (
        <section className={`${classes.InputSection_div}`}>
          <Container className={classes.container}>
            {/* Input Section */}
            <div className={`${[classes.userImagesContainer].join(" ")}`}>
              <ProfileWithEditButton
                updateImage={coverImage}
                setUpdateImage={setCoverImage}
                onErrorImage={true}
                classImg={classes.coverImageTagClass}
                className={classes.coverImage}
                classBtn={classes.coverEditBtn}
              />

              <div className={`${classes.profile_div}`}>
                <ProfileWithEditButton
                  updateImage={profileImage}
                  setUpdateImage={setProfileImage}
                />
              </div>
            </div>
            <p className={`${classes.greyText} ${classes.profile_text}`}>
              Change Profile Picture
            </p>

            <div className={`${classes.inp_div}`}>
              <Input
                type={"text"}
                placeholder={"User Name"}
                setter={setUserName}
                value={userName}
                leftIcon={<FaUserAlt size={20} color={"#E60000"} />}
              />
            </div>
            <div className={`${classes.inp_div}`}>
              <Input
                type={"email"}
                placeholder={"Email"}
                setter={setEmail}
                value={email}
                leftIcon={<FiMail size={20} color={"#E60000"} />}
                disabled={true}
              />
            </div>
            <div className={`${classes.inp_div}`}>
              <Input
                type={"number"}
                placeholder={"Contact"}
                setter={setcontactNo}
                value={contactNo}
                leftIcon={<BsTelephoneFill size={20} color={"#E60000"} />}
              />
            </div>
            <div className={`${classes.inp_div}`}>
              <Input
                type={"text"}
                placeholder={"Description"}
                setter={setDescription}
                value={description}
                leftIcon={<MdOutlineDescription size={20} color={"#E60000"} />}
              />
            </div>

            {/* Social Icon */}
            <div className={`${classes.inp_div}`}>
              <Input
                type={"text"}
                placeholder={"Snapchat"}
                setter={setSnapchat}
                value={snapchat}
                leftIcon={<FaSnapchat size={20} color={"#E60000"} />}
              />
            </div>
            <div className={`${classes.inp_div}`}>
              <Input
                type={"text"}
                placeholder={"FaceBook"}
                setter={setFacebook}
                value={facebook}
                leftIcon={<FaFacebook size={20} color={"#E60000"} />}
              />
            </div>
            <div className={`${classes.inp_div}`}>
              <Input
                type={"text"}
                placeholder={"Instagram"}
                setter={setInstagram}
                value={instagram}
                leftIcon={<FaInstagram size={20} color={"#E60000"} />}
              />
            </div>
            <div className={`${classes.inp_div}`}>
              <Input
                type={"text"}
                placeholder={"Google"}
                setter={setGoogle}
                value={google}
                leftIcon={<FaGooglePlus size={20} color={"#E60000"} />}
              />
            </div>
            <div className={`${classes.inp_div}`}>
              <Input
                type={"text"}
                placeholder={"LinkedIn"}
                setter={setLinkedIn}
                value={linkedIn}
                leftIcon={<FaLinkedinIn size={20} color={"#E60000"} />}
              />
            </div>
            {/* End of social Icon */}

            <Row className={classes.inp_div}>
              <Col md={12}>
                <h3>CERTIFICATIONS</h3>
                <p>Please check boxes below that apply to your business.</p>
              </Col>
            </Row>
            <Row className={classes.inp_div}>
              {certificatesArray?.map((item, i) => {
                return (
                  <Col md={6} className={[classes.certificateCol]} key={i}>
                    <h3>{item?.CertificateType}</h3>
                    {item?.certificateItem?.map((certificate, i) => {
                      const isCheckItem = selectedCertificatesObjects.findIndex(
                        (x) =>
                          x?.certificateTypeId == item?._id &&
                          x?.certificateItemId == certificate?._id
                      );
                      return (
                        <Checkbox
                          value={selectedCertificatesObjects}
                          isCheckedItem={isCheckItem}
                          data={{
                            mechanicUserId: userData?.UserId?._id,
                            mechanicDetailId: userData?._id,
                            certificateTypeId: item?._id,
                            certificateItemId: certificate?._id,
                          }}
                          label={certificate?.CertificateName}
                          disabled={false}
                          setValue={(e) => {
                            setSelectedCertificatesObjects(e);
                          }}

                          //   value={selectedCertificates}
                          //   label={certificate?.CertificateName}
                          //   disabled={false}
                          //   setValue={(e) => {
                          //     setSelectedCertificates(e);
                          //     handleSelectedCertificates(
                          //       item,
                          //       certificate,
                          //       "certificate"
                          //     );
                          //   }}
                        />
                      );
                    })}
                  </Col>
                );
              })}
            </Row>

            <Row className={classes.inp_div}>
              <Col md={12}>
                <h3>SERVICES</h3>
                <p>Please check boxes below that apply to your business.</p>
              </Col>
            </Row>
            <Row className={classes.inp_div}>
              {servicesArray?.map((item, i) => {
                return (
                  <Col md={6} className={[classes.certificateCol]} key={i}>
                    <h3>{item?.serviceType}</h3>
                    {item?.serviceItem?.map((service, i) => {
                      const isCheckItem = selectedServicesObjects.findIndex(
                        (x) =>
                          x?.serviceTypeId == item?._id &&
                          x?.serviceItemId == service?._id
                      );
                      return (
                        <Checkbox
                          //   label={service?.serviceName}
                          //   value={selectedServices}
                          //   disabled={false}
                          //   setValue={(e) => {
                          //     setSelectedServices(e);
                          //     handleSelectedCertificates(
                          //       item,
                          //       service,
                          //       "service"
                          //     );
                          //   }}
                          value={selectedServicesObjects}
                          isCheckedItem={isCheckItem}
                          data={{
                            mechanicUserId: userData?.UserId?._id,
                            mechanicDetailId: userData?._id,
                            serviceTypeId: item?._id,
                            serviceItemId: service?._id,
                          }}
                          label={service?.serviceName}
                          disabled={false}
                          setValue={(e) => {
                            setSelectedServicesObjects(e);
                          }}
                        />
                      );
                    })}
                  </Col>
                );
              })}
            </Row>

            <div className={classes.btnBox}>
              <Button
                disabled={loading}
                className={classes.savebtn}
                onClick={submit}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </div>

            {/* Input Section */}
          </Container>
        </section>
      )}
    </div>
  );
}

export default EditMechanicProfile;
