import React, { useState } from "react";
import { FiMail } from "react-icons/fi";
import {
  FaUserAlt,
  FaSnapchat,
  FaFacebook,
  FaInstagram,
  FaGooglePlus,
  FaLinkedinIn,
} from "react-icons/fa";
import { MdOutlineDescription } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Container, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

import classes from "./CreateUnclaimBusiness.module.css";
import Input from "../../components/Input/index";
import ProfileWithEditButton from "../../components/ProfileWithEditButton";

import { apiHeader, createFormData, URL } from "../../Config/apiUrl";
import { Post } from "../../Axios/AxiosFunctions";
import { Checkbox } from "../../components/Checkbox";
import TextArea from "../../components/TextArea";
import { useHistory } from "react-router-dom";
import ReasonModal from "../../components/ReasonModal";
import { BsTelephoneFill } from "react-icons/bs";

function CreateUnclaimBusiness() {
  let history = useHistory();
  const { accessToken, certificatesArray, servicesArray } = useSelector(
    (state) => state.globalReducer
  );

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [snapchat, setSnapchat] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [google, setGoogle] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [loading, setLoading] = useState(false);
  const [contactNo, setContactNo] = useState("");

  const [profileImage, setProfileImage] = useState("");
  const [coverImage, setCoverImage] = useState("");

  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [selectedCertificatesObjects, setSelectedCertificatesObjects] =
    useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServicesObjects, setSelectedServicesObjects] = useState([]);

  const createUnclaimBusiness = URL("admin/createMechanic");
  // Api

  const submit = async () => {
    let params = {
      email,
      description,
      userName,
    };
    for (let key in params) {
      if (params[key] == "" || params[key] == null) {
        return toast.error(`Please enter ${key} field.`);
      }
    }

    params = {
      ...params,
      contactNo,
      photo: profileImage,
      cover_image: coverImage,
      facebookLink: facebook,
      instagramLink: instagram,
      snapchatLink: snapchat,
      googleLink: google,
      linkedinLink: linkedIn,
      selectedCertificates: JSON.stringify(selectedCertificatesObjects),
      selectedServices: JSON.stringify(selectedServicesObjects),
    };

    let formData = await createFormData(params);

    // selectedCertificatesObjects.forEach((item) => {
    //   formData.append("selectedCertificates", item);
    // });
    // selectedServicesObjects.forEach((item) => {
    //   formData.append("selectedServices", item);
    // });
    setLoading(true);
    let responseData = await Post(
      createUnclaimBusiness,
      formData,
      apiHeader(accessToken, true)
    );
    setLoading(false);
    if (responseData !== undefined) {
      toast.success(`Unclaim Business Created Successfully.`);
      history.goBack();
    }
  };

  const handleSelectedCertificates = (type, item, itemType) => {
    let index = -1;
    let newItem = {};
    let data =
      itemType == "certificate"
        ? selectedCertificatesObjects.slice()
        : selectedServicesObjects.slice();
    if (itemType == "certificate") {
      index = selectedCertificatesObjects.findIndex(
        (x) => x.certificateItemId == item?._id
      );
      if (index == -1) {
        newItem = {
          mechanicUserId: null,
          mechanicDetailId: null,
          certificateTypeId: type?._id,
          certificateItemId: item?._id,
        };
      }
    } else {
      index = selectedCertificatesObjects.findIndex(
        (x) => x.certificateItemId == item?._id
      );
      if (index == -1) {
        newItem = {
          mechanicUserId: null,
          mechanicDetailId: null,
          serviceTypeId: type?._id,
          serviceItemId: item?._id,
        };
      }
    }
    if (index == -1) {
      data.push(newItem);
    } else {
      data?.splice(index, 1);
    }
    if (itemType == "certificate") {
      setSelectedCertificatesObjects(data);
    } else {
      setSelectedServicesObjects(data);
    }
  };

  return (
    <section className={`${classes.InputSection_div}`}>
      <style>
        {`
        .btn-primary:hover {
    color: #fff;
  background-color: var(--main-color-red);
    border-color: var(--main-color-red);
}
        .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
  background-color: var(--main-color-red);
    border-color: var(--main-color-red);
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #e600006e;
}
        `}
      </style>
      <Container className={classes.container}>
        {/* Input Section */}
        <div className={`${[classes.userImagesContainer].join(" ")}`}>
          <ProfileWithEditButton
            updateImage={coverImage}
            setUpdateImage={setCoverImage}
            onErrorImage={true}
            classImg={classes.coverImageTagClass}
            className={classes.coverImage}
            classBtn={classes.coverEditBtn}
          />
          <div className={`${classes.profile_div}`}>
            <ProfileWithEditButton
              updateImage={profileImage}
              setUpdateImage={setProfileImage}
            />
          </div>
        </div>
        <p className={`${classes.greyText} ${classes.profile_text}`}>
          Change Profile Picture
        </p>

        <Row>
          <Col md={6} className={`${classes.inp_div}`}>
            <Input
              type={"text"}
              placeholder={"Business Name*"}
              setter={setUserName}
              value={userName}
              leftIcon={<FaUserAlt size={20} color={"#E60000"} />}
            />
          </Col>
          <Col md={6} className={`${classes.inp_div}`}>
            <Input
              type={"email"}
              placeholder={"Email*"}
              setter={setEmail}
              value={email}
              leftIcon={<FiMail size={20} color={"#E60000"} />}
            />
          </Col>
          <Col md={6} className={`${classes.inp_div}`}>
            <Input
              placeholder={"Contact No"}
              value={contactNo}
              setter={setContactNo}
              type={"number"}
              leftIcon={<BsTelephoneFill size={18} color={"#E60000"} />}
            />
          </Col>
          <Col md={6} className={`${classes.inp_div}`}>
            <Input
              type={"text"}
              placeholder={"Snapchat"}
              setter={setSnapchat}
              value={snapchat}
              leftIcon={<FaSnapchat size={20} color={"#E60000"} />}
            />
          </Col>
          <Col md={6} className={`${classes.inp_div}`}>
            <Input
              type={"text"}
              placeholder={"FaceBook"}
              setter={setFacebook}
              value={facebook}
              leftIcon={<FaFacebook size={20} color={"#E60000"} />}
            />
          </Col>
          <Col md={6} className={`${classes.inp_div}`}>
            <Input
              type={"text"}
              placeholder={"Instagram"}
              setter={setInstagram}
              value={instagram}
              leftIcon={<FaInstagram size={20} color={"#E60000"} />}
            />
          </Col>
          <Col md={6} className={`${classes.inp_div}`}>
            <Input
              type={"text"}
              placeholder={"Google"}
              setter={setGoogle}
              value={google}
              leftIcon={<FaGooglePlus size={20} color={"#E60000"} />}
            />
          </Col>
          <Col md={6} className={`${classes.inp_div}`}>
            <Input
              type={"text"}
              placeholder={"LinkedIn"}
              setter={setLinkedIn}
              value={linkedIn}
              leftIcon={<FaLinkedinIn size={20} color={"#E60000"} />}
            />
          </Col>

          <Col md={12} className={`${classes.inp_div}`}>
            <TextArea
              placeholder={"Description*"}
              value={description}
              setter={setDescription}
              type={"text"}
              rows={6}
              leftIcon={<MdOutlineDescription size={20} color={"#E60000"} />}
            />
          </Col>
        </Row>

        <Row className={classes.inp_div}>
          <Col md={12}>
            <h3>CERTIFICATIONS</h3>
            <p>Please check boxes below that apply to your business.</p>
          </Col>
        </Row>
        <Row className={classes.inp_div}>
          {certificatesArray?.map((item, i) => {
            return (
              <Col md={6} className={[classes.certificateCol]} key={i}>
                <h3>{item?.CertificateType}</h3>
                {item?.certificateItem?.map((certificate, i) => {
                  const isCheckItem = selectedCertificatesObjects.findIndex(
                    (x) =>
                      x?.certificateTypeId == item?._id &&
                      x?.certificateItemId == certificate?._id
                  );
                  return (
                    <Checkbox
                      value={selectedCertificatesObjects}
                      label={certificate?.CertificateName}
                      disabled={false}
                      isCheckedItem={isCheckItem}
                      data={{
                        mechanicUserId: null,
                        mechanicDetailId: null,
                        certificateTypeId: item?._id,
                        certificateItemId: certificate?._id,
                      }}
                      setValue={(e) => {
                        // setSelectedCertificates(e);
                        // handleSelectedCertificates(
                        //   item,
                        //   certificate,
                        //   "certificate"
                        // );
                        setSelectedCertificatesObjects(e);
                      }}
                    />
                  );
                })}
              </Col>
            );
          })}
        </Row>

        <Row className={classes.inp_div}>
          <Col md={12}>
            <h3>SERVICES</h3>
            <p>Please check boxes below that apply to your business.</p>
          </Col>
        </Row>
        <Row className={classes.inp_div}>
          {servicesArray?.map((item, i) => {
            return (
              <Col md={6} className={[classes.certificateCol]} key={i}>
                <h3>{item?.serviceType}</h3>
                {item?.serviceItem?.map((service, i) => {
                  const isCheckItem = selectedServicesObjects.findIndex(
                    (x) =>
                      x?.serviceTypeId == item?._id &&
                      x?.serviceItemId == service?._id
                  );
                  return (
                    <Checkbox
                      label={service?.serviceName}
                      value={selectedServicesObjects}
                      isCheckedItem={isCheckItem}
                      data={{
                        mechanicUserId: null,
                        mechanicDetailId: null,
                        serviceTypeId: item?._id,
                        serviceItemId: service?._id,
                      }}
                      disabled={false}
                      setValue={(e) => {
                        // setSelectedServices(e);
                        // handleSelectedCertificates(item, service, "service");
                        setSelectedServicesObjects(e);
                      }}
                    />
                  );
                })}
              </Col>
            );
          })}
          <Col md={12} className={`${classes.savebtnContainer}`}>
            <Button
              className={`${classes.savebtn}`}
              onClick={submit}
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default CreateUnclaimBusiness;
