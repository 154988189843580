import {
  NEW_MENTOR_REQUEST,
  MENTOR_CHANGES,
  NEW_PAGE_DETAILS,
  APPROVED_MECHANIC,
} from "./actionTypes";
import axios from "axios";

export const getNewPageDetails = (data) => {
  return (dispatch) => {
    dispatch({ type: NEW_PAGE_DETAILS, data: data });
  };
};

export const getNewMentors = (data) => {
  return (dispatch) => {
    dispatch({ type: NEW_MENTOR_REQUEST, data: data });
  };
};

export const getMentorChanges = (data) => {
  return (dispatch) => {
    dispatch({ type: MENTOR_CHANGES, data: data });
  };
};

export const approvedMechanic = (data) => {
  return (dispatch) => {
    dispatch({ type: APPROVED_MECHANIC, data: data });
  };
};
