import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl, URL } from "../Config/apiUrl";
import Pagination from "../components/Pagination";
import { approvedMechanic } from "../store/actions/mentorActions";
import { useDispatch, useSelector } from "react-redux";
import { Get, Patch } from "../Axios/AxiosFunctions";
import { useHistory } from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { notFound } from "../constant/imagepath";

import logo from "../logo.svg";
import Input from "../components/Input";

function ApprovedMechanic(props) {
  // const newMentors = useSelector((state) => state.mentorReducer.unApprovedMentors);
  const [unApprovedMechanic, setUnApprovedMechanic] = useState([]);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [status, setStatus] = useState(1);
  const [actionToggle, setActionToggle] = useState("Approved");
  const [isPremium, setIsPremium] = useState(false);
  const [isMMHVerified, setIsMMHVerified] = useState(false);
  const [isPrivatePDFLength, setIsPrivatePDFLength] = useState(false);
  const [sheetLoading, setSheetLoading] = useState(false);

  const [search, setSearch] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const getMechanicSheetApiURL = URL("mechanic-details/file");

  const Header = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  console.log(accessToken);
  const dispatch = useDispatch();
  let history = useHistory();

  const getMechanicList = async (
    pgNumber,
    status,
    premium,
    mmhVerified,
    privatePdfLength
  ) => {
    const getMechanicApiURL = URL(
      `approved-or-deactivated-mechanics-list?status=${status}&premium=${premium}&mmhVerified=${mmhVerified}&page=${pgNumber}&limit=${limit}&privatePdfLength=${privatePdfLength}&search=${search}`
    );
    setIsFetchingData(true);
    const responseData = await Get(getMechanicApiURL, accessToken);
    if (responseData !== undefined) {
      setUnApprovedMechanic(responseData.data.data);
      // if (pgNumber == 1) {
      //   setUnApprovedMechanic(responseData.data.data);
      // } else {
      //   let newArray = unApprovedMechanic.concat(responseData.data.data);
      //   setUnApprovedMechanic(newArray);
      // }
    } else {
      setUnApprovedMechanic([]);
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    getMechanicList(1, status, isPremium, isMMHVerified, isPrivatePDFLength);
  }, [status]);

  useEffect(() => {
    getMechanicList(
      pageNumber,
      status,
      isPremium,
      isMMHVerified,
      isPrivatePDFLength
    );
  }, [pageNumber]);

  const handlePremiumToggle = () => {
    getMechanicList(1, status, !isPremium, isMMHVerified, isPrivatePDFLength);
    setIsPremium(!isPremium);
    setPageNumber(1);
  };

  const handleMMHVerifiedToggle = () => {
    getMechanicList(1, status, isPremium, !isMMHVerified, isPrivatePDFLength);
    setIsMMHVerified(!isMMHVerified);
    setPageNumber(1);
  };
  const handlePrivatePdfLengthToggle = () => {
    getMechanicList(1, status, isPremium, isMMHVerified, !isPrivatePDFLength);
    setIsPrivatePDFLength(!isPrivatePDFLength);
    setPageNumber(1);
  };
  const handleDownloadSheet = async () => {
    setSheetLoading(true);
    const responseData = await Get(getMechanicSheetApiURL, accessToken);
    if (responseData !== undefined) {
      const link = document.createElement("a");
      link.href = `${apiUrl}${responseData.data.path}`;
      link.setAttribute("download", `mechanic.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
    setSheetLoading(false);
  };

  const GetSubscriptionPlans = async () => {
    const subscriptionPlansApiURL = URL(`get-plans`);
    setIsLoading(true);
    const responseData = await Get(subscriptionPlansApiURL, accessToken, false);
    if (responseData !== undefined) {
      setSubscriptionPlans(responseData.data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    GetSubscriptionPlans();
  }, []);

  return (
    <>
      {/* <!-- DataTales Example --> */}
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <div className="my-4" style={{ position: "relative" }}>
            <Input
              placeholder={"Search Here..."}
              value={search}
              setter={setSearch}
              onkeyPress={() =>
                getMechanicList(
                  1,
                  status,
                  isPremium,
                  isMMHVerified,
                  isPrivatePDFLength
                )
              }
            />
            <Button
              onClick={() =>
                getMechanicList(
                  1,
                  status,
                  isPremium,
                  isMMHVerified,
                  isPrivatePDFLength
                )
              }
              style={{
                position: "absolute",
                right: "1px",
                top: "1px",
                height: "50px",
                width: "100px",
              }}
            >
              Search
            </Button>
          </div>
          <div className={"searchContainer"}>
            <div>Google Places Search</div>
            <div className={"filterBtnContainer"}>
              <span>icon</span> <span>Filter</span>
            </div>
          </div>
          <div
            className="MechanicScreenHeader"
            style={{ display: "flex", alignItems: "center" }}
          >
            <h6 className="m-0 font-weight-bold text-primary">Mechanic</h6>
            <div style={{ marginLeft: "26px" }}>
              <DropdownButton
                id="dropdown-basic-button"
                title={`${
                  actionToggle == undefined
                    ? "select Mechanic Type"
                    : actionToggle
                }`}
                onSelect={(e) => {
                  setStatus(e == "Approved" ? 1 : 0);
                  setActionToggle(e);
                  setPageNumber(1);
                  console.log(actionToggle);
                }}
              >
                <Dropdown.Item eventKey="Approved">Approved</Dropdown.Item>
                <Dropdown.Item eventKey="Deactivated">
                  Deactivated
                </Dropdown.Item>
              </DropdownButton>
            </div>
            {actionToggle == "Approved" && (
              <>
                <Button
                  style={{
                    border: `${
                      isPremium === true
                        ? "1px solid #cf2b3b"
                        : "1px solid black"
                    }`,
                    backgroundColor: `${
                      isPremium === true ? "#cf2b3b" : "#fff"
                    }`,
                    color: `${isPremium === true ? "white" : "black"}`,
                  }}
                  onClick={() => {
                    handlePremiumToggle();
                  }}
                >
                  Premium
                </Button>
                <Button
                  style={{
                    border: `${
                      isMMHVerified === true
                        ? "1px solid #cf2b3b"
                        : "1px solid black"
                    }`,
                    backgroundColor: `${
                      isMMHVerified === true ? "#cf2b3b" : "#fff"
                    }`,
                    color: `${isMMHVerified === true ? "white" : "black"}`,
                  }}
                  onClick={() => {
                    handleMMHVerifiedToggle();
                  }}
                >
                  MMH Verified
                </Button>
                <Button
                  style={{
                    border: `${
                      isPrivatePDFLength === true
                        ? "1px solid #cf2b3b"
                        : "1px solid black"
                    }`,
                    backgroundColor: `${
                      isPrivatePDFLength === true ? "#cf2b3b" : "#fff"
                    }`,
                    color: `${isPrivatePDFLength === true ? "white" : "black"}`,
                  }}
                  onClick={() => {
                    handlePrivatePdfLengthToggle();
                  }}
                >
                  Private PDF
                </Button>
              </>
            )}
            <Button
              onClick={() => {
                sheetLoading == false && handleDownloadSheet();
              }}
              disabled={sheetLoading}
              style={{
                border: "1px solid black",
                backgroundColor: "#fff",
                color: "black",
              }}
            >
              Download Sheet
            </Button>
          </div>
        </div>
        <div className="card-body">
          {isFetchingData || isLoading == true ? (
            <div className="col-md-12 darken-newMechanic on-loading">
              <SpinnerRoundOutlined
                enabled={isFetchingData}
                thickness={200}
                color="#AB2330"
              />{" "}
            </div>
          ) : (
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    {/* <td>Photo</td> */}
                    <th>Name</th>
                    <th>Email</th>
                    <th>Package</th>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading == false &&
                    unApprovedMechanic.map((item, index) => {
                      const filterIndex = subscriptionPlans.findIndex(
                        (x) => (x) => x?.id == item?.plan
                      );
                      return (
                        <>
                          <tr key={item._id}>
                            <td>{index + 1}</td>
                            {/* <td className="table-image">
                              <img 
                                src={`${nodeImageUrl}${item.photo}`} alt="" className="image-fluid" />
                            </td> */}
                            <td>{item?.userName}</td>
                            <td>{item?.UserId?.email}</td>
                            <td>
                              {filterIndex !== -1
                                ? subscriptionPlans[filterIndex]?.product?.name
                                : "Null"}
                            </td>
                            <td>{item?.UserId?.role}</td>
                            <td>
                              <Button
                                className={`btn btn-user btn-block ${
                                  item.active ? "btn-approved" : "btn-pending"
                                }`}
                                onClick={() => {
                                  history.push(
                                    `/ApprovedMechanicDetails/${item?._id}`
                                  );
                                  dispatch(approvedMechanic(item));
                                }}
                              >
                                View Detail
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              {unApprovedMechanic.length == 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={notFound}
                    alt=""
                    className="image-fluid"
                    width={"20%"}
                  />
                  <h3>No Mechanic Found</h3>
                </div>
              )}
              {unApprovedMechanic.length !== 0 && (
                <div className="pagination">
                  <Pagination
                    maxLastLength={limit}
                    array={unApprovedMechanic}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ApprovedMechanic;
