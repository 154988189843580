import React, { useState, useEffect } from "react";
import { apiUrl, pdfUrl } from "../Config/apiUrl";
import { pdfBg } from "../constant/imagepath";

const PDFView = ({ item }) => {
  return (
    <a
      download
      target="_blank"
      href={`${pdfUrl}/${item}`}
      className="me-3 mb-3"
    >
      <div className="certificateCard">
        <img src={pdfBg} alt="cover" className="image-fluid" />
        <span>View PDF</span>
      </div>
    </a>
  );
};

export default PDFView;
