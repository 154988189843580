import React from "react";
import { Modal } from "reactstrap";
import { Button } from "react-bootstrap";

import { useSelector } from "react-redux";

const EditAddServiceTypesModal = ({
  setEditAddServiceTypesModal,
  editAddServiceTypesModal,
  value,
  setValue,
  EditHandler,
  index,
  item,
  loading,
}) => {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);

  return (
    <>
      <Modal
        className="modal-dialog-centered custom-addLang-modal"
        isOpen={editAddServiceTypesModal}
      >
        {/* <ModalBody> */}
        <div className="modal-Header">
          <h2 className="text-center">Add Service Types</h2>
        </div>
        <div className="addlang-modalBody">
          <h2 className="text-center">Add Service Types </h2>
          <div className="innerContainer">
            <div className="form-group">
              <input
                type="text"
                placeholder="Add Service Types"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer footer-div">
            <div>
              <Button
                className="btn btn-user btn-block btn-cancel"
                onClick={() => setEditAddServiceTypesModal(false)}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>

            <div>
              <Button
                className="btn btn-user btn-block btn-save"
                onClick={() => EditHandler(item, index)}
                disabled={loading}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </>
  );
};

export default EditAddServiceTypesModal;
