import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { Post, Patch } from "../Axios/AxiosFunctions";

const EditProductsModal = ({
  editProductsModal,
  editDescriptionText,
  setEditDescriptionText,
  titleText,
  loading,
  onPress,
  onPressCancel,
}) => {
  return (
    <>
      <Modal
        className="modal-dialog-centered custom-addLang-modal"
        isOpen={editProductsModal}
        toggle={loading == false && onPressCancel}
      >
        {/* <ModalBody> */}
        <div className="modal-Header">
          <h2 className="text-center">Edit Products</h2>
        </div>
        <div className="addlang-modalBody">
          <h2 className="text-center">Product Details</h2>
          <div className="innerContainer">
            <div className="form-group">
              <p className="editProductTitle">{titleText}</p>
              <div className="Plans-textArea">
                <textarea
                  type="text"
                  placeholder="Description"
                  value={editDescriptionText}
                  onChange={(e) => setEditDescriptionText(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer footer-div">
            <div>
              <Button
                className="btn btn-user btn-block btn-cancel"
                onClick={loading == false && onPressCancel}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>

            <div>
              <Button
                className="btn btn-user btn-block btn-save"
                onClick={onPress}
                disabled={loading}
              >
                {loading ? "Saving..." : " Save"}
              </Button>
            </div>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </>
  );
};

export default EditProductsModal;
