import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl, URL } from "../../Config/apiUrl";
import Pagination from "../../components/Pagination";
import { approvedMechanic } from "../../store/actions/mentorActions";
import { useDispatch, useSelector } from "react-redux";
import { Get, Patch, Post } from "../../Axios/AxiosFunctions";
import { useHistory } from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { notFound } from "../../constant/imagepath";
import classes from "./AllMechanic.module.css";

import logo from "../../logo.svg";
import Input from "../../components/Input";
import { FaPlus } from "react-icons/fa";
import { BsFileEarmarkArrowDown, BsFilter } from "react-icons/bs";
import { BiSearchAlt } from "react-icons/bi";
import MechanicFilterModal from "../../components/MechanicFilterModal";
import { AiOutlineCloudDownload } from "react-icons/ai";
import DropDown from "../../components/DropDown";

const CustomButton = ({
  title = "Submit",
  icon = <BiSearchAlt size={20} color={"white"} />,
  className,
  onClick,
}) => {
  return (
    <div
      className={[classes?.btnContainer, className].join(" ")}
      onClick={onClick}
    >
      {icon}
      <span>{title}</span>
    </div>
  );
};

function AllMechanic(props) {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);

  const [responseData, setResponseData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);

  const [limit, setLimit] = useState({ label: 10, value: 10 });

  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState({
    certificates: [],
    services: [],
    status: "all",
    sort: "A-Z",
    mmhVerified: "",
    membershipedUser: "",
    state: "",
  });

  const [sheetLoading, setSheetLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const [showFilterModal, setShowFilterModal] = useState(false);

  const getMechanicSheetApiURL = URL("mechanic-details/file");

  const Header = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const dispatch = useDispatch();
  let history = useHistory();

  const handleDownloadSheet = async () => {
    setSheetLoading(true);
    const responseData = await Get(getMechanicSheetApiURL, accessToken);
    if (responseData !== undefined) {
      const link = document.createElement("a");
      link.href = `${apiUrl}${responseData.data.path}`;
      link.setAttribute("download", `mechanic.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
    setSheetLoading(false);
  };

  const GetSubscriptionPlans = async () => {
    const subscriptionPlansApiURL = URL(`get-plans`);
    setIsLoading(true);
    const responseData = await Get(subscriptionPlansApiURL, accessToken, false);
    if (responseData !== undefined) {
      setSubscriptionPlans(responseData.data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    GetSubscriptionPlans();
  }, []);

  const fetchData = async (pageNo, filterParam, limitNo = limit) => {
    const url = URL(`user-filters?page=${pageNo}&limit=${limitNo?.value}`);
    const params = {
      search,
      ...filterParam,
    };
    setLoading(true);
    const response = await Post(url, params, Header);
    if (response !== undefined) {
      setResponseData(response.data.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(pageNumber, filterData);
  }, [pageNumber]);

  const handleSetFilterData = (data) => {
    setPageNumber(1);
    setFilterData(data);
    fetchData(1, data);
  };

  return (
    <>
      {/* <!-- DataTales Example --> */}
      <div className="card shadow mb-4">
        <div className={classes?.searchContainer}>
          <div className={classes?.inputContainer}>
            <Input
              placeholder={"Search Here..."}
              value={search}
              setter={setSearch}
              onkeyPress={() => {
                loading == false && fetchData(1, filterData);
              }}
              inputContainer={classes?.searchInputContainer}
              customStyle={{
                borderRadius: 10,
              }}
              inputStyle={{
                paddingBlock: 15,
              }}
            />
            <CustomButton
              className={classes?.searchBtnContainer}
              title={"Search"}
              icon={<BiSearchAlt size={20} color={"white"} />}
              onClick={() => {
                loading == false && fetchData(1, filterData);
              }}
            />
          </div>
          <CustomButton
            className={classes?.filterBtnContainer}
            title={"Filter"}
            icon={<BsFilter size={20} color={"white"} />}
            onClick={() => {
              setShowFilterModal(!showFilterModal);
            }}
          />
          <div className={[classes.limitDropDownDiv]}>
            <DropDown
              data={[
                { label: 10, value: 10 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
                { label: 200, value: 200 },
              ]}
              value={limit}
              setValue={(e) => {
                setPageNumber(1);
                setLimit(e);
                fetchData(1, filterData, e);
              }}
              placeholder="sort"
            />
          </div>

          <CustomButton
            className={classes?.downloadBtnContainer}
            title={"Download Sheet"}
            icon={<BsFileEarmarkArrowDown size={20} color={"black"} />}
            onClick={() => {
              sheetLoading == false && handleDownloadSheet();
            }}
          />
          {/* <div className={classes?.sortByContainer}>
            <span>Sort:</span>
            <DropdownButton
              id="dropdown-basic-button"
              title={`${
                actionToggle == undefined
                  ? "select Mechanic Type"
                  : actionToggle
              }`}
              onSelect={(e) => {
                setStatus(e == "Approved" ? 1 : 0);
                setActionToggle(e);
                setPageNumber(1);
                console.log(actionToggle);
              }}
            >
              <Dropdown.Item eventKey="A-Z">A-Z By Name</Dropdown.Item>
              <Dropdown.Item eventKey="Z-A">Z-A By Name</Dropdown.Item>
              <Dropdown.Item eventKey="Highest Rating">
                Highest Rating
              </Dropdown.Item>
              <Dropdown.Item eventKey="Lowest Rating">
                Lowest Rating
              </Dropdown.Item>
              <Dropdown.Item eventKey="Most Booking">
                Most Booking
              </Dropdown.Item>
              <Dropdown.Item eventKey="Most Contact">
                Most Contact
              </Dropdown.Item>
            </DropdownButton>
          </div> */}
        </div>
        <div className="card-body">
          {loading || isLoading == true ? (
            <div className="col-md-12 darken-newMechanic on-loading">
              <SpinnerRoundOutlined
                enabled={loading}
                thickness={200}
                color="#AB2330"
              />{" "}
            </div>
          ) : (
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Business Name</th>
                    <th>Email</th>
                    <th>Package</th>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading == false &&
                    responseData?.map((item, index) => {
                      const filterIndex = subscriptionPlans?.findIndex(
                        (x) => x?.id === item?.plan
                      );
                      return (
                        <>
                          <tr key={item._id}>
                            <td>{index + 1 + 10 * (pageNumber - 1)}</td>
                            <td>{item?.userName}</td>
                            <td>{item?.UserId?.email}</td>
                            <td>
                              {filterIndex !== -1
                                ? subscriptionPlans[filterIndex]?.product?.name
                                : "Free"}
                            </td>
                            <td>{item?.UserId?.role}</td>
                            <td>
                              <Button
                                className={`btn btn-user btn-block ${
                                  item.active ? "btn-approved" : "btn-pending"
                                }`}
                                onClick={() => {
                                  history.push(
                                    `/ApprovedMechanicDetails/${item?._id}`
                                  );
                                  dispatch(approvedMechanic(item));
                                }}
                              >
                                View Detail
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              {responseData?.length == 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={notFound}
                    alt=""
                    className="image-fluid"
                    width={"20%"}
                  />
                  <h3>No Mechanic Found</h3>
                </div>
              )}
              {responseData?.length !== 0 && (
                <div className="pagination">
                  <Pagination
                    maxLastLength={limit?.value}
                    array={responseData}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <MechanicFilterModal
        filterData={filterData}
        setFilterData={handleSetFilterData}
        showModal={showFilterModal}
        setShowModal={setShowFilterModal}
      />
    </>
  );
}

export default AllMechanic;
