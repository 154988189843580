import React from "react";
import classes from "./Checkbox.module.css";

export const Checkbox = ({
  value,
  setValue,
  label,
  disabled,
  labelStyle,
  isCheckedItem,
  data,
  isMulti = true,
}) => {
  // const isChecked = value?.findIndex((findValue) => findValue == label);
  // //  / console.log(value == label,{isChecked})

  // const HandleClick = () => {
  //   let newArray = [];
  //   console.log("onClickUk");
  //   if (isChecked !== -1) {
  //     newArray.splice(isChecked, 1);
  //   } else {
  //     newArray.push(label);
  //   }
  //   setValue(newArray);
  // };

  const isChecked = isMulti
    ? isCheckedItem !== undefined
      ? isCheckedItem
      : value?.findIndex((findValue) => findValue == label)
    : value == ""
    ? -1
    : true;

  // const isChecked = isMulti
  // ? value?.findIndex((findValue) => findValue == label)
  // : value == ""
  // ? -1
  // : true;

  const HandleClick = () => {
    let newArray = isMulti ? value?.slice() : value;
    if (isMulti) {
      if (isChecked !== -1) {
        newArray.splice(isChecked, 1);
      } else {
        // newArray.push(label);
        newArray.push(data !== undefined ? data : label);
      }
    } else {
      newArray = isChecked !== -1 ? "" : label;
    }

    setValue(newArray);
  };
  return (
    <>
      <style jsx>{`
        .${classes.container} input:disabled ~ .${classes.checkmark} {
          border: ${isChecked !== -1 ? 0 : 2}px;
            solid #dddddd80;
          background-color: ${isChecked !== -1 ? "green" : "lightgray"}
      `}</style>

      <div className={`my-2 ${classes.checkboxWithLabel}`}>
        <div className={`${classes.container}`}>
          <input
            type="checkbox"
            checked={isChecked !== -1 && "checked"}
            disabled={disabled}
            id={`checkbox${label}`}
          />
          <span
            className={classes.checkmark}
            onClick={() => disabled !== true && HandleClick()}
          ></span>
        </div>
        {label && (
          <label
            htmlFor={`checkbox${label}`}
            className={` ${
              isChecked !== -1 && disabled == false
                ? classes.labelChecked
                : disabled == true
                ? classes.labelDisabled
                : classes.label
            }`}
            style={{
              ...labelStyle,
            }}
          >
            <span>{label}</span>
          </label>
        )}
      </div>
    </>
  );
};
