import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "react-bootstrap";

const ConfirmModal = ({
  isModalVisible,
  setIsModalVisible,
  onClickNo,
  onClickYes,
  confirmTextLine,
  isApiCall,
}) => {
  return (
    <Modal
      className="modal-dialog-centered delete-modal p-4"
      isOpen={isModalVisible}
    >
      <div className="delete-modal-body">
        <h4 className="text-center ">{confirmTextLine}</h4>
      </div>
      {/* footer  */}
      <div className="saveBtn-Container mb-3">
        <Button
          className="btn btn-save mr-3"
          onClick={() => {
            onClickYes();
          }}
          disabled={isApiCall}
        >
          {isApiCall ? "Deleting..." : "Yes"}
        </Button>
        <Button
          className="btn btn-save"
          onClick={() => {
            onClickNo();
          }}
          disabled={isApiCall}
        >
          No
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
