import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Get, Patch, Post } from "../Axios/AxiosFunctions";
import { apiUrl, imageUrl, URL } from "../Config/apiUrl";
import { SpinnerRoundOutlined } from "spinners-react";
import { toast } from "react-toastify";
import { getNewPageDetails } from "../store/actions/mentorActions";
import { useHistory } from "react-router";

function PageDetails() {
  const dispatch = useDispatch();
  const history = useHistory();

  //redux Value
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const newpageDetails = useSelector(
    (state) => state.mentorReducer.pageDetails
  );
  //api Header And Url
  const pageKeysApiURL = URL(`get-system-config/${newpageDetails?.pageName}`);
  const submitApiURL = URL(`update-system-config`);

  const Header = {
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // Page Edit State's
  const [pageKeysObject, setPageKeysObject] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [pageHeading, setPageHeading] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [newCoverImage, setNewCoverImage] = useState(null);

  const [section1Title, setSection1Title] = useState("");
  const [section1Description, setSection1Description] = useState("");
  const [section1Image, setSection1Image] = useState("");
  const [newSection1Image, setNewSection1Image] = useState(null);

  const [section2Title, setSection2Title] = useState("");
  const [section2Description, setSection2Description] = useState("");
  const [section2Image, setSection2Image] = useState("");
  const [newSection2Image, setNewSection2Image] = useState(null);

  const [section3Title, setSection3Title] = useState("");
  const [section3Description, setSection3Description] = useState("");
  const [section3Image, setSection3Image] = useState("");
  const [newSection3Image, setNewSection3Image] = useState(null);

  var formData = new FormData();

  const GetObjectKeysApiMethod = async () => {
    setLoading(true);
    const responseData = await Get(pageKeysApiURL, accessToken);
    if (responseData !== undefined) {
      setPageKeysObject(responseData?.data?.data);
      setPageHeading(responseData?.data?.data?.heading);
      setPageDescription(responseData?.data?.data?.detail_description);
      setCoverImage(responseData?.data?.data?.cover_image);
      {
        responseData?.data?.data?.sec1Heading !== undefined &&
          setSection1Title(responseData?.data?.data?.sec1Heading);
        setSection1Description(responseData?.data?.data?.sec1Description);
        setSection1Image(responseData?.data?.data?.sec1Image);
      }
      {
        responseData?.data?.data?.sec2Heading !== undefined &&
          setSection2Title(responseData?.data?.data?.sec2Heading);
        setSection2Description(responseData?.data?.data?.sec2Description);
        setSection2Image(responseData?.data?.data?.sec2Image);
      }
      {
        responseData?.data?.data?.sec3Heading !== undefined &&
          setSection3Title(responseData?.data?.data?.sec3Heading);
        setSection3Description(responseData?.data?.data?.sec3Description);
        setSection3Image(responseData?.data?.data?.sec3Image);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    GetObjectKeysApiMethod();
    return () => {
      dispatch(getNewPageDetails(null));
    };
  }, []);

  const uploadImages = (event, setterFile, setterDisplay) => {
    if (event.target.files.length > 0) {
      setterFile(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (event) => {
        setterDisplay(event.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const createFormData = () => {
    formData.append("_id", newpageDetails?._id);
    formData.append("pageName", newpageDetails?.pageName);
    formData.append("heading", pageHeading);
    formData.append("detail_description", pageDescription);
    {
      newCoverImage !== null
        ? formData.append("cover_image", newCoverImage)
        : formData.append("cover_image", coverImage);
    }
    return formData;
  };

  const createSection1FormData = () => {
    formData.append("sec1Heading", section1Title);
    formData.append("sec1Description", section1Description);
    {
      newSection1Image !== null
        ? formData.append("sec1Image", newSection1Image)
        : formData.append("sec1Image", section1Image);
    }
    return formData;
  };

  const createSection2FormData = () => {
    formData.append("sec2Heading", section2Title);
    formData.append("sec2Description", section2Description);
    {
      newSection2Image !== null
        ? formData.append("sec2Image", newSection2Image)
        : formData.append("sec2Image", section2Image);
    }
    return formData;
  };

  const createSection3FormData = () => {
    formData.append("sec3Heading", section3Title);
    formData.append("sec3Description", section3Description);
    {
      newSection3Image !== null
        ? formData.append("sec3Image", newSection3Image)
        : formData.append("sec3Image", section3Image);
    }
    return formData;
  };

  const submit = async () => {
    setSubmitLoading(true);
    let data = await createFormData();
    {
      pageKeysObject?.sec1Heading !== undefined &&
        (data = await createSection1FormData());
    }
    {
      pageKeysObject?.sec2Heading !== undefined &&
        (data = await createSection2FormData());
    }
    {
      pageKeysObject?.sec3Heading !== undefined &&
        (data = await createSection3FormData());
    }
    let responseData = await Post(submitApiURL, data, Header);

    if (responseData !== undefined) {
      console.log("response", responseData.data);
      toast.success(
        `${newpageDetails?.pageName} Page Content has been updated SuccessFully`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
    setSubmitLoading(false);
  };
  return (
    <div className="container-fluid newMentorRequestDetails">
      <div className="d-flex align-items-center">
        <div
          className="save-container"
          style={{
            // width: "7.5%",
            marginRight: "1%",
            // marginLeft: "2%",
          }}
        >
          <Button
            className="btn btn-user btn-block btn-save"
            onClick={() => {
              dispatch(getNewPageDetails(null));
              history.goBack();
            }}
            disabled={submitLoading}
          >
            back
          </Button>
        </div>
        <h1
          className="h3 mb-2 text-gray-800"
          style={{ textTransform: "uppercase" }}
        >
          {newpageDetails?.pageName.replaceAll("_", " ")} Page Details
        </h1>
      </div>
      {/* <h1
        className="h3 mb-2 text-gray-800"
        style={{ textTransform: "capitalize" }}
      >
        {newpageDetails?.pageName} Page Details
      </h1> */}
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          {loading ? (
            <div className="row">
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={loading}
                  thickness={200}
                  color="#AB2330"
                />{" "}
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="form-group">
                  <label for="InputHeading" className="pages-label-style">
                    Heading
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="InputHeading"
                    placeholder="Enter Heading"
                    value={pageHeading}
                    onChange={(e) => setPageHeading(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group">
                  <label for="InputDescription" className="pages-label-style">
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="InputDescription"
                    placeholder="Enter Description"
                    value={pageDescription}
                    onChange={(e) => setPageDescription(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <label for="InputCoverImage" className="pages-label-style">
                    Cover Image
                  </label>
                </div>
                <div className="col-md-12">
                  <div className="form-group Image-Cover-Border">
                    <div className="image-upload-avatar">
                      {newCoverImage !== null ? (
                        <img
                          src={coverImage}
                          alt="User Image"
                          className="image-fluid"
                        />
                      ) : coverImage !== "" ? (
                        <img
                          src={`${imageUrl}/${coverImage}`}
                          alt="User Image"
                          className="image-fluid"
                        />
                      ) : (
                        <>
                          <span>
                            <i
                              className="fa fa-upload"
                              style={{ fontSize: 50 }}
                            />
                            <p>Upload Photo</p>
                          </span>
                        </>
                      )}
                      <input
                        type="file"
                        id="InputCoverImage"
                        accept="image/png, image/jpeg"
                        onChange={(e) =>
                          uploadImages(e, setNewCoverImage, setCoverImage)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Section 1 Start */}
              {pageKeysObject?.sec1Heading !== undefined && (
                <div className="row Section-Container">
                  <div className="col-md-12">
                    <h4 style={{ textTransform: "uppercase" }}>Section 1:</h4>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        for="section1InputHeading"
                        className="pages-label-style"
                      >
                        Heading
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="section1InputHeading"
                        placeholder="Enter Heading"
                        value={section1Title}
                        onChange={(e) => setSection1Title(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label
                        for="section1InputDescription"
                        className="pages-label-style"
                      >
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="section1InputDescription"
                        placeholder="Enter Description"
                        value={section1Description}
                        onChange={(e) => setSection1Description(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group Section-Image-Display">
                      <label
                        for="section1InputImage"
                        className="pages-label-style"
                      >
                        Image
                      </label>
                      <div
                        className="image-upload-avatar"
                        style={{
                          border:
                            newSection1Image !== null || section1Image !== ""
                              ? "0px solid #ced4da"
                              : "1px solid #ced4da",
                        }}
                      >
                        {newSection1Image !== null ? (
                          <img
                            src={section1Image}
                            alt="User Image"
                            className="image-fluid"
                          />
                        ) : section1Image !== "" ? (
                          <img
                            src={`${imageUrl}/${section1Image}`}
                            alt="User Image"
                            className="image-fluid"
                          />
                        ) : (
                          <>
                            <span>
                              <i
                                className="fa fa-upload"
                                style={{ fontSize: 50 }}
                              />
                              <p>Upload Photo</p>
                            </span>
                          </>
                        )}

                        <input
                          type="file"
                          id="section1InputImage"
                          accept="image/png, image/jpeg"
                          onChange={(e) =>
                            uploadImages(
                              e,
                              setNewSection1Image,
                              setSection1Image
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Section 1 End */}

              {/* Section 2 start */}
              {pageKeysObject?.sec2Heading !== undefined && (
                <div className="row Section-Container">
                  <div className="col-md-12">
                    <h4 style={{ textTransform: "uppercase" }}>Section 2:</h4>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        for="section1InputHeading"
                        className="pages-label-style"
                      >
                        Heading
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="section1InputHeading"
                        placeholder="Enter Heading"
                        value={section2Title}
                        onChange={(e) => setSection2Title(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label
                        for="section1InputDescription"
                        className="pages-label-style"
                      >
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="section1InputDescription"
                        placeholder="Enter Description"
                        value={section2Description}
                        onChange={(e) => setSection2Description(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group Section-Image-Display">
                      <label
                        for="section1InputImage"
                        className="pages-label-style"
                      >
                        Image
                      </label>
                      <div
                        className="image-upload-avatar"
                        style={{
                          border:
                            newSection2Image !== null || section2Image !== ""
                              ? "0px solid #ced4da"
                              : "1px solid #ced4da",
                        }}
                      >
                        {newSection2Image !== null ? (
                          <img
                            src={section2Image}
                            alt="User Image"
                            className="image-fluid"
                          />
                        ) : section2Image !== "" ? (
                          <img
                            src={`${imageUrl}/${section2Image}`}
                            alt="User Image"
                            className="image-fluid"
                          />
                        ) : (
                          <>
                            <span>
                              <i
                                className="fa fa-upload"
                                style={{ fontSize: 50 }}
                              />
                              <p>Upload Photo</p>
                            </span>
                          </>
                        )}

                        <input
                          type="file"
                          id="section1InputImage"
                          accept="image/png, image/jpeg"
                          onChange={(e) =>
                            uploadImages(
                              e,
                              setNewSection2Image,
                              setSection2Image
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Section 2 End */}

              {/* Section 3 Start */}
              {pageKeysObject?.sec3Heading !== undefined && (
                <div className="row Section-Container">
                  <div className="col-md-12">
                    <h4 style={{ textTransform: "uppercase" }}>Section 3:</h4>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        for="section1InputHeading"
                        className="pages-label-style"
                      >
                        Heading
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="section1InputHeading"
                        placeholder="Enter Heading"
                        value={section3Title}
                        onChange={(e) => setSection3Title(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label
                        for="section1InputDescription"
                        className="pages-label-style"
                      >
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="section1InputDescription"
                        placeholder="Enter Description"
                        value={section3Description}
                        onChange={(e) => setSection3Description(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group Section-Image-Display">
                      <label
                        for="section1InputImage"
                        className="pages-label-style"
                      >
                        Image
                      </label>
                      <div
                        className="image-upload-avatar"
                        style={{
                          border:
                            newSection3Image !== null || section3Image !== ""
                              ? "0px solid #ced4da"
                              : "1px solid #ced4da",
                        }}
                      >
                        {newSection3Image !== null ? (
                          <img
                            src={section3Image}
                            alt="User Image"
                            className="image-fluid"
                          />
                        ) : section3Image !== "" ? (
                          <img
                            src={`${imageUrl}/${section3Image}`}
                            alt="User Image"
                            className="image-fluid"
                          />
                        ) : (
                          <>
                            <span>
                              <i
                                className="fa fa-upload"
                                style={{ fontSize: 50 }}
                              />
                              <p>Upload Photo</p>
                            </span>
                          </>
                        )}

                        <input
                          type="file"
                          id="section1InputImage"
                          accept="image/png, image/jpeg"
                          onChange={(e) =>
                            uploadImages(
                              e,
                              setNewSection3Image,
                              setSection3Image
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Section 3 End */}
              <div className="row">
                <div className="col-md-12">
                  <div className="save-container">
                    <Button
                      className="btn btn-user btn-block btn-save"
                      onClick={() => submitLoading == false && submit()}
                      disabled={submitLoading}
                    >
                      {submitLoading == true ? "loading" : "Save"}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PageDetails;
