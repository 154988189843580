import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Get, Post, Delete, Patch } from "../Axios/AxiosFunctions";
import { URL } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import { SpinnerRoundOutlined } from "spinners-react";
import AddAndEditItemModal from "../components/AddAndEditItemModal";
import { toast } from "react-toastify";
import ConfirmModal from "../components/ConfirmModal";

function AddCertificateItems(props) {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);

  const [isFetchingData, setIsFetchingData] = useState(true);
  const [CertificatesResponseData, setCertificatesResponseData] = useState([]);
  const [selectedCertificateType, setSelectedCertificateType] = useState({});
  const [inputCertificateItem, setInputCertificateItem] = useState("");
  const [addItemLoading, setAddItemLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [selection, setSelection] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [addCertificateItemsModal, setAddCertificateItemsModal] =
    useState(false);
  const [editAddCertificateItemsModal, setEditAddCertificateItemsModal] =
    useState(false);

  const addAndGetCertificateItemApiURL = URL(`certificate-item`);
  const editCertificateItemsUrl = URL(`certificate-item/${editItem?.id}`);
  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const resetStates = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    getCertificateItem();
  }, []);

  const getCertificateItem = async () => {
    setIsFetchingData(true);
    const getResponseData = await Get(
      addAndGetCertificateItemApiURL,
      accessToken
    );
    if (getResponseData !== undefined) {
      setCertificatesResponseData(getResponseData.data.data);
      console.log(getResponseData.data.data);
    }
    setIsFetchingData(false);
  };

  const addCertificateItemHandler = async () => {
    setLoading(true);
    let obj = {
      CertificateTypeId: selectedCertificateType.id,
      CertificateName: inputCertificateItem,
    };
    const responseData = await Post(
      addAndGetCertificateItemApiURL,
      obj,
      authHeader
    );
    if (responseData !== undefined) {
      const index = CertificatesResponseData.findIndex(
        (x) => x._id == selectedCertificateType.id
      );
      let oldArray = CertificatesResponseData.slice();
      oldArray.splice(index, 1, responseData.data?.data);
      setCertificatesResponseData(oldArray);
      setAddCertificateItemsModal(false);
      setSelectedCertificateType({});
      setInputCertificateItem("");
      toast.success("Item Successfully Added.");

    }
    setLoading(false);
  };

  const deleteHandler = async () => {
    const deleteCertificate = URL(`certificate-item/${selection?.id}`);

    setLoading(true);
    const responseData = await Delete(deleteCertificate, null, authHeader);

    if (responseData !== undefined) {
      let oldArray = CertificatesResponseData.slice();
      oldArray[selection?.parentIndex].certificateItem.splice(
        selection?.index,
        1
      );
      setCertificatesResponseData(oldArray);
      setShowDeleteModal(false);
      setSelection(null);
      toast.success("Item Deleted Successfully.");
    }
    setLoading(false);
  };

  const EditHandler = async () => {
    setLoading(true);
    let obj = {
      CertificateTypeId: selectedCertificateType.id,
      CertificateName: inputCertificateItem,
    };
    const responseData = await Patch(editCertificateItemsUrl, obj, authHeader);
    if (responseData !== undefined) {
      let oldArray = CertificatesResponseData.slice();
      oldArray[editItem?.parentIndex].certificateItem.splice(
        editItem?.index,
        1,
        responseData.data?.data
      );
      setCertificatesResponseData(oldArray);
      setEditItem({});
      setEditAddCertificateItemsModal(false);
      setSelectedCertificateType({});
      setInputCertificateItem("");
    }
    setLoading(false);
  };

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequests">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Certificate Items</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex">
            <h6 className="m-0 font-weight-bold text-primary">
              Add Certificate Items
            </h6>
            <div className="addBtn">
              <i
                className="fas fa-plus-circle"
                onClick={() => setAddCertificateItemsModal(true)}
              ></i>
            </div>
          </div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#AB2330"
                />{" "}
              </div>
            ) : (
              <div className="table-responsive" style={{ overflow: "hidden" }}>
                {CertificatesResponseData.map((item, index) => {
                  return (
                    <div
                      key={`div ${index}`}
                      className="servicesItemAndTypeContainer"
                    >
                      <h3
                        key={`h3 ${index}`}
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        {item?.CertificateType}
                      </h3>
                      {item.certificateItem.map((innerItem, innerIndex) => (
                        <div
                          key={`div ${innerIndex}`}
                          className="serviceItemParent"
                        >
                          <p>
                            {innerIndex + 1}) {innerItem?.CertificateName}
                          </p>
                          <Button
                            className="btn btn-user btn-block btn-save"
                            onClick={() => {
                              setEditAddCertificateItemsModal(true);
                              setEditItem({
                                id: innerItem?._id,
                                index: innerIndex,
                                parentIndex: index,
                              });
                              setInputCertificateItem(
                                innerItem.CertificateName
                              );
                              setSelectedCertificateType({
                                id: item?._id,
                                itemType: item?.CertificateType,
                              });
                            }}
                            disabled={loading}
                          >
                            Edit
                          </Button>
                          <Button
                            className="btn btn-user btn-block btn-save"
                            onClick={() => {
                              setSelection({
                                id: innerItem?._id,
                                index: innerIndex,
                                parentIndex: index,
                              });
                              setShowDeleteModal(true);
                              // deleteHandler(innerItem?._id, innerIndex, index);
                            }}
                            disabled={loading}
                          >
                            {loading === innerItem._id ? "loading" : " Delete"}
                          </Button>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}

      <AddAndEditItemModal
        title={"Add Certificate Items"}
        type={"certificate"}
        visibleModal={addCertificateItemsModal}
        typesArray={CertificatesResponseData}
        selectedType={selectedCertificateType}
        setSelectedType={setSelectedCertificateType}
        inputItem={inputCertificateItem}
        setInputItem={setInputCertificateItem}
        onPress={() => {
          addCertificateItemHandler();
        }}
        onPressCancel={() => {
          setSelectedCertificateType({});
          setInputCertificateItem("");
          setAddCertificateItemsModal(!addCertificateItemsModal);
        }}
        loading={loading}
      />

      <AddAndEditItemModal
        title={"Edit Certificate Items"}
        type={"certificate"}
        visibleModal={editAddCertificateItemsModal}
        typesArray={CertificatesResponseData}
        selectedType={selectedCertificateType}
        setSelectedType={setSelectedCertificateType}
        inputItem={inputCertificateItem}
        setInputItem={setInputCertificateItem}
        onPress={() => {
          EditHandler();
        }}
        onPressCancel={() => {
          setEditItem({});
          setSelectedCertificateType({});
          setInputCertificateItem("");
          setEditAddCertificateItemsModal(false);
        }}
        loading={loading}
      />

      <ConfirmModal
        isModalVisible={showDeleteModal}
        setIsModalVisible={setShowDeleteModal}
        onClickNo={resetStates}
        onClickYes={deleteHandler}
        confirmTextLine={"Are you sure you want to delete this item?"}
        isApiCall={loading}
      />
    </>
  );
}

export default AddCertificateItems;
