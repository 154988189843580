import React, { useState } from "react";
import classes from "./SideBar.module.css";
import { useLocation, useHistory } from "react-router-dom";
import { logo } from "../../constant/imagepath";
import { FiHome } from "react-icons/fi";
import { FaCertificate, FaClipboardList, FaUsers } from "react-icons/fa";
import { RiArrowDownSFill, RiArrowUpSFill, RiListCheck2 } from "react-icons/ri";
import { AiFillContainer, AiFillDashboard } from "react-icons/ai";
import { useSelector } from "react-redux";
import { MdEmail, MdHomeRepairService } from "react-icons/md";
import { ImTicket } from "react-icons/im";

const RenderItem = ({ icon, title, subMenu = [], path }) => {
  const history = useHistory();
  const active = useLocation()?.pathname;
  const [subnav, setSubnav] = useState(false);

  const subActive = subMenu.find((item, index) => item?.path == active);

  const showSubnav = () => setSubnav(!subnav);
  return (
    <>
      <div
        className={[
          classes?.listItemContainer,
          path == active && classes?.active,
          subActive && classes?.subActive,
          subnav && classes?.marginZero,
        ].join(" ")}
        onClick={() => {
          if (subMenu?.length > 0) {
            showSubnav(!subnav);
          } else {
            history.push(path);
          }
        }}
      >
        {icon}
        <span>{title}</span>
        {subMenu?.length > 0 &&
          (subnav ? (
            <RiArrowUpSFill
              size={20}
              color={"white"}
              className={classes?.dropDownIcon}
            />
          ) : (
            <RiArrowDownSFill
              size={20}
              color={"white"}
              className={classes?.dropDownIcon}
            />
          ))}
      </div>
      {subnav &&
        subMenu.map((item, index) => {
          return (
            <div
              className={[
                classes?.innerItemContainer,
                item?.path == active && classes?.active,
              ].join(" ")}
              key={index}
              onClick={() => {
                history.push(item?.path);
              }}
            >
              <span>{item.label}</span>
            </div>
          );
        })}
    </>
  );
};

const SideBar = () => {
  const userType = useSelector((state) => state.globalReducer.userType);

  return (
    <div className={classes?.mainContainer}>
      <div className={classes.logoContainer}>
        <img src={logo} />
      </div>
      <div className={classes.itemsContainer}>
        <RenderItem
          icon={
            <AiFillDashboard size={22} color={"rgba(255, 255, 255, 0.3)"} />
          }
          title={"Dashboard"}
          path={"/"}
        />
        {userType !== "admin" ? (
          <RenderItem
            icon={<FaUsers size={22} color={"rgba(255, 255, 255, 0.3)"} />}
            title={"Admin List"}
            path={"/AdminList"}
          />
        ) : null}

        <RenderItem
          title={"Mechanic"}
          icon={<FaUsers size={22} color={"rgba(255, 255, 255, 0.3)"} />}
          subMenu={[
            { label: "Request", path: "/NewMechanicRequests" },
            { label: "Unclaimed Busniess", path: "/unclaimedBusiness" },
            { label: "Existing Mechanics", path: "/ApprovedMechanic" },
            { label: "Rejected Mechanics", path: "/RejectedMechanics" },
          ]}
        />
        <RenderItem
          title={"Certificates"}
          icon={<FaCertificate size={22} color={"rgba(255, 255, 255, 0.3)"} />}
          subMenu={[
            { label: "Types", path: "/AddCertificateTypes" },
            { label: "Items", path: "/AddCertificateItems" },
          ]}
        />
        <RenderItem
          title={"Services"}
          icon={
            <MdHomeRepairService size={22} color={"rgba(255, 255, 255, 0.3)"} />
          }
          subMenu={[
            { label: "Types", path: "/AddServicesTypes" },
            { label: "Items", path: "/AddServicesItems" },
          ]}
        />
        {/* <RenderItem
        icon={<AiFillContainer size={22} color={"rgba(255, 255, 255, 0.3)"} />}
        title={"Contact Support"}
        path={"/ContactSupport"}
      /> */}
        <RenderItem
          icon={
            <AiFillContainer size={22} color={"rgba(255, 255, 255, 0.3)"} />
          }
          title={"Contact Support"}
          subMenu={[
            { label: "Contact Us", path: "/ContactSupport" },
            // { label: "Consultation", path: "/unclaimBusiness" },
          ]}
        />
        <RenderItem
          icon={<MdEmail size={22} color={"rgba(255, 255, 255, 0.3)"} />}
          title={"Email Templates"}
          path={"/EmailTemplates"}
        />
        <RenderItem
          icon={<ImTicket size={22} color={"rgba(255, 255, 255, 0.3)"} />}
          title={"Coupons"}
          path={"/AddVoucher"}
        />
        <RenderItem
          icon={
            <FaClipboardList size={22} color={"rgba(255, 255, 255, 0.3)"} />
          }
          title={"Plans"}
          path={"/Plans"}
        />

        <RenderItem
          icon={<RiListCheck2 size={22} color={"rgba(255, 255, 255, 0.3)"} />}
          title={"CMS"}
          subMenu={[
            { label: "Pages", path: "/Pages" },
            { label: "Goals", path: "/Goals" },
            { label: "Services", path: "/Services" },
            { label: "Aboutus", path: "/Aboutus" },
            { label: "Faq", path: "/Faq" },
            { label: "PrivacyPolicy", path: "/PrivacyPolicy" },
            { label: "TermAndCondition", path: "/TermAndCondition" },
            { label: "Sources", path: "/Sources" },
          ]}
        />
      </div>
    </div>
  );
};

export default SideBar;
