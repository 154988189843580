import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Get } from "../../Axios/AxiosFunctions";
import StatsCard from "../../components/StatsCard/StatsCard";
import { URL } from "../../Config/apiUrl";
import classes from "./Statistics.module.css";
import {
  request,
  eye,
  withdraw,
  balance,
  statsImg,
} from "../../constant/imagepath";
import { SpinnerRoundOutlined } from "spinners-react";

const Statistics = () => {
  const accessToken = useSelector((state) => state?.globalReducer?.accessToken);
  const [statsData, setStatsData] = useState(null);
  const [statsLoading, setStatsLoading] = useState(false);

  const fetchData = async () => {
    const url = URL("admin/stats");
    const response = await Get(url, accessToken);
    setStatsLoading(true);
    if (response !== undefined) {
      const updatedResponse = response?.data?.data;
      const updatedStatsData = [
        {
          image: statsImg,
          title: "Customer Count",
          price: updatedResponse?.customerCount,
        },
        {
          image: statsImg,
          title: "New Contact Us Messages",
          price: updatedResponse?.newContactUsMessages,
        },
        {
          image: statsImg,
          title: "Non Premium Mechanics",
          price: updatedResponse?.nonPremiumMechanics,
        },
        {
          image: statsImg,
          title: "Premium Mechanics",
          price: updatedResponse?.premiumMechanics,
        },
        {
          image: statsImg,
          title: "Replied Contact Us Messages",
          price: updatedResponse?.repliedContactUsMessages,
        },
        {
          image: statsImg,
          title: "Unclaimed Mechanics",
          price: updatedResponse?.unclaimedMechanics,
        },
        {
          image: statsImg,
          title: "Verified Badge Mechanics",
          price: updatedResponse?.verifiedBadgeMechanics,
        },
        ...updatedResponse?.bookingStats?.map((item) => ({
          image: statsImg,
          title: `Booking ${item?.status}`,
          price: item?.count,
        })),
        {
          image: statsImg,
          title: "Total Certificates Count",
          price: updatedResponse?.certificates?.length,
        },
        {
          image: statsImg,
          title: "Total Services Count",
          price: updatedResponse?.services?.length,
        },
      ];
      setStatsData(updatedStatsData);
    }
    setStatsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={[classes.mainContainer]}>
      <h2>Statistics</h2>
      <Row className={classes.cardRow}>
        {statsLoading ? (
          <div className="col-md-12 darken-newMechanic on-loading">
            <SpinnerRoundOutlined
              enabled={statsLoading}
              thickness={200}
              color="#AB2330"
            />
          </div>
        ) : (
          statsData?.map((item, index) => {
            return (
              <Col xl={4} sm={6} xs={12} key={index}>
                <StatsCard item={item} />
              </Col>
            );
          })
        )}
      </Row>
    </div>
  );
};

export default Statistics;
