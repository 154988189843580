import { useState } from "react/cjs/react.development";
import logo from "./logo.svg";
import "./App.css";
import "../src/assets/css/styles.css";
import "../src/assets/css/stylesCustom.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
// import Dashboard from "./screens/Dashboard";
import Login from "./components/Login";
import Footer from "./components/Footer";
import Header from "./components/Header";
import SideNavbar from "./components/SideNavbar";

import NewMechanicRequests from "./screens/NewMechanicRequests";
import NewMechanicRequestsDetails from "./screens/NewMechanicRequestDetails/NewMechanicRequestsDetails";
import AddCertificateTypes from "./screens/AddCertificateTypes";
import AddCertificateItems from "./screens/AddCertificateItems";
import AddServicesTypes from "./screens/AddServicesTypes";
import AddServicesItems from "./screens/AddServicesItems";
import AddVoucher from "./screens/AddVoucher";
import AddVoucherDetails from "./screens/AddVoucherDetails";
import AddNewVoucher from "./screens/AddNewVoucher";
import AdminList from "./screens/AdminList";
import AddNewAdmin from "./screens/AddNewAdmin";
import Plans from "./screens/Plans";
import ApprovedMechanic from "./screens/ApprovedMechanic";
import ApprovedMechanicDetails from "./screens/ApprovedMechanicDetail/ApprovedMechanicDetails";
import Faq from "./screens/Faq";
import Services from "./screens/Services";
import Goals from "./screens/Goals";
import CMSAboutUsItems from "./screens/CMSAboutUsItems";
import Pages from "./screens/Pages";
import PageDetails from "./screens/PageDetails";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Sources from "./screens/Sources";
import TermAndCondition from "./screens/TermAndContition";
import ContentEditor from "./screens/ContentEditor";

import { Fragment, useEffect } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ContactSupportScreen from "./screens/ContactSupportScreen";
import AllUnclaimBusiness from "./screens/AllUnclaimBusiness";
import CreateUnclaimBusiness from "./screens/CreateUnclaimBusiness";
import { setCertificatesAndServices } from "./store/actions/globalActions";
import { Get } from "./Axios/AxiosFunctions";
import { URL } from "./Config/apiUrl";
import EditMechanicProfile from "./screens/EditMechanicProfile";
import EmailTemplates from "./screens/EmailTemplates";
import Statistics from "./screens/Statistics/Statistics";
import SideBar from "./components/SideBar";
import AllMechanic from "./screens/AllMechanic";
import EmailEditor from "./screens/EmailEditor";
import RejectedMechanics from "./screens/RejectedMechanics";
import RejectedMechanicDetail from "./screens/RejectedMechanicDetail";

function App() {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.globalReducer.isLogin);

  const getCertificatesAndServicesArray = async () => {
    const filterDataURL = URL(`filter-data`);
    const resData = await Get(filterDataURL);

    if (resData !== undefined) {
      dispatch(setCertificatesAndServices(resData?.data?.data));
    }
  };

  useEffect(() => {
    getCertificatesAndServicesArray();
  }, []);

  return (
    <>
      <ToastContainer />

      <Router>
        <Fragment>
          {isLogin ? (
            <>
              <div id="wrapper">
                <SideBar />
                {/* <SideNavbar /> */}
                <div id="content-wrapper" className="d-flex flex-column">
                  <div id="content">
                    <Header />

                    {/* <Route path="/" exact component={NewMechanicRequests} /> */}

                    <Route path="/" exact component={Statistics} />

                    <Route
                      path="/NewMechanicRequests"
                      component={NewMechanicRequests}
                    />
                    <Route
                      path="/NewMechanicRequestsDetails/:id"
                      component={NewMechanicRequestsDetails}
                    />
                    <Route
                      path="/unclaimedBusiness"
                      component={AllUnclaimBusiness}
                    />
                    <Route
                      path="/createUnclaimBusniess"
                      component={CreateUnclaimBusiness}
                    />
                    <Route
                      path="/AddCertificateTypes"
                      component={AddCertificateTypes}
                    />
                    <Route
                      path="/AddCertificateItems"
                      component={AddCertificateItems}
                    />
                    <Route
                      path="/AddServicesTypes"
                      component={AddServicesTypes}
                    />
                    <Route
                      path="/AddServicesItems"
                      component={AddServicesItems}
                    />
                    <Route path="/AddVoucher" component={AddVoucher} />
                    <Route
                      path="/AddVoucherDetails"
                      component={AddVoucherDetails}
                    />
                    <Route path="/AddNewVoucher" component={AddNewVoucher} />
                    <Route path="/AdminList" component={AdminList} />
                    <Route path="/AddNewAdmin" component={AddNewAdmin} />
                    <Route path="/Plans" component={Plans} />
                    <Route
                      path="/ApprovedMechanic"
                      component={AllMechanic}
                      exact
                    />
                    {/* <Route
                      path="/ApprovedMechanic"
                      component={ApprovedMechanic}
                      exact
                    /> */}
                    <Route
                      path="/EditMechanic/:id"
                      exact
                      component={EditMechanicProfile}
                    />
                    <Route
                      path="/ApprovedMechanicDetails/:id"
                      component={ApprovedMechanicDetails}
                    />
                    <Route path="/Faq" component={Faq} />
                    <Route path="/Services" component={Services} />
                    <Route path="/Goals" component={Goals} />
                    <Route path="/Aboutus" component={CMSAboutUsItems} />
                    <Route path="/Pages" component={Pages} />
                    <Route path="/PageDetails" component={PageDetails} />
                    <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
                    <Route
                      path="/TermAndCondition"
                      component={TermAndCondition}
                    />
                    <Route path="/ContentEditor" component={ContentEditor} />
                    <Route path="/Sources" component={Sources} />
                    <Route
                      path="/ContactSupport"
                      component={ContactSupportScreen}
                    />
                    <Route
                      exact
                      path={"/EmailTemplates"}
                      component={EmailTemplates}
                    />
                    <Route
                      exact
                      path={"/EmailEditor/:name"}
                      component={EmailEditor}
                    />

                    <Route
                      exact
                      path={"/RejectedMechanics"}
                      component={RejectedMechanics}
                    />
                    <Route
                      exact
                      path={"/RejectedMechanics/:id"}
                      component={RejectedMechanicDetail}
                    />
                  </div>
                  <Footer />
                </div>
              </div>
            </>
          ) : (
            <Login />
          )}
        </Fragment>
      </Router>
    </>
  );
}

export default App;
