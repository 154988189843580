import React, { useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { apiHeader, CheckRequiredField, URL } from "../../Config/apiUrl";
import { Patch } from "../../Axios/AxiosFunctions";
import { useLocation, useHistory } from "react-router-dom";

function EmailEditor() {
  const history = useHistory();
  const emailData = useLocation()?.state;

  //redux Value
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  //api Header And Url

  const submitApiURL = URL("template/update");

  const quillRef = useRef();

  // Page Edit State's
  const [content, setContent] = useState(emailData?.message);
  const [submitLoading, setSubmitLoading] = useState(false);

  const submit = async () => {
    let data = { key: emailData?.key, message: content };
    let requiredNull = CheckRequiredField(data);
    if (requiredNull == true) {
      toast.error("Enter Required Fields.");
      return null;
    }
    setSubmitLoading(true);
    let responseData = await Patch(submitApiURL, data, apiHeader(accessToken));

    if (responseData !== undefined) {
      toast.success(`${emailData?.key} Email Template updated successfully`);
      history.goBack();
    }
    setSubmitLoading(false);
  };

  return (
    <div className="container-fluid newMentorRequestDetails">
      <div className="d-flex align-items-center">
        <div
          className="save-container"
          style={{
            marginRight: "1%",
          }}
        >
          <Button
            className="btn btn-user btn-block btn-save"
            onClick={() => {
              history.goBack();
            }}
            disabled={submitLoading}
          >
            Back
          </Button>
        </div>
        <h1
          className="h3 mb-2 text-gray-800"
          style={{ textTransform: "uppercase" }}
        >
          {emailData?.key}
        </h1>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <div className="col-md-12">
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={content}
              onChange={setContent}
            />
          </div>

          <div
            className="row"
            style={{
              paddingRight: "inherit",
            }}
          >
            <div className="col-md-12">
              <div className="save-container">
                <Button
                  className="btn btn-user btn-block btn-save"
                  onClick={() => submitLoading == false && submit()}
                  disabled={submitLoading}
                >
                  {submitLoading == true ? "loading" : "Save"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailEditor;
