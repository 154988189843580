import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button, DropdownButton } from "react-bootstrap";
import { URL } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import DropDown from "./DropDown";

const AddFaqModal = ({
  addFaqModal,
  setAddFaqModal,
  question,
  setQuestion,
  answer,
  setAnswer,
  type,
  setType,
  onPress,
  loading,
}) => {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);

  return (
    <>
      <style>{`
      .custom-addLang-modal textarea{
        width:100%;
      }
    `}</style>
      <Modal
        className="modal-dialog-centered custom-addLang-modal"
        isOpen={addFaqModal}
        toggle={() => setAddFaqModal(false)}
      >
        {/* <ModalBody> */}
        <div className="modal-Header">
          <h2 className="text-center">Add FAQ</h2>
        </div>
        <div className="addlang-modalBody">
          {/* <h2 className="text-center">Add Service Types </h2> */}
          <div className="innerContainer my-4">
            <DropDown
              data={[
                { label: "Mechanic", value: "Mechanic" },
                { label: "Customer", value: "Customer" },
              ]}
              value={type}
              setValue={setType}
            />
          </div>
          <div className="innerContainer">
            <div className="form-group">
              <textarea
                type="text"
                placeholder="Add Question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>
          </div>
          <div className="innerContainer">
            <div className="form-group">
              <textarea
                type="text"
                placeholder="Add Answer"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer footer-div">
            <div>
              <Button
                className="btn btn-user btn-block btn-cancel"
                onClick={() => setAddFaqModal(false)}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>

            <div>
              <Button
                className="btn btn-user btn-block btn-save"
                onClick={onPress}
                disabled={
                  question === "" || answer === "" || loading || type === ""
                }
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </div>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </>
  );
};

export default AddFaqModal;
