import React from "react";
import Select from "react-select";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: state.isSelected ? 0 : "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#b43b3b" : "white",
    padding: 10,
  }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #dddddd",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #dddddd",
    },
  }),

  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = "opacity 300ms";

  //   return { ...provided, opacity, transition };
  // }
};
const DropDown = (props) => {
  const { value, setValue, data } = props;
  return (
    <Select
      {...props}
      className="basic-single"
      classNamePrefix="select"
      value={value}
      onChange={(e) => {
        setValue(e);
      }}
      options={data}
      styles={customStyles}
    />
  );
};

export default DropDown;
