import React from "react";

function Pagination({ maxLastLength, array, setPageNumber, pageNumber }) {
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        <li
          onClick={() => {
            pageNumber > 1 && setPageNumber((prev) => prev - 1);
          }}
          // className="previous"
          className={`previous ${pageNumber == 1 && "disabled"}`}
        >
          <a className="page-link" aria-label="Previous">
            <span aria-hidden="true">&laquo; Previous</span>
          </a>
        </li>

        <li className="middle">{pageNumber}</li>
        <li
          className={`next ${maxLastLength > array?.length && "disabled"}`}
          onClick={() => {
            maxLastLength == array?.length && setPageNumber((prev) => prev + 1);
          }}
        >
          <a className="page-link" aria-label="Next">
            <span aria-hidden="true">Next &raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
