import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { Post, Patch } from "../Axios/AxiosFunctions";
import DatePicker from "react-date-picker";
import { imageUrl, voucherImageUrl } from "../Config/apiUrl";

const EditVoucherModal = ({
  editVoucherModal,
  setEditVoucherModal,
  EditVoucherHandler,
  item,
  index,
  editImageBase64,
  loading,
  editImage,
  setEditImage,
  editVoucherTitle,
  setEditVoucherTitle,
  editVoucherCode,
  setEditVoucherCode,
  editVoucherDiscount,
  setEditVoucherDiscount,
  editWebsiteLink,
  setEditWebsiteLink,
  editVoucherDescription,
  setEditVoucherDescription,
  editStartDate,
  setEditStartDate,
  editEndDate,
  setEditEndDate,
  setEditImageBase64,
  uploadImages,
}) => {
  return (
    <>
      <Modal
        className="modal-dialog-centered custom-EditVoucher-modal"
        isOpen={editVoucherModal}
        // toggle={() => {
        //   setEditVoucherModal(false);
        //   setEditImageBase64("");
        // }}
      >
        {/* <ModalBody> */}
        <div className="modal-Header">
          <h2 className="text-center modal-Title">Edit Products</h2>
        </div>
        <div className="voucher-modalBody">
          <h2 className="text-center modal-subTitle">Product Details</h2>
          <div className="innerContainer">
            <div className="form-group">
              {/* <p>{titleText}</p>    
                <div className="Plans-textArea" >
                    <textarea
                        type="text"
                        placeholder="Description"
                        value={editDescriptionText}
                        onChange={(e)=>setEditDescriptionText(e.target.value)}
                    />
                </div> */}

              <div className="row">
                <div className="col-md-9">
                  <div className="inputFields-container">
                    {/* new code start  */}

                    <div className="form-group ml-5 icon-upload-avatar">
                      {editImage == "" ? (
                        <>
                          <span>
                            <i
                              className="fa fa-upload"
                              style={{ fontSize: 50 }}
                            />
                            <p>Upload Photo</p>
                          </span>
                        </>
                      ) : editImageBase64 !== "" ? (
                        <img
                          src={editImageBase64}
                          alt="User Image"
                          className="img-fluid mt-2 mb-3"
                          style={{ width: "200px", height: "auto" }}
                        />
                      ) : (
                        <img
                          src={`${imageUrl}/${editImage}`}
                          alt="User Image"
                          className="img-fluid mt-2 mb-3"
                          style={{ width: "200px", height: "auto" }}
                        />
                      )}
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => uploadImages(e)}
                      />
                    </div>
                    {/* new code end */}

                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Voucher Title"
                      value={editVoucherTitle}
                      onChange={(e) => setEditVoucherTitle(e.target.value)}
                    />
                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Voucher Code"
                      value={editVoucherCode}
                      onChange={(e) => setEditVoucherCode(e.target.value)}
                    />
                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Voucher Discount"
                      value={editVoucherDiscount}
                      onChange={(e) => setEditVoucherDiscount(e.target.value)}
                    />

                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Voucher website Link"
                      value={editWebsiteLink}
                      onChange={(e) => setEditWebsiteLink(e.target.value)}
                    />

                    <textarea
                      className="inputBox voucherTextArea"
                      type="text"
                      placeholder="Voucher Description"
                      value={editVoucherDescription}
                      onChange={(e) =>
                        setEditVoucherDescription(e.target.value)
                      }
                    />

                    <div className="datePicker-container">
                      <div className="DatePicker">
                        <p style={{ margin: "0" }} className="ml-3">
                          Start Date
                        </p>
                        <DatePicker
                          className="DatePickerBox"
                          onChange={setEditStartDate}
                          value={editStartDate}
                        />
                      </div>
                      <div className="DatePicker">
                        <p style={{ margin: "0" }} className="ml-3">
                          End Date
                        </p>
                        <DatePicker
                          className="DatePickerBox"
                          onChange={setEditEndDate}
                          value={editEndDate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer footer-div">
            <div>
              <Button
                className="btn btn-user btn-block btn-cancel"
                onClick={() => {
                  setEditVoucherModal(false);
                  setEditImageBase64("");
                }}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>

            <div>
              <Button
                className="btn btn-user btn-block btn-save"
                onClick={() => EditVoucherHandler(item, index)}
                disabled={loading}
              >
                {loading ? "Saving..." : " Save"}
              </Button>
            </div>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </>
  );
};

export default EditVoucherModal;
