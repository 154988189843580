import React, { useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-date-picker";
import { URL } from "../Config/apiUrl";
import { Post, Patch } from "../Axios/AxiosFunctions";
import { useHistory } from "react-router-dom";
// import { SpinnerRoundOutlined } from "spinners-react";

function AddNewVoucher(props) {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [paymentRequests, setPaymentRequests] = useState([]);
  const [limit, setLimit] = useState(10);
  const [addVoucher, setAddVoucher] = useState("Voucher 1");
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  // const { setChoice } = props;

  const [voucherTitle, setVoucherTitle] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherDiscount, setVoucherDiscount] = useState("");
  const [voucherDescription, setVoucherDescription] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [image, setImage] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  let history = useHistory();

  console.log("start date", startDate);
  console.log("end date", endDate);
  const [loading, setLoading] = useState(false);

  //   useEffect(() => {
  //     // dispatch(getNewMentors());
  //     var config = {
  //       method: "get",
  //       url: NODE_URL(
  //         `transaction/pending-withdraws?limit=${limit}&page=${pageNumber}&addVoucher=${addVoucher}`
  //       ),
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     };
  //     axios(config)
  //       .then(function (response) {
  //         let maxLastLength =
  //           pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
  //         setMaxLength(maxLastLength);
  //         setPaymentRequests(response.data.data);
  //         console.log(response.data.data, "=================================");
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   }, [pageNumber, addVoucher]);

  //   const approve = (id) => {
  //     console.log(id);
  //     var config = {
  //       method: "get",
  //       url: NODE_URL(
  //         `transaction/clear-pending-list?addVoucher=${AddVoucher}`
  //       ),
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     };
  //     axios(config)
  //       .then(function (response) {
  //         console.log(response);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   };

  const decline = (id) => {
    console.log(id);
    // var config = {
    //   method: "get",
    //   url: NODE_URL(`mentor/decline-mentor/${id}`),
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    // };
    // axios(config)
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };

  const PaymentRequestData = [
    {
      id: 1,
      name: "Daniyal",
      m_id: "00126u7565876",
      balance: "$1000",
      noOfSessios: 5,
      noOfMentees: 11,
      country: "Germany",
      data: "Edinburgh",
    },
    {
      id: 2,
      name: "Ahsan",
      m_id: "00189u7371160",
      balance: "$16000",
      noOfSessios: 7,
      noOfMentees: 10,
      country: "Germany",
      data: "Edinburgh",
    },
    {
      id: 3,
      name: "Qasim",
      m_id: "0033124u73788g8",
      balance: "$1300",
      noOfSessios: 4,
      noOfMentees: 13,
      country: "Germany",
      data: "Edinburgh",
    },
    {
      id: 4,
      name: "Ehtisham",
      m_id: "00173124u73786",
      balance: "$1800",
      noOfSessios: 8,
      noOfMentees: 10,
      country: "Germany",
      data: "Edinburgh",
    },
    {
      id: 5,
      name: "Basit",
      m_id: "001ff3124u73800",
      balance: "$2200",
      noOfSessios: 10,
      noOfMentees: 7,
      country: "Germany",
      data: "Edinburgh",
    },
  ];

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const imgAuthHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  };

  console.log("access token", accessToken);

  const uploadImages = async (event) => {
    setImage(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const createFormdata = async (data) => {
    //  console.log({data})
    var formData = new FormData();
    console.log({ formData });
    formData.append("image", image);
    formData.append("title", voucherTitle);
    formData.append("code", voucherCode);
    formData.append("discount", voucherDiscount);
    formData.append("description", voucherDescription);
    formData.append("web_link", websiteLink);
    formData.append("voucher_start", startDate);
    formData.append("voucher_end", endDate);
    formData.append("test", "test");
    return formData;
  };

  const AddNewVoucherHandler = async () => {
    console.log("add functio run");
    // setAddServiceTypesModal(false);
    const addServiceType = URL(`voucher`);
    setLoading(true);
    let obj = {
      image: image,
      title: voucherTitle,
      code: voucherCode,
      discount: voucherDiscount,
      description: voucherDescription,
      web_link: websiteLink,
      voucher_start: startDate,
      voucher_end: endDate,
    };

    let returnFormData = await createFormdata(obj);
    console.log({ returnFormData });
    const responseData = await Post(
      addServiceType,
      returnFormData,
      imgAuthHeader
    );
    if (responseData !== undefined) {
      // console.log({addServicesTypes})
      console.log("new voucher add test", responseData);
      alert(responseData.data.status);
      history.push("/AddVoucher");
      //   let oldData = addServicesTypes.slice();
      //   oldData.push(responseData?.data?.data)
      //   console.log({oldData})
      //   setAddServicesTypes(oldData)
      // setAddServiceTypesModal(false);
      //   console.log("qasim api add service", responseData?.data);
    }
    setLoading(false);
  };

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequests">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Add New Voucher</h1>
        {/* <p className="mb-4">Add Voucher List</p> */}

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex">
            <h6 className="m-0 font-weight-bold text-primary">Add Voucher</h6>
          </div>
          <div className="card-body">
            <div className="table-responsive" style={{ overflowX: "hidden" }}>
              <div className="row">
                <div className="col-md-3 Voucher-img">
                  {/* <img src={voucher} alt="" className="image-fluid" /> */}
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => uploadImages(e)}
                  />
                </div>
                <div className="col-md-9">
                  <div className="inputFields-container">
                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Voucher Title"
                      value={voucherTitle}
                      onChange={(e) => setVoucherTitle(e.target.value)}
                    />
                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Voucher Code"
                      value={voucherCode}
                      onChange={(e) => setVoucherCode(e.target.value)}
                    />
                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Voucher Discount"
                      value={voucherDiscount}
                      onChange={(e) => setVoucherDiscount(e.target.value)}
                    />

                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Voucher website Link"
                      value={websiteLink}
                      onChange={(e) => setWebsiteLink(e.target.value)}
                    />

                    <textarea
                      className="inputBox voucherTextArea"
                      type="text"
                      placeholder="Voucher Description"
                      value={voucherDescription}
                      onChange={(e) => setVoucherDescription(e.target.value)}
                    />

                    <div className="datePicker-container">
                      <div className="DatePicker">
                        <p style={{ margin: "0" }}>Start Date</p>
                        <DatePicker
                          className="DatePickerBox"
                          onChange={setStartDate}
                          value={startDate}
                        />
                      </div>
                      <div className="DatePicker">
                        <p style={{ margin: "0" }}>End Date</p>
                        <DatePicker
                          className="DatePickerBox"
                          onChange={setEndDate}
                          value={endDate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ width: "100px" }}>
                <Button
                  className={`btn btn-user btn-block btn-approved`}
                  onClick={() => AddNewVoucherHandler()}
                  disabled={loading}
                >
                  {loading ? "loading" : " Save"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}
    </>
  );
}

export default AddNewVoucher;
