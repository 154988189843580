import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { SpinnerRoundOutlined } from "spinners-react";
import Pagination from "../../components/Pagination";
import { notFound } from "../../constant/imagepath";
import classes from "./EmailTemplates.module.css";
import { apiHeader, URL } from "../../Config/apiUrl";
import { Get, Patch, Post } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import moment from "moment";
import AddOrUpdateEmailTemplateModal from "../../components/AddOrUpdateEmailTemplateModal";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";

function EmailTemplates() {
  const history = useHistory();
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const limit = 10;

  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  // For Editing
  const [isEditing, setIsEditing] = useState(false);
  const [isShowEditingModal, setIsShowEditingModal] = useState(false);
  const [editingData, setEditingData] = useState();
  // For Adding
  const [isAdding, setIsAdding] = useState(false);
  const [isShowAddingModal, setIsShowAddingModal] = useState(false);

  async function getData() {
    const url = URL("template");
    setIsLoading(true);
    const response = await Get(url, accessToken);
    if (response !== undefined) {
      console.log(response);
      setData(response?.data?.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  async function updateData(params) {
    const url = URL("template/update");
    setIsEditing(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    if (response !== undefined) {
      console.log(response);
      let resData = response?.data?.data;
      let newData = [...data];
      let index = newData.findIndex((item) => item?._id == resData?._id);
      newData.splice(index, 1, resData);
      setData(newData);
      toast.success("Email Template updated successfully");
      setIsEditing(false);
      setIsShowEditingModal(false);
    } else {
      setIsEditing(false);
    }
  }

  async function createData(params) {
    const url = URL("template/create");
    setIsAdding(true);
    const response = await Post(url, params, apiHeader(accessToken));
    if (response !== undefined) {
      console.log(response);
      let resData = response?.data?.data;
      let newData = [...data];
      newData.push(resData);
      setData(newData);
      toast.success("Email Template added successfully");
      setIsAdding(false);
      setIsShowAddingModal(false);
    } else {
      setIsAdding(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container-fluid newMentorRequests">
      {/* <!-- Page Heading --> */}
      <h1 className="h3 mb-2 text-gray-800">Email Templates</h1>

      {/* <!-- DataTales Example --> */}
      <div className="card shadow mb-4">
        {/* <div className="card-header py-3 d-flex">
                    <h6 className="m-0 font-weight-bold text-primary">
                        Add Email Template
                    </h6>
                    <div className="addBtn">
                        <i
                            className="fas fa-plus-circle"
                            onClick={() => {
                                setIsShowAddingModal(true)
                            }}
                        ></i>
                    </div>
                </div> */}

        <div className="card-body">
          {isLoading ? (
            <div className="col-md-12 darken-newMechanic on-loading">
              <SpinnerRoundOutlined
                enabled={isLoading}
                thickness={200}
                color="#AB2330"
              />{" "}
            </div>
          ) : (
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Name</th>
                    <th>Message</th>
                    <th>Updated At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading == false &&
                    data?.map((item, index) => {
                      return (
                        <>
                          <tr key={item?._id}>
                            <td>{index + 1}</td>
                            <td>{item?.key}</td>
                            <td>
                              {/* <p className={classes.templateContent}> */}
                              {parse(item?.message)}
                              {/* </p> */}
                            </td>
                            <td>
                              {moment(item?.updatedAt).format(
                                "DD MMM YYYY, hh:mm"
                              )}
                            </td>
                            <td>
                              <Button
                                className={`btn btn-user btn-block
                                                                `}
                                onClick={() => {
                                  history.push({
                                    pathname: `/EmailEditor/${item?.key}`,
                                    state: item,
                                  });
                                  // setEditingData(item)
                                  // setIsShowEditingModal(true)
                                }}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              {data.length == 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={notFound}
                    alt=""
                    className="image-fluid"
                    width={"20%"}
                  />
                  <h3>No Email Templates Found</h3>
                </div>
              ) : (
                <div className="pagination">
                  <Pagination
                    maxLastLength={limit}
                    array={data}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {isShowEditingModal && (
        <AddOrUpdateEmailTemplateModal
          show={isShowEditingModal}
          setShow={setIsShowEditingModal}
          loading={isEditing}
          type={"update"}
          data={editingData}
          onSave={updateData}
        />
      )}
      <AddOrUpdateEmailTemplateModal
        show={isShowAddingModal}
        setShow={setIsShowAddingModal}
        loading={isAdding}
        type={"add"}
        onSave={createData}
      />
    </div>
  );
}

export default EmailTemplates;
