import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { URL } from "../Config/apiUrl";
import Pagination from "../components/Pagination";
import { getNewMentors } from "../store/actions/mentorActions";
import { useDispatch, useSelector } from "react-redux";
import { Get, Patch, Post, Delete } from "../Axios/AxiosFunctions";
import { useHistory } from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import AddProductsModal from "../components/AddProductsModal";
import EditProductsModal from "../components/EditProductsModal";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer, toast } from "react-toastify";
import ConfirmModal from "../components/ConfirmModal";

function Plans(props) {
  // const { setChoice } = props;
  // const newMentors = useSelector((state) => state.mentorReducer.unApprovedMentors);
  const [products, setProducts] = useState([]);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  // const [loading,setLoading] =useState(true);
  const [isFetchingData, setIsFetchingData] = useState(true);

  const [addProductModal, setAddProductModal] = useState(false);

  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const [editProductsModal, setEditProductsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [title, setTitle] = useState("Select Title");
  const [description, setDescription] = useState("");
  const [productId, setProductId] = useState("");
  const [itemDetail, setItemDetail] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  let history = useHistory();

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const getProducts = async () => {
    const productURL = URL(`product?page=${pageNumber}&limit=${limit}`);
    setIsFetchingData(true);

    const responseData = await Get(productURL, accessToken);
    let maxLastLength =
      pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
    setMaxLength(maxLastLength);
    if (responseData !== undefined) {
      setProducts(responseData.data.data);
    } else {
      console.log("error");
    }
    setIsFetchingData(false);
  };

  const GetSubscriptionPlans = async () => {
    const subscriptionPlansApiURL = URL(`get-plans`);
    setIsFetchingData(true);
    // setLoading(true);
    const responseData = await Get(subscriptionPlansApiURL, accessToken);
    if (responseData !== undefined) {
      console.log({ responseData });
      setSubscriptionPlans(responseData.data.data);
      console.log(responseData?.data?.data);
      // setProducts(responseData?.data?.data);
    } else {
      setSubscriptionPlans([]);
      console.log("error");
    }
    // setLoading(false);
    setIsFetchingData(false);
  };

  useEffect(() => {
    getProducts();
    GetSubscriptionPlans();
  }, [pageNumber]);

  const Header = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  String.prototype.insert = function (index, string) {
    if (index > 0) {
      return this.substring(0, index) + string + this.substr(index);
    }

    return string + this;
  };

  const AddPlansHandler = async () => {
    setLoading(true);
    const addServiceType = URL(`product`);
    let str = description.trim().replaceAll("\n", "\n\n ➤ ");
    str = str.insert(0, "➤ ");
    let obj = { prodId: title.id, description: str, title: title.title };
    console.log(obj);
    const responseData = await Post(addServiceType, obj, authHeader);
    if (responseData !== undefined) {
      let oldData = products.slice();
      oldData.push(responseData?.data?.data);
      setProducts(oldData);
      toast.success("Plan Added Successfully!");
    }
    setAddProductModal(false);
    setLoading(false);
  };
  const resetStates = () => {
    setShowDeleteModal(false);
  };

  const deleteHandler = async () => {
    const deleteProducts = URL(`product/${selectedItem?.prodId}`);
    let index = products?.findIndex(
      (ele) => ele?.prodId === selectedItem?.prodId
    );
    setLoading(true);

    const responseData = await Delete(deleteProducts, null, authHeader);
    if (responseData !== undefined) {
      // console.log({ responseData });
      let arr = products.slice();
      arr.splice(index, 1);
      setProducts(arr);
      setShowDeleteModal(false);
      setSelectedItem(null);
      toast.success("Item Deleted Successfully!");
    }
    setLoading(false);
  };

  const EditPlansHandler = async () => {
    setLoading(true);
    const editPlans = URL(`product/${itemDetail?.item.prodId}`);
    let obj = { description: description };
    const responseData = await Patch(editPlans, obj, authHeader);
    if (responseData !== undefined) {
      let oldData = products.slice();
      oldData.splice(itemDetail?.index, 1, responseData?.data?.data);
      setProducts(oldData);
      setEditProductsModal(false);
      toast.success("Changes Done Successfully!");
    }
    setLoading(false);
  };

  const cancelHandler = async (setter) => {
    setter(false);
    setItemDetail({});
    setDescription("");
    setProductId("");
    setTitle("Select Title");
  };

  return (
    <>
      {/* <!-- DataTales Example --> */}
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex">
          <h6 className="m-0 font-weight-bold text-primary">Plans List</h6>
          <div className="addBtn">
            <i
              className="fas fa-plus-circle"
              onClick={() => setAddProductModal(true)}
            ></i>
          </div>
        </div>
        <div className="card-body">
          {isFetchingData ? (
            <div className="col-md-12 darken-newMechanic on-loading">
              <SpinnerRoundOutlined
                enabled={isFetchingData}
                thickness={200}
                color="#AB2330"
              />{" "}
            </div>
          ) : (
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <td>S.No</td>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Action</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((item, index) => {
                    return (
                      <>
                        <tr key={item._id}>
                          <td>{index + 1}</td>
                          <td>{item.title}</td>
                          <td>{item.description}</td>
                          <td>
                            <Button
                              className="btn btn-user btn-block btn-save"
                              onClick={() => {
                                setSelectedItem(item);
                                setShowDeleteModal(true);
                              }}
                              disabled={loading}
                            >
                              {loading === item.id ? "loading" : " Delete"}
                            </Button>
                          </td>
                          <td>
                            <Button
                              className="btn btn-user btn-block btn-save"
                              onClick={() => {
                                setItemDetail({
                                  index: index,
                                  item: item,
                                });
                                setTitle(item.title);
                                setDescription(item?.description);
                                setProductId(item?.prodId);
                                setEditProductsModal(true);
                              }}
                              disabled={loading}
                            >
                              Edit
                            </Button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              <div className="pagination">
                <Pagination
                  maxLastLength={maxLength}
                  array={products}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <AddProductsModal
        addProductModal={addProductModal}
        setAddProductModal={setAddProductModal}
        description={description}
        setDescription={setDescription}
        onPress={() => {
          AddPlansHandler();
        }}
        title={title}
        loading={loading}
        setTitle={setTitle}
        subscriptionPlans={subscriptionPlans}
        setSubscriptionPlans={setSubscriptionPlans}
      />

      <EditProductsModal
        loading={loading}
        editProductsModal={editProductsModal}
        setEditProductsModal={setEditProductsModal}
        editDescriptionText={description}
        setEditDescriptionText={setDescription}
        titleText={title}
        setTitleText={setTitle}
        EditPlansHandler={EditPlansHandler}
        onPress={() => {
          EditPlansHandler();
        }}
        onPressCancel={() => {
          cancelHandler(setEditProductsModal);
        }}
      />

      <ConfirmModal
        isModalVisible={showDeleteModal}
        setIsModalVisible={setShowDeleteModal}
        onClickNo={resetStates}
        onClickYes={deleteHandler}
        confirmTextLine={"Are you sure you want to delete this item?"}
        isApiCall={loading}
      />
    </>
  );
}

export default Plans;
