import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { URL } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";

const AddServiceTypesModal = ({
  addServiceTypesModal,
  setAddServiceTypesModal,
  onPress,
  value,
  setValue,
  loading,
}) => {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);

  return (
    <>
      <Modal
        className="modal-dialog-centered custom-addLang-modal"
        isOpen={addServiceTypesModal}
        // toggle={() => setAddServiceTypesModal(false)}
      >
        {/* <ModalBody> */}
        <div className="modal-Header">
          <h2 className="text-center">Add Service Types</h2>
        </div>
        <div className="addlang-modalBody">
          <h2 className="text-center">Add Service Types </h2>
          <div className="innerContainer">
            <div className="form-group">
              <input
                type="text"
                placeholder="Add Service Types"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer footer-div">
            <div>
              <Button
                className="btn btn-user btn-block btn-cancel"
                onClick={() => setAddServiceTypesModal(false)}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>

            <div>
              <Button
                className="btn btn-user btn-block btn-save"
                onClick={onPress}
                disabled={loading}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </>
  );
};

export default AddServiceTypesModal;
