import React, { useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import { Button } from "react-bootstrap";
import { URL } from "../Config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { Get, Delete, Post, Patch } from "../Axios/AxiosFunctions";
import { SpinnerRoundOutlined } from "spinners-react";
import { toast } from "react-toastify";
import AddAndEditCmsWebItemModal from "../components/AddAndEditCmsWebItemModal";
import { successIcon, warningIcon } from "../constant/imagepath";
import AlertModal from "../components/AlertModal";
import { useHistory } from "react-router";
import { getNewPageDetails } from "../store/actions/mentorActions";

const PrivacyPolicy = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [isFetchingData, setIsFetchingData] = useState(true);

  const [loading, setLoading] = useState("");

  const [itemArray, setItemArray] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [title, setTitle] = useState("");
  const [itemDetail, setItemDetail] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);

  const addUrl = URL(`privacy-policy`);

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const getPrivacyPolicy = async () => {
    const getPPApiURL = URL(`web/all-privacypolicy`);
    setIsFetchingData(true);
    const responseData = await Get(getPPApiURL, accessToken);
    if (responseData !== undefined) {
      setItemArray(responseData.data.data);
    } else {
      setItemArray([]);
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  const HandleShowAlertModal = (e, index) => {
    setItemDetail({
      index: index,
      id: e,
    });
    setShowAlertModal(true);
  };
  const deleteHandler = async () => {
    const deleteApiUrl = `${addUrl}/${itemDetail.id}`;
    setSaveLoading(true);
    // setLoading(itemDetail.id);
    const responseData = await Delete(deleteApiUrl, null, authHeader);
    if (responseData !== undefined) {
      let arr = itemArray.slice();
      arr.splice(itemDetail?.index, 1);
      setItemArray(arr);
      toast.success("Item Deleted Successfully.");
      cancelHandler(setShowAlertModal);
    }
    setSaveLoading(false);
    // setLoading("");
  };

  const CheckRequiredField = (obj) => {
    let requiredFields = false;
    Object.keys(obj).forEach(function (key) {
      if (obj[key] == "") {
        requiredFields = true;
      }
    });
    return requiredFields;
  };

  const addPrivacyPolicyHandler = async () => {
    setSaveLoading(true);
    let data = {
      title: title,
    };
    let requiredNull = CheckRequiredField(data);
    if (requiredNull == true) {
      toast.error("Enter Required Fields.");
      return null;
    }
    const responseData = await Post(addUrl, data, authHeader);
    if (responseData !== undefined) {
      let oldData = itemArray.slice();
      oldData.push(responseData?.data?.data);
      setItemArray(oldData);
      toast.success("Item Successfully Added.");
      cancelHandler(setShowAddModal);
    }
    setSaveLoading(false);
  };

  const editItemHandler = async () => {
    const editUrl = `${addUrl}/${itemDetail.id}`;

    setSaveLoading(true);
    let data = {
      title: title,
    };
    let requiredNull = CheckRequiredField(data);
    if (requiredNull == true) {
      toast.error("Enter Required Fields.");
      return null;
    }
    const responseData = await Post(editUrl, data, authHeader);
    if (responseData !== undefined) {
      let oldData = itemArray.slice();
      oldData.splice(itemDetail.index, 1, responseData?.data?.data);
      setItemArray(oldData);
      toast.success("Item Edit Successfully.");
      cancelHandler(setShowEditModal);
    }
    setSaveLoading(false);
  };

  const cancelHandler = async (setter) => {
    setter(false);
    setItemDetail({});
    setTitle("");
  };

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequests">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Privacy Policy</h1>
        <p className="mb-4">Add and Edit Privacy Policy</p>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <h6 className="m-0 font-weight-bold text-primary">
                Add New Privacy Policy
              </h6>
              <div className="addBtn">
                <i
                  className="fas fa-plus-circle"
                  onClick={() => setShowAddModal(true)}
                ></i>
              </div>
            </div>
          </div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#175092"
                />{" "}
              </div>
            ) : (
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>title</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemArray.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.title}</td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() => {
                                  setTitle(item.title);
                                  setItemDetail({
                                    index: index,
                                    id: item?._id,
                                    item: item,
                                  });
                                  setShowEditModal(true);
                                }}
                              >
                                Edit
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() => {
                                  history.push("/ContentEditor");
                                  const data = item;
                                  data.linkPath = `privacy-policy`;
                                  dispatch(getNewPageDetails(data));
                                }}
                              >
                                Edit Content
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() =>
                                  HandleShowAlertModal(item?._id, index)
                                }
                                disabled={loading}
                              >
                                {loading === item._id ? "loading" : " Delete"}
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}

      <AddAndEditCmsWebItemModal
        title={"Add Privacy Policy"}
        type={"privacyPolicy"}
        visibleModal={showAddModal}
        itemTitle={title}
        setItemTitle={setTitle}
        onPress={() => {
          addPrivacyPolicyHandler();
        }}
        onPressCancel={() => {
          cancelHandler(setShowAddModal);
        }}
        itemLoading={saveLoading}
      />

      <AddAndEditCmsWebItemModal
        title={"Edit Privacy Policy"}
        type={"privacyPolicy"}
        visibleModal={showEditModal}
        itemTitle={title}
        setItemTitle={setTitle}
        onPress={() => {
          editItemHandler();
        }}
        onPressCancel={() => {
          cancelHandler(setShowEditModal);
        }}
        itemLoading={saveLoading}
      />
      <AlertModal
        title={"Alert!"}
        message={"Are you sure you want to delete this item?"}
        image={warningIcon}
        visibleModal={showAlertModal}
        onPress={() => {
          deleteHandler();
        }}
        onPressCancel={() => {
          cancelHandler(setShowAlertModal);
        }}
        itemLoading={saveLoading}
      />
    </>
  );
};

export default PrivacyPolicy;
