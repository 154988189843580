import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { Post, Patch } from "../Axios/AxiosFunctions";

const AddProductsModal = ({
  addProductModal,
  setAddProductModal,
  description,
  setDescription,
  onPress,
  title,
  setTitle,
  subscriptionPlans,
  setSubscriptionPlans,
  loading,
}) => {
  console.log(title);
  return (
    <>
      <Modal
        className="modal-dialog-centered custom-addLang-modal"
        isOpen={addProductModal}
        toggle={() => setAddProductModal(false)}
      >
        {/* <ModalBody> */}
        <div className="modal-Header">
          <h2 className="text-center">Add Products</h2>
        </div>
        <div className="addlang-modalBody">
          <h2 className="text-center">Product Details</h2>
          <div className="innerContainer">
            <div className="form-group">
              <DropdownButton
                // style={{padding: "10px 85px"}}
                id="dropdown-basic-button"
                title={`${
                  title?.title == undefined ? "select Product" : title?.title
                }`}
                onSelect={(e) => {
                  console.log(e);
                  let item = subscriptionPlans.filter((x) => {
                    return x.product.id == e;
                  });
                  setTitle({
                    id: e,
                    title: item[0]?.product?.name,
                  });
                }}
              >
                {subscriptionPlans?.map((item, index) => (
                  <Dropdown.Item
                    className="addCertificateItems"
                    key={index}
                    eventKey={item?.product?.id}
                  >
                    {item?.product?.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>

              <div className="Plans-textArea">
                <textarea
                  type="text"
                  rows={10}
                  placeholder="Product Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer footer-div">
            <div>
              <Button
                className="btn btn-user btn-block btn-cancel"
                onClick={() => setAddProductModal(false)}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>

            <div>
              <Button
                className="btn btn-user btn-block btn-save"
                onClick={onPress}
                disabled={loading}
              >
                {loading ? "Saving..." : " Save"}
              </Button>
            </div>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </>
  );
};

export default AddProductsModal;
