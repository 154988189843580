import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import TextArea from "../TextArea";
import classes from "./ReasonModal.module.css";
import textAreaModalClasses from "../TextArea/TextArea.module.css";
import { toast } from "react-toastify";

const ReasonModal = ({ show, setShow, onClick, loading }) => {
  const [reason, setReason] = useState("");

  const reject = async () => {
    if (reason == "") {
      return toast.error("Please fill the reason first");
    }
    await onClick(reason);
    setReason("");
    setShow(false);
  };
  return (
    <>
      <style>{`
    .${textAreaModalClasses.inputPassContainer}{
        padding:10px 12px;
    }
    `}</style>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header className={[classes.modalHeader]}>
          <h2>Reason For Rejection</h2>
        </Modal.Header>
        <Modal.Body className={[classes.modalBody]}>
          <TextArea
            label={"Reason*"}
            placeholder={"Reason*"}
            value={reason}
            setter={setReason}
            rows={5}
          />
          <div className={[classes.submitBtnDiv]}>
            <Button onClick={reject}>{loading ? "loading" : "Submit"}</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReasonModal;
