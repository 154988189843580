import React, { useState } from "react";

import { loginVector, LogoBlack } from "../constant/imagepath";
import { URL } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import { useDispatch } from "react-redux";
import { islogin } from "../store/actions/globalActions";
import { Button } from "react-bootstrap";
import cookie from "js-cookie";

function Login(props) {
  const { setUserLogin } = props;
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const authHeader = {
    headers: {
      Accept: "application/json",
      // "Access-Control-Allow-Origin": "*"
    },
  };

  const loginHandle = async () => {
    // e.preventDefault()
    // setUserLogin(email);
    const adminLoginURL = URL("loginAdmin");
    let params = {
      email: email,
      password: password,
      role: "super-admin",
    };
    setLoading(true);
    const responseData = await Post(adminLoginURL, params, authHeader);
    setLoading(false);
    // await Get("https://opentdb.com/api.php?amount=10");
    if (responseData !== undefined) {
      cookie.set("accessToken", responseData?.data?.token, { expires: 30 });
      console.log(responseData?.data?.data);
      dispatch(islogin(responseData?.data));
    }
  };

  return (
    <>
      <div className="container login">
        {/* <!-- Outer Row --> */}
        <div className="row justify-content-center custom-alignment">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                {/* <!-- Nested Row within Card Body --> */}
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image">
                    <img src={loginVector} alt="" className="image-fluid" />
                  </div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <img
                          src={LogoBlack}
                          alt=""
                          className="image-fluid logo"
                        />
                        <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                      </div>
                      <form className="user">
                        <div className="form-group">
                          <input
                            className="form-control form-control-user"
                            type="email"
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Enter Email Address..."
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control form-control-user"
                            type="password"
                            id="exampleInputPassword"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        {/* <div className="form-group">
                                                    <div className="custom-control custom-checkbox small">
                                                        <input 
                                                            className="custom-control-input" 
                                                            type="checkbox"
                                                            id="customCheck" 
                                                        />
                                                        <label className="custom-control-label" for="customCheck">RememberMe</label>
                                                    </div>
                                                </div> */}
                        <Button
                          className="btn btn-primary-custom btn-user btn-block"
                          onClick={() => loginHandle()}
                          disabled={loading}
                        >
                          {loading ? "loading" : "Login"}
                        </Button>
                      </form>
                      {/* <div className="text-center">
                                                <a className="small" href="forgot-password.html">Forgot Password?</a>
                                            </div>
                                            <div className="text-center">
                                                <a className="small" href="register.html">Create an Account!</a>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
