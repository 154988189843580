import React, { useEffect, useState, Component } from "react";
import Select from "react-select";
import Pagination from "../components/Pagination";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { URL } from "../Config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Get, Patch, Delete } from "../Axios/AxiosFunctions";
import {
  getNewMentors,
  getNewPageDetails,
} from "../store/actions/mentorActions";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";
import EditVoucherModal from "../components/EditVoucherModal";
import { toast } from "react-toastify";
import ConfirmModal from "../components/ConfirmModal";
import moment from "moment";
import ContactRepliedModal from "../components/ContactRepliedModal";

function ContactSupportScreen(props) {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);

  const [supportMessages, setSupportMessages] = useState([]);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [dropDownValue, setDropDownValue] = useState("consult");
  const [show, setShow] = useState(false);
  const [sendData, setSendData] = useState(null);
  const [renderScreen, setRenderScreen] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
  };
  console.log("------->", sendData);
  const [loading, setLoading] = useState("");

  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  console.log(dropDownValue, "dropDownValue");

  const getSupportMessagesList = async () => {
    const supportMessagesApiURL = URL(
      `contact-us/all?page=${pageNumber}&limit=${limit}&q=${dropDownValue}`
    );
    setIsFetchingData(true);
    // setLoading(true);
    const responseData = await Get(supportMessagesApiURL, accessToken);
    let maxLastLength =
      pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
    setMaxLength(maxLastLength);
    if (responseData !== undefined) {
      setSupportMessages(responseData.data.data);
    } else {
      setSupportMessages([]);
      console.log("error");
    }
    // setLoading(false);
    setIsFetchingData(false);
  };

  useEffect(() => {
    getSupportMessagesList();
  }, [pageNumber, dropDownValue, renderScreen]);

  const resetStates = () => {
    setShowDeleteModal(false);
  };

  const deleteHandler = async () => {
    const deleteVoucher = URL(`/contact-us/delete/${selectedItem?.id}`);
    setLoading(true);
    const responseData = await Delete(deleteVoucher, null, authHeader);
    if (responseData !== undefined) {
      let arr = supportMessages.slice();
      arr.splice(selectedItem.index, 1);
      setSupportMessages(arr);
      setShowDeleteModal(false);
      setSelectedItem(null);
      toast.success("Deleted Successfully!");
    }
    setLoading(false);
  };
  const options = [
    { value: "consult", label: "consult" },
    { value: "contactus", label: "Contact Us" },
    { value: "contactsupport", label: "Contact Support" },
  ];
  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequests">
        {/* <!-- Page Heading --> */}
        <div className="d-flex j-between">
          <h1 className="h3 mb-2 text-gray-800">Contact Support Message</h1>
          <div className="select-b">
            <Select
              options={options}
              onChange={(e) => setDropDownValue(e.value)}
              defaultValue={options[0]}
            />
          </div>
        </div>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#AB2330"
                />{" "}
              </div>
            ) : (
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Business Name</th>
                      <th>Email</th>
                      <th>ContactNo</th>
                      <th>Message</th>
                      {/* <th>Category</th> */}
                      <th>Role</th>
                      <th>Replied</th>
                      <th>Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {supportMessages?.map((item, index) => {
                      return (
                        <>
                          <tr key={item._id}>
                            <td>{index + 1}</td>
                            <td>{item?.firstName + " " + item?.lastName}</td>
                            <td>{item?.email}</td>
                            <td>{item?.phoneNo}</td>
                            <td>{item?.message}</td>
                            {/* <td>{item.formType}</td> */}
                            <td>{item?.role ? item?.role : "Not Known"}</td>
                            <td>{item?.replied ? "Yes" : "No"}</td>
                            <td>
                              {moment(item?.createdAt).format(
                                "DD/MM/yyy "
                                // hh:mm A"
                              )}
                            </td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() => {
                                  setSelectedItem({
                                    id: item?._id,
                                    index: index,
                                  });
                                  setShowDeleteModal(true);
                                }}
                                disabled={loading}
                              >
                                Delete
                              </Button>
                              <Button
                                className="btn btn-user btn-block btn-save mar-0"
                                onClick={(e) => {
                                  setSendData(item);
                                  setShow(true);
                                }}
                              >
                                Reply
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>

                <div className="pagination">
                  <Pagination
                    maxLastLength={maxLength}
                    array={supportMessages}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}

      <ConfirmModal
        isModalVisible={showDeleteModal}
        setIsModalVisible={setShowDeleteModal}
        onClickNo={resetStates}
        onClickYes={deleteHandler}
        confirmTextLine={"Are you sure you want to delete this item?"}
        isApiCall={loading}
      />
      {sendData && (
        <ContactRepliedModal
          sendData={sendData}
          handleClose={handleClose}
          show={show}
          setRenderScreen={setRenderScreen}
        />
      )}
    </>
  );
}

export default ContactSupportScreen;
