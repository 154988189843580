import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Get, Post, Delete, Patch } from "../../Axios/AxiosFunctions";
import { URL } from "../../Config/apiUrl";
import { Button } from "react-bootstrap";
import { SpinnerRoundOutlined } from "spinners-react";
import { toast } from "react-toastify";
import ConfirmModal from "../../components/ConfirmModal";
import { useHistory } from "react-router-dom";

import Pagination from "../../components/Pagination";
import NoData from "../../components/NoData/NoData";

import classes from "./AllUnclaimBusiness.module.css";

function AllUnclaimBusiness(props) {
  let history = useHistory();
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const maxRecordLimit = 10;

  const [isFetchingData, setIsFetchingData] = useState(true);
  const [allUnclaimBusniess, setAllUnclaimBusniess] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);

  const getUnclaimBusniessApiURL = URL(`customer/unclaimed-business-users`);

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const resetStates = () => {
    setSelectedItem(null);
    setShowDeleteModal(false);
  };

  useEffect(() => {
    getAllUnclaimBusiness();
  }, []);

  const getAllUnclaimBusiness = async () => {
    setIsFetchingData(true);
    const getResponseData = await Get(getUnclaimBusniessApiURL, accessToken);
    if (getResponseData !== undefined) {
      const fetchedData = getResponseData?.data?.data;
      setAllUnclaimBusniess(fetchedData);
    }
    setIsFetchingData(false);
  };

  const deleteHandler = async () => {
    const deleteCertificate = URL(`admin/unclaimed-user/${selectedItem?._id}`);
    setLoading(true);
    const responseData = await Delete(deleteCertificate, null, authHeader);
    if (responseData !== undefined) {
      let oldArray = allUnclaimBusniess.slice();
      const index = oldArray?.findIndex(
        (item) => item?.id == selectedItem?._id
      );
      oldArray.splice(index, 1);
      setAllUnclaimBusniess(oldArray);
      setLoading(false);
      setShowDeleteModal(false);
      toast.success("Unclaim Business Deleted Successfully.");
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequests">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Unclaimed Business</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex">
            <h6 className="m-0 font-weight-bold text-primary">
              Add Unclaim Business
            </h6>
            <div className="addBtn">
              <i
                className="fas fa-plus-circle"
                onClick={() => {
                  history.push("/createUnclaimBusniess");
                }}
              ></i>
            </div>
          </div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#AB2330"
                />{" "}
              </div>
            ) : (
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Business Name</th>
                      <th>Email</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allUnclaimBusniess?.map((item, index) => {
                      return (
                        <>
                          <tr key={item?._id}>
                            <td>{index + 1}</td>
                            <td>{item?.userName}</td>
                            <td>{item?.UserId?.email}</td>
                            <td>
                              <p className={classes.description}>
                                {item?.description}
                              </p>
                            </td>
                            <td>
                              <Button
                                className="btn btn-user btn-block btn-save"
                                onClick={() => {
                                  setSelectedItem(item);
                                  setShowDeleteModal(true);
                                }}
                                disabled={loading}
                              >
                                {loading === item._id ? "loading" : " Delete"}
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                {allUnclaimBusniess?.length == 0 && (
                  <NoData text={"No Business Found"} />
                )}
                {allUnclaimBusniess?.length > 0 && (
                  <div className="pagination">
                    <Pagination
                      maxLastLength={maxRecordLimit * pageNumber}
                      array={allUnclaimBusniess}
                      setPageNumber={setPageNumber}
                      pageNumber={pageNumber}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}

      <ConfirmModal
        isModalVisible={showDeleteModal}
        setIsModalVisible={setShowDeleteModal}
        onClickNo={resetStates}
        onClickYes={deleteHandler}
        confirmTextLine={"Are you sure you want to delete this item?"}
        isApiCall={loading}
      />
    </>
  );
}

export default AllUnclaimBusiness;
