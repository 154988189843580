import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";
import { Get } from "../../Axios/AxiosFunctions";
import Pagination from "../../components/Pagination";
import { URL, imageUrl } from "../../Config/apiUrl";
import { getNewMentors } from "../../store/actions/mentorActions";
import classes from "./RejectedMechanic.module.css";

const RejectedMechanics = () => {
  const [rejectedMechanic, setRejectedMechanic] = useState([]);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const dispatch = useDispatch();
  let history = useHistory();
  const getMechanicList = async () => {
    const certificatesApiURL = URL(
      `admin/mechanics/rejected?page=${pageNumber}&limit=${limit}`
    );
    setIsFetchingData(true);
    const responseData = await Get(certificatesApiURL, accessToken);
    let maxLastLength =
      pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
    setMaxLength(maxLastLength);
    if (responseData !== undefined) {
      console.log({ responseData });
      setRejectedMechanic(responseData.data.data);
    } else {
      setRejectedMechanic([]);
      console.log("error");
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    GetSubscriptionPlans();
  }, []);

  useEffect(() => {
    getMechanicList();
  }, [pageNumber]);

  const GetSubscriptionPlans = async () => {
    const subscriptionPlansApiURL = URL(`get-plans`);
    setIsLoading(true);
    const responseData = await Get(subscriptionPlansApiURL, accessToken, false);
    if (responseData !== undefined) {
      setSubscriptionPlans(responseData.data.data);
    }
    setIsLoading(false);
  };

  return (
    <>
      {/* <!-- DataTales Example --> */}
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            Rejected Mechanics
          </h6>
        </div>
        <div className="card-body">
          {isFetchingData === true || isLoading == true ? (
            <div className="col-md-12 darken-newMechanic on-loading">
              <SpinnerRoundOutlined
                enabled={isFetchingData}
                thickness={200}
                color="#AB2330"
              />{" "}
            </div>
          ) : (
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <td>Photo</td>
                    <th>Business Name</th>
                    <th>Email</th>
                    <th>Package</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading == false &&
                    rejectedMechanic?.map((item, index) => {
                      const filterIndex = subscriptionPlans.findIndex(
                        (x) => (x) => x?.id == item?.plan
                      );
                      return (
                        <>
                          <tr key={item._id}>
                            <td className="table-image">
                              <img
                                src={`${imageUrl}/${item?.photo}`}
                                alt=""
                                className="image-fluid"
                              />
                            </td>
                            <td>{item?.userName}</td>
                            <td>{item?.UserId?.email}</td>
                            <td>
                              {filterIndex !== -1
                                ? subscriptionPlans[filterIndex]?.product?.name
                                : "Null"}
                            </td>
                            <td>
                              <Button
                                className={`btn btn-user btn-block ${
                                  item.active ? "btn-approved" : "btn-pending"
                                }`}
                                onClick={() => {
                                  history.push(
                                    `/RejectedMechanics/${item?._id}`
                                  );
                                  dispatch(getNewMentors(item));
                                  console.log(item);
                                }}
                              >
                                View Detail
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              <div className="pagination">
                <Pagination
                  maxLastLength={maxLength}
                  array={rejectedMechanic}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RejectedMechanics;
