import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import { FaSnapchat } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { ImGooglePlus3 } from "react-icons/im";
import { MdDescription, MdEmail, MdLocationPin } from "react-icons/md";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { toast } from "react-toastify";
import { SpinnerRoundOutlined } from "spinners-react";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import PDFView from "../../components/PDFView";
import ProfileWithEditButton from "../../components/ProfileWithEditButton";
import { URL } from "../../Config/apiUrl";
import classes from "./RejectedMechanicDetail.module.css";

const RejectedMechanicDetail = () => {
  const id = useParams()?.id;
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [userData, setUserData] = useState(null);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    const url = URL(`mechanic/mechanic-detail/${id}`);
    setIsFetchingData(true);
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      setUserData(response?.data?.data?.user);
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const Header = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const approve = async (id) => {
    const approvedUrl = URL(`admin/mechanic/verify`);
    const params = {
      mechanicId: id,
    };
    setLoading(true);
    const responseData = await Patch(approvedUrl, params, Header);
    if (responseData !== undefined) {
      setUserData(responseData.data?.data);
      history.push("/RejectedMechanics");
      toast.success("Mechanic Approved!");
    }
    setLoading(false);
  };

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequestDetails">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Rejected Mechanic Detail</h1>

        {/* <!-- DataTales Example --> */}
        <div>
          <div>
            {/* profile */}
            <div className="row gx-0">
              {isFetchingData ? (
                <div className="col-md-12 darken-newMechanic on-loading">
                  <SpinnerRoundOutlined
                    enabled={isFetchingData}
                    thickness={200}
                    color="#AB2330"
                  />{" "}
                </div>
              ) : (
                <>
                  <div className={classes.bodyContainer}>
                    <div
                      className={`${classes.userImagesContainer}`}
                      style={{
                        height: 400,
                      }}
                    >
                      <ProfileWithEditButton
                        updateImage={userData?.cover_image}
                        className={classes.coverImage}
                        onErrorImage={true}
                        classImg={classes.coverImageTagClass}
                        edit={false}
                      />

                      <div className={`${classes.profileImageContainer}`}>
                        <ProfileWithEditButton
                          updateImage={userData?.photo}
                          edit={false}
                        />
                      </div>
                    </div>
                    <div
                      className={`${classes.userDetailContainer}`}
                      style={{
                        margin: "50px 0",
                      }}
                    >
                      <div className={classes.centerItems}>
                        <StarRatings
                          rating={userData?.ratingsAverage}
                          starRatedColor="var(--main-color-red)"
                          numberOfStars={5}
                          name="rating"
                          starDimension="16px"
                          starSpacing="1px"
                        />
                        <p>{`${userData?.review?.length} ${
                          userData?.review?.length === 1 ? "review" : "reviews"
                        }`}</p>
                        <p className={`${classes.userNameText}`}>
                          {userData?.userName}
                        </p>
                        <>
                          <Row className={`g-0`}>
                            <Col
                              md={12}
                              className={classes.SocialLinkContainer}
                            >
                              {userData?.snapchatLink && (
                                <FaSnapchat
                                  size={24}
                                  color={"var(--main-color-red)"}
                                  onClick={() => {
                                    window.open(
                                      `https://${userData?.snapchatLink}`,
                                      "_blank"
                                    );
                                  }}
                                  className={classes.socialIcon}
                                />
                              )}
                              {userData?.facebookLink && (
                                <BsFacebook
                                  size={24}
                                  color={"var(--main-color-red)"}
                                  onClick={() => {
                                    window.open(
                                      `https://${userData?.facebookLink}`,
                                      "_blank"
                                    );
                                  }}
                                  className={classes.socialIcon}
                                />
                              )}
                              {userData?.instagramLink && (
                                <GrInstagram
                                  size={24}
                                  color={"var(--main-color-red)"}
                                  onClick={() => {
                                    window.open(
                                      `https://${userData?.instagramLink}`,
                                      "_blank"
                                    );
                                  }}
                                  className={classes.socialIcon}
                                />
                              )}
                              {userData?.googleLink && (
                                <ImGooglePlus3
                                  size={24}
                                  color={"var(--main-color-red)"}
                                  onClick={() => {
                                    window.open(
                                      `https://${userData?.googleLink}`,
                                      "_blank"
                                    );
                                  }}
                                  className={classes.socialIcon}
                                />
                              )}
                              {userData?.linkedinLink && (
                                <BsLinkedin
                                  size={24}
                                  color={"var(--main-color-red)"}
                                  onClick={() => {
                                    window.open(
                                      `https://${userData?.linkedinLink}`,
                                      "_blank"
                                    );
                                  }}
                                  className={classes.socialIcon}
                                />
                              )}
                            </Col>
                          </Row>
                        </>
                      </div>
                      <Row className={`g-0 ${classes.personalDetailRow}`}>
                        <Col md={4} sm={12}>
                          <MdEmail size={24} color={"#E60000"} />
                          <span>{userData?.UserId?.email}</span>
                        </Col>
                        <Col md={8} sm={12}>
                          <MdLocationPin size={24} color={"#E60000"} />
                          <span>
                            {userData?.address
                              ? userData?.address
                              : "This information is not edit yet..."}
                          </span>
                        </Col>
                        <Col md={12} sm={12}>
                          <MdDescription size={24} color={"#E60000"} />
                          <span>
                            {userData?.description
                              ? userData?.description
                              : "This information is not edit yet..."}
                          </span>
                        </Col>
                      </Row>

                      <Row
                        className={`g-0 ${classes.certificateAndServicesRow}`}
                      >
                        <Col md={12} sm={12}>
                          <h4>CERTIFICATIONS</h4>
                        </Col>
                        {userData?.certificates?.length > 0 ? (
                          userData?.certificates?.map((items, index) => {
                            return (
                              <Col md={6} sm={12}>
                                <h5>{items?.certificateType}</h5>
                                <ul>
                                  {items?.certificateItem?.map(
                                    (list, index) => {
                                      return (
                                        <li className={`${classes.greyText}`}>
                                          {list?.CertificateName}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </Col>
                            );
                          })
                        ) : (
                          <h6>No Certificates</h6>
                        )}
                      </Row>

                      <Row
                        className={`g-0 ${classes.certificateAndServicesRow}`}
                      >
                        <Col md={12} sm={12}>
                          <h4>SERVICES</h4>
                        </Col>
                        {userData?.services?.length > 0 ? (
                          userData?.services?.map((items, index) => {
                            return (
                              <Col md={6} sm={12}>
                                <h5>{items?.serviceType}</h5>
                                <ul>
                                  {items?.serviceItems?.map((list, index) => {
                                    return (
                                      <li className={`${classes.greyText}`}>
                                        {list?.serviceName}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Col>
                            );
                          })
                        ) : (
                          <h6>No Services</h6>
                        )}
                      </Row>

                      <Row
                        className={`g-0 ${classes.certificateAndServicesRow}`}
                      >
                        <Col md={12} sm={12}>
                          <h4>Public Certificates PDF</h4>
                        </Col>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {userData?.documents?.length > 0 ? (
                            userData?.documents?.map((el, index) => {
                              return <PDFView item={el} />;
                            })
                          ) : (
                            <h6>No Public Certificates</h6>
                          )}
                        </div>
                      </Row>
                      <Row
                        className={`g-0 ${classes.certificateAndServicesRow}`}
                      >
                        <Col md={12} sm={12}>
                          <h4>Private Certificates PDF</h4>
                        </Col>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {userData?.privateDocuments?.length > 0 ? (
                            userData?.privateDocuments?.map((el, index) => {
                              return <PDFView item={el} />;
                            })
                          ) : (
                            <h6>No Private Certificates</h6>
                          )}
                        </div>
                      </Row>
                      <div className="my-4 d-flex justify-content-end">
                        <Button
                          className="btn btn-success"
                          onClick={() => {
                            approve(userData?._id);
                          }}
                          disabled={loading}
                        >
                          {loading ? "loading" : "Approve"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}
    </>
  );
};

export default RejectedMechanicDetail;
