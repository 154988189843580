import React from "react";
import { Country, State, City } from "country-state-city";
import DropDown from "../DropDown";

const StateCitySelect = ({
  // selectedCountry,
  // setSelectedCountry,
  selectedState,
  setSelectedState,
  // selectedCity,
  // setSelectedCity,
}) => {
  return (
    <div>
      {/* <div className="mb-3">
        <h4>Country</h4>
        <DropDown
          data={Country.getAllCountries()}
          getOptionLabel={(options) => {
            return options["name"];
          }}
          getOptionValue={(options) => {
            return options["name"];
          }}
          value={selectedCountry}
          setValue={setSelectedCountry}
          placeholder="Select Country"
        />
      </div> */}
      <div className="mb-3">
        <h4>State</h4>
        <DropDown
          data={State?.getStatesOfCountry("US")}
          getOptionLabel={(options) => {
            return options["name"];
          }}
          getOptionValue={(options) => {
            return options["name"];
          }}
          value={selectedState}
          setValue={setSelectedState}
          placeholder="Select State"
        />
      </div>
      {/* <div className="mb-3">
        <h4>City</h4>
        <DropDown
          data={City.getCitiesOfState(
            selectedState?.countryCode,
            selectedState?.isoCode
          )}
          getOptionLabel={(options) => {
            return options["name"];
          }}
          getOptionValue={(options) => {
            return options["name"];
          }}
          value={selectedCity}
          setValue={setSelectedCity}
          placeholder="Select City"
        />
      </div> */}
    </div>
  );
};
export default StateCitySelect;
