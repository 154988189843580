import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { URL } from "../Config/apiUrl";
import Pagination from "../components/Pagination";
import { getNewMentors } from "../store/actions/mentorActions";
import { useDispatch, useSelector } from "react-redux";
import { Get, Patch, Delete } from "../Axios/AxiosFunctions";
import { useHistory } from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";

function AdminList(props) {
  // const { setChoice } = props;
  // const newMentors = useSelector((state) => state.mentorReducer.unApprovedMentors);
  const [unApprovedMechanic, setUnApprovedMechanic] = useState([]);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [loading, setLoading] = useState("");
  const [isFetchingData, setIsFetchingData] = useState(true);
  //   const [btnLoading,setBtnLoading] =useState("");

  const dispatch = useDispatch();
  let history = useHistory();

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const getMechanicList = async () => {
    const certificatesApiURL = URL(
      `admin/all?page=${pageNumber}&limit=${limit}`
    );
    setIsFetchingData(true);
    // setLoading(true);
    console.log("access token", accessToken);
    const responseData = await Get(certificatesApiURL, accessToken);
    let maxLastLength =
      pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
    setMaxLength(maxLastLength);
    console.log({ responseData });
    if (responseData !== undefined) {
      console.log({ responseData });
      setUnApprovedMechanic(responseData.data.data);
      // setUnApprovedMechanic(responseData?.data?.data);
    } else {
      setUnApprovedMechanic([]);
      console.log("error");
    }
    // setLoading(false);
    setIsFetchingData(false);
  };

  useEffect(() => {
    getMechanicList();
  }, [pageNumber]);

  const Header = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const approve = async (id, index) => {
    const approvedUrl = URL(`admin/mechanic/verify`);
    const params = {
      mechanicId: id,
    };
    // setLoading(true);
    const responseData = await Patch(approvedUrl, params, Header, accessToken);
    //   setLoading(false)
    if (responseData !== undefined) {
      console.log("responseData", responseData);
      // setUnApprovedMechanic([])
      let arr = unApprovedMechanic.slice();
      arr.splice(index, 1);
      setUnApprovedMechanic(arr);
    }
  };
  const decline = (id) => {
    var config = {
      method: "patch",
      url: URL(`admin/decline-mentor/${id}`),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteHandler = async (e, index) => {
    const deleteAdmin = URL(`admin/delete/${e}`);
    setLoading(e);
    const responseData = await Delete(deleteAdmin, null, authHeader);
    if (responseData !== undefined) {
      // console.log({ responseData });
      let arr = unApprovedMechanic.slice();
      arr.splice(index, 1);
      setUnApprovedMechanic(arr);
    }
    setLoading("");
  };

  console.log("ADMIN LIST ", unApprovedMechanic);
  console.log("access token", accessToken);
  return (
    <>
      {/* <!-- DataTales Example --> */}
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex">
          <h6 className="m-0 font-weight-bold text-primary">Admin List</h6>
          <div className="addBtn">
            <Link to="/AddNewAdmin">
              <i
                className="fas fa-plus-circle"
                //   onClick={() => setAddServiceTypesModal(true)}
              ></i>
            </Link>
          </div>
        </div>

        <div className="card-body">
          {isFetchingData ? (
            <div className="col-md-12 darken-newMechanic on-loading">
              <SpinnerRoundOutlined
                enabled={isFetchingData}
                thickness={200}
                color="#AB2330"
              />{" "}
            </div>
          ) : (
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <td>S.NO</td>
                    <th>Email</th>
                    <th>Role</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {unApprovedMechanic.map((item, index) => {
                    return (
                      <>
                        <tr key={item.id}>
                          {/* <td className="table-image">
                              <img 
                                src={`${nodeImageUrl}${item.photo}`} alt="" className="image-fluid" />
                            </td> */}
                          <td>{index + 1}</td>
                          <td>{item.email}</td>
                          <td>{item.role}</td>
                          <td>
                            <Button
                              className="btn btn-user btn-block btn-save"
                              onClick={() => deleteHandler(item?._id, index)}
                              disabled={loading}
                              // onClick={()=>console.log("clicked")}
                            >
                              {loading === item._id ? "loading" : " Delete"}
                            </Button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              <div className="pagination">
                <Pagination
                  maxLastLength={maxLength}
                  array={unApprovedMechanic}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminList;
