import React from "react";
import { Modal } from "reactstrap";
import { Button } from "react-bootstrap";

const AlertModal = ({
  title,
  message,
  visibleModal,
  image,
  onPress,
  onPressCancel,
  itemLoading,
}) => {
  return (
    <Modal
      className="modal-dialog-centered custom-addLang-modal"
      isOpen={visibleModal}
      toggle={onPressCancel}
    >
      <div className="alert-modal-Header">
        <img src={image} alt="" className="img-fluid" />
        <h2 className="text-center">{title}</h2>
      </div>
      <div className="addlang-modalBody">
        <div className="Alert-modal-message-container">
          <p className="text-center">{message}</p>
        </div>
        <div className="modal-footer footer-div">
          <div>
            <Button
              className="btn btn-user btn-block btn-cancel"
              onClick={onPressCancel}
            >
              Cancel
            </Button>
          </div>

          <div>
            <Button
              className="btn btn-user btn-block btn-save"
              onClick={onPress}
              disabled={itemLoading}
            >
              {itemLoading ? "Loading.." : "Delete"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AlertModal;
